
import { Typography } from '@mui/material';
import { ICustomInput } from '../types';
import { EASSelect } from './EASSelect';
import { EASRadio } from './EASRadio';
import { EASCheck } from './EASCheck';


type EASCustomProps = { 
    customInput: ICustomInput | undefined;
    helperText?: string ;
    size?: 'small' | 'medium' | undefined;
    value?: string | Array<string> | any;
    isLoading?: boolean | undefined;
    onChange?: (event: any, value: any | null) => void;
    onFocus?: () => void;
  } 

export const EASCustom: React.FC<EASCustomProps> = ({...props}) => {
    
  return (
    <>
      {(
        () => {
          switch(props.customInput?.type_custom_input) {
            case '1': 
                return  <EASSelect
                          isLoading={props.isLoading}
                          defaultValue={null}
                          options={props?.customInput?.options_custom_input ? props.customInput.options_custom_input : []}
                          label={props?.customInput?.name_custom_input}
                          getOptionLabel={(options) => options.text}
                          value={props.value}
                          onChangeSelect={props.onChange}
                          onFocus={props.onFocus}
                          error={!!props.helperText}
                          helperText={props.helperText}
                          size={props.size}
                      />;           
            case '2': 
              return  <EASRadio
                        isLoading={props.isLoading}
                        label={props?.customInput?.name_custom_input}
                        options={props?.customInput?.options_custom_input ? props.customInput.options_custom_input : []}
                        value={props.value}
                        onChange={props.onChange}
                        onFocus={props.onFocus}
                        error={!!props.helperText}
                        helperText={props.helperText}
                      />;   
              
            case '3':
              return  <EASCheck
                        isLoading={props.isLoading}
                        label={props?.customInput?.name_custom_input}
                        options={props?.customInput?.options_custom_input ? props.customInput.options_custom_input : []}
                        value={props?.value && Array.isArray(props.value) ? props.value : []}
                        onChange={props.onChange}
                        error={!!props.helperText}
                        helperText={props.helperText}
                        onFocus={props.onFocus}
                      />;

            default: 
            return <Typography variant="overline" gutterBottom sx={{ display: 'block' }}>
                    Campo Personalizado não definido
                  </Typography>;
        }})()
      }
    </>


  );
};
