import React, { useEffect, useState } from 'react';

import Excel from 'exceljs';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  CircularProgress,
  Card,
  Box,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  styled,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CachedIcon from '@mui/icons-material/Cached';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';

import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { IList, IProgram, IInvitation } from '../../shared/types';
import { EASButton, EASButtonUpload, EASCheck, EASSelect } from '../../shared/forms';

//import * as XLSX from 'xlsx';

interface IProgramInviteImportProps {
  program?: IProgram;
  open: boolean;  
  tags?: Array<IList>;
  setOpen: (open:boolean) => void;
  listInvitation?: Array<IInvitation> | null;
}

interface IImportList{
  name?:string;
  email?:string;
  type?:string;
  objectives?:string;
}

interface IRows{
  col1: string; 
  col2: string; 
  col3?: string; 
  col4?: string;
}

interface IColumnsSelect {
  checkHeaders?: string;
  name?: string;
  email?: string;
  type?: string;
  objectives?: string;
}
interface LineError {
  line?:string;
  content?:string;
}

interface IErrorImportSXL {
  invalidMail?:Array<LineError>;
  duplicateItemList?:Array<LineError>;
  duplicateItemBd?:Array<LineError>;
  invalidTypes?:Array<LineError>;
  invalidObjectives?:Array<LineError>;
}

const columnsNameItems:Array<IList> = 
            [  { text: 'Coluna 1', value: '1' },
              { text: 'Coluna 2', value: '2' },
              { text: 'Coluna 3', value: '3' },
              { text: 'Coluna 4', value: '4' },
            ];

export const ProgramInviteImport: React.FC<IProgramInviteImportProps> = ({ open, tags, setOpen, listInvitation}) => {

  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState<Array<IImportList>>([]);
  const [fileLoaded, setFileLoaded] = useState<File | null>(null);
  const [rows, setRows] = useState<Array<IRows>>([]);
  const [columnsName, setColumnsName] = useState<Array<IList>>(columnsNameItems);

  const [columnsSelect, setColumnsSelect] = useState<IColumnsSelect>({});
  const [columnsSelectError, setColumnsSelectError] = useState<IColumnsSelect| null>({});


  const [importErrors, setImportErrors] = useState<IErrorImportSXL>({});
  const [isViewErrors, setIsViewErrors] = useState<boolean>(false);

  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);


  
  

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
   
  useEffect(() => {
    if(open == false)
      handleResetFileUpload();
}, [open]);

  useEffect(() => {

      const updatedColumns = [...columnsName];
      if(columnsSelect?.checkHeaders == '1'){
        updatedColumns[0].text = rows[0].col1;
        updatedColumns[1].text = rows[0].col2;
        updatedColumns[2].text = rows[0].col3 ? rows[0].col3 : 'A coluna está vazia';
        updatedColumns[3].text = rows[0].col4 ? rows[0].col4 : 'A coluna está vazia';
        
      }
      else {
        updatedColumns[0].text = 'Coluna 1';
        updatedColumns[1].text = 'Coluna 2';
        updatedColumns[2].text = 'Coluna 3';
        updatedColumns[3].text = 'Coluna 4';
      }
      setColumnsName(updatedColumns);
      
  }, [columnsSelect?.checkHeaders]);

  const handleResetFileUpload = () => {
    setFileLoaded(null);
    setColumnsName(columnsNameItems);
    setColumnsSelect({});
    setColumnsSelectError(null);
    setIsAnalyzing(false);
    setIsViewErrors(false);
    setImportErrors({});
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      loadFile(file);
      setFileLoaded(file);
    }
  };

  const loadFile = async (file: File): Promise<IRows[]> => {
    setLoadingFile(true);
    const purchases: Array<IRows> = [];
    const workbook = new Excel.Workbook();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        try {
          const buffer = reader.result as ArrayBuffer;
          await workbook.xlsx.load(buffer, {ignoreNodes: ['dataValidations' , 'hyperlinks' ]});
          workbook.eachSheet((sheet) => {
            sheet.eachRow((row, rowIndex) => {
              const line = row.values as Array<any>;
              const purchase = {
                col1: line[1],
                col2: line[2],
                col3: line[3],
                col4: line[4],
              };
              purchases.push(purchase);
            });
          });
          setRows(purchases);
          setLoadingFile(false);
          resolve(purchases);
        } catch (error) {
          setLoadingFile(false);
          reject(error);
        }
      };
      reader.onerror = (error) => {
        setLoadingFile(false);
        reject(error);
      };
    });
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const isValidColumnSelect = yup.object({
    name: yup.string().required('Este campo é obrigatório'),
    email: yup.string().required('Este campo é obrigatório'),
    type: yup.string().required('Este campo é obrigatório'),
  });

  const importList = () => {
    isValidColumnSelect
      .validate(columnsSelect, { abortEarly: false })
      .then(validateProgram => {
        setIsAnalyzing(true);
        const importList: Array<IImportList> = [];
        const emailSet = new Set<string>();
        const firstOccurrenceMap = new Map<string, number>();

        const invalidMails: Array<LineError> = [];
        const duplicateItemList: Array<LineError> = [];
        const duplicateItemBd: Array<LineError> = [];
        const invalidTypes: Array<LineError> = [];
        const invalidObjectives: Array<LineError> = [];

        let errorReport = false;
        
        rows.forEach((row, index) => {
          if ((columnsSelect.checkHeaders == '1' && index > 0) || columnsSelect.checkHeaders != '1') {
            const nameKey = `col${validateProgram.name}` as keyof typeof row;
            const emailKey = `col${validateProgram.email}` as keyof typeof row;
            const typeKey = `col${columnsSelect.type}` as keyof typeof row;
            const objectivesKey = `col${columnsSelect.objectives}` as keyof typeof row;
        
            const email = row[emailKey] || '';
            const type = row[typeKey] || '';
            const objectives = row[objectivesKey] || '';
            const uniqueIdentifier = `${email}-${type}`;
            const lowerCaseType = type.toLowerCase();
        
            const objectivesArray = objectives.split(',').map(item => item.trim());
        
            if (!isValidEmail(email)) {
              invalidMails.push({ line: (index + 1).toString(), content: email });
              errorReport = true;
            }
        
            if (emailSet.has(uniqueIdentifier)) {
              const firstOccurrenceLine = firstOccurrenceMap.get(uniqueIdentifier); 
              if (firstOccurrenceLine) {
                duplicateItemList.push({
                  line: `${firstOccurrenceLine}, ${index + 1}`, 
                  content: email + ' | ' + type
                });
                errorReport = true;
              }
            } else {
              emailSet.add(uniqueIdentifier);
              firstOccurrenceMap.set(uniqueIdentifier, index + 1);
            }
        
            if (lowerCaseType !== 'mentor' && lowerCaseType !== 'mentorado') {
              invalidTypes.push({ line: (index + 1).toString(), content: type });
              errorReport = true;
            }
        
            const missingTags = (tags && tags.length > 0)
              ? objectivesArray.filter(item => 
                  !tags.some(tag => typeof tag.text === 'string' && tag.text.toLowerCase() === item.toLowerCase())
                )
              : [];
            
            const missingTagsString = missingTags.join(', ');
        
            if (missingTags.length > 0) {
              invalidObjectives.push({ line: (index + 1).toString(), content: missingTagsString });
              errorReport = true;
            }
        
            const existingInvitation = listInvitation?.find(invitation => 
              invitation.email_program_invitation === email && 
              (invitation.participation_role_invitation === '0' ? 'Mentor' : 
                invitation.participation_role_invitation === '1' ? 'Mentorado' : '') === type
            );

            if (existingInvitation) {
              duplicateItemBd.push({
                line: (index + 1).toString(),
                content: email + ' | ' + type
              });
              errorReport = true;
            }


            const importLine = {
              name: row[nameKey] || '', 
              email, 
              type: row[typeKey] || '', 
              objectives: row[objectivesKey] || ''
            };
        
            importList.push(importLine);
          }
        });

        if(errorReport){

          setImportErrors(existingValues => ({
            ...existingValues,
            invalidMail: invalidMails,
            duplicateItemList: duplicateItemList,
            duplicateItemBd:duplicateItemBd,
            invalidTypes: invalidTypes,
            invalidObjectives: invalidObjectives
          }));


          setIsAnalyzing(false);
          setIsViewErrors(true);

        }
        else {    
          
          setIsViewErrors(false);
          alert(JSON.stringify(importList));
        }
    })
    .catch((errors: yup.ValidationError) => {
      errors.inner.forEach(error => {
        if (error.path) {
          const keyError = error.path;
          setColumnsSelectError(existingValues => ({
            ...existingValues,
            [keyError]: error.message
          }));
        }
      });
    });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Importar Lista</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {!fileLoaded  && (    
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center'}}>
              <EASButtonUpload
                variant="contained"
                accept=".xlsx, .xls"
                sx={{width:'290px'}}   
                disabled={loadingFile}
                onChangeFile={handleFileUpload}
                endIcon={loadingFile ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <UploadFileIcon/>}
              >
                {loadingFile ? 'Carregando Arquivo' :'Importar Lista de Convidados'}
              </EASButtonUpload>
            </Grid>
          )}
          {fileLoaded  && (    
            <>
              <Grid item xs={12} sx={{display:'flex', justifyContent:'center'}}>     
                <EASButton 
                    variant="outlined" 
                    sx={{width:'290px'}}   
                    disabled={loadingFile}
                    onClick={handleResetFileUpload}
                    endIcon={<CachedIcon/>}
                    >
                    Carregar outro Arquivo
                </EASButton>
              </Grid>

          </>
          )}
          
          <Grid item xs={12}>
            <Card sx={{ borderRadius: 2, bgcolor: 'transparent', p:1}} variant='outlined' elevation={0}>
              <Grid container spacing={0}>
                <Grid item xs={3}>   
                  <Typography variant="caption" sx={{fontWeight:'bold'}}>Nome do Arquivo:</Typography>
                </Grid>
                <Grid item xs={9}> 
                  <Typography variant="caption">{fileLoaded ? fileLoaded.name : 'Nenhum Arquivo Carregado'}</Typography>
                </Grid>
                <Grid item xs={3}> 
                  <Typography variant="caption" sx={{fontWeight:'bold'}}>Total de Linhas:</Typography>
                </Grid>
                <Grid item xs={9}> 
                  <Typography variant="caption">{fileLoaded ? rows.length : '-'}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>        
          {isAnalyzing && (    
          <Grid item xs={12} sx={{display:'flex', justifyContent:'center', minHeight:'360px'}}>
            <Grid item xs={12}>               
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress variant='indeterminate' />
              </Box>
            </Grid>
          </Grid>
          )}
          {isViewErrors && (
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center', minHeight:'360px'}}>
              <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{color:'error.main', fontWeight:'bold', textAlign:'center'}}>Não é possivel realiza a importação deste arquivos.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion sx={{ borderRadius: 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}  >
                        <Grid container spacing={1}>
                          <Grid item xs={true}>    
                            <Typography  variant="body2" sx={{fontWeight:'bold'}}>
                              Emails invalidos
                            </Typography>
                          </Grid>
                          <Grid item zeroMinWidth>
                              <Avatar sx={{ bgcolor: importErrors?.invalidMail?.length ? 'error.main' : 'success.main', height: '22px', width: '22px', fontSize: '12px' }} >
                                {importErrors?.invalidMail?.length ? importErrors?.invalidMail?.length : <CheckIcon />}
                              </Avatar>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      {importErrors?.invalidMail?.length && (
                      <AccordionDetails>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell component="th" scope="row"># Linha</TableCell>
                                <TableCell component="th" scope="row">Erro</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {importErrors?.invalidMail.map((row) => (
                                <StyledTableRow key={row.line}>
                                  <TableCell>{row.line}</TableCell>
                                  <TableCell>{row.content}</TableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                      )}
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion sx={{ borderRadius: 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}  >
                        <Grid container spacing={1}>
                          <Grid item xs={true}>    
                            <Typography  variant="body2" sx={{fontWeight:'bold'}}>
                              Papel na mentoria inválido
                            </Typography>
                          </Grid>
                          <Grid item zeroMinWidth>
                              <Avatar sx={{ bgcolor: importErrors?.invalidTypes?.length ? 'error.main' : 'success.main', height: '22px', width: '22px', fontSize: '12px' }} >
                                {importErrors?.invalidTypes?.length ? importErrors?.invalidTypes?.length : <CheckIcon />}
                              </Avatar>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      {importErrors?.invalidTypes?.length && (
                      <AccordionDetails>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell component="th" scope="row"># Linha</TableCell>
                                <TableCell component="th" scope="row">Erro</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {importErrors?.invalidTypes.map((row) => (
                                <StyledTableRow key={row.line}>
                                  <TableCell>{row.line}</TableCell>
                                  <TableCell>{row.content}</TableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                      )}
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion sx={{ borderRadius: 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}  >
                        <Grid container spacing={1}>
                          <Grid item xs={true}>    
                            <Typography  variant="body2" sx={{fontWeight:'bold'}}>
                              Conpetências Invalidas
                            </Typography>
                          </Grid>
                          <Grid item zeroMinWidth>
                              <Avatar sx={{ bgcolor: importErrors?.invalidObjectives?.length ? 'error.main' : 'success.main', height: '22px', width: '22px', fontSize: '12px' }} >
                                {importErrors?.invalidObjectives?.length ? importErrors?.invalidObjectives?.length : <CheckIcon sx={{fontSize:'10px'}} />}
                              </Avatar>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      {importErrors?.invalidObjectives?.length && (
                      <AccordionDetails>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell component="th" scope="row"># Linha</TableCell>
                                <TableCell component="th" scope="row">Erro</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {importErrors?.invalidObjectives.map((row) => (
                                <StyledTableRow key={row.line}>
                                  <TableCell>{row.line}</TableCell>
                                  <TableCell>{row.content}</TableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                      )}
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion sx={{ borderRadius: 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}  >
                        <Grid container spacing={1}>
                          <Grid item xs={true}>    
                            <Typography  variant="body2" sx={{fontWeight:'bold'}}>
                              Cadastros repetidos na planilha
                            </Typography>
                          </Grid>
                          <Grid item zeroMinWidth>
                              <Avatar sx={{ bgcolor: importErrors?.duplicateItemList?.length ? 'error.main' : 'success.main', height: '22px', width: '22px', fontSize: '12px' }} >
                                {importErrors?.duplicateItemList?.length ? importErrors?.duplicateItemList?.length : <CheckIcon />}
                              </Avatar>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      {importErrors?.duplicateItemList?.length && (
                      <AccordionDetails>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell component="th" scope="row"># Linha</TableCell>
                                <TableCell component="th" scope="row">Erro</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {importErrors?.duplicateItemList.map((row) => (
                                <StyledTableRow key={row.line}>
                                  <TableCell>{row.line}</TableCell>
                                  <TableCell>{row.content}</TableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                      )}
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion sx={{ borderRadius: 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}  >
                        <Grid container spacing={1}>
                          <Grid item xs={true}>    
                            <Typography  variant="body2" sx={{fontWeight:'bold'}}>
                              Cadastros já existentes no programa
                            </Typography>
                          </Grid>
                          <Grid item zeroMinWidth>
                              <Avatar sx={{ bgcolor: importErrors?.duplicateItemBd?.length ? 'error.main' : 'success.main', height: '22px', width: '22px', fontSize: '12px' }} >
                                {importErrors?.duplicateItemBd?.length ? importErrors?.duplicateItemBd?.length : <CheckIcon sx={{fontSize:'10px'}} />}
                              </Avatar>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      {importErrors?.duplicateItemBd?.length && (
                      <AccordionDetails>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell component="th" scope="row"># Linha</TableCell>
                                <TableCell component="th" scope="row">Erro</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {importErrors?.duplicateItemBd.map((row) => (
                                <StyledTableRow key={row.line}>
                                  <TableCell>{row.line}</TableCell>
                                  <TableCell>{row.content}</TableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                      )}
                    </Accordion>
                  </Grid>
              </Grid>
            </Grid>
          )}

            {!isAnalyzing && !isViewErrors && (
            <Grid item xs={12} sx={{display:'flex', justifyContent:'center', minHeight:'360px'}}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                
                </Grid>
                <Grid item xs={7}>
                  <EASCheck
                      disabled={!fileLoaded}
                      options={[{text:'A primeira linha é de cabeçalhos.', value:'1'}]}
                      value={columnsSelect?.checkHeaders ? [columnsSelect.checkHeaders] : []}
                      onChange={(e) =>                
                          setColumnsSelect(existingValues => ({
                              ...existingValues,
                              checkHeaders:e.target.checked ? '1' : ''
                          }))
                      }
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle2" sx={{my:2, textAlign:'right'}}>Nome</Typography>
                </Grid>
                <Grid item xs={7}>
                  <EASSelect 
                      options={columnsName}
                      disabled={!fileLoaded}
                      // disabled={!(programEdit?.match_program && programEdit.match_program == '1')}
                      onFocus={() =>
                        setColumnsSelectError(existingValues => ({
                          ...existingValues,
                          name: '',
                        }))
                      }
                      label="Selecione a Coluna"
                      getOptionLabel={(option) => option.text}    
                      getOptionDisabled={(option) => 
                        option != columnsName.find(item => item.value == columnsSelect?.name) && 
                        (
                          option == columnsName.find(item => item.value == columnsSelect?.email) 
                          ||
                          option == columnsName.find(item => item.value == columnsSelect?.type)
                          ||
                          option == columnsName.find(item => item.value == columnsSelect?.objectives)
                        )
                      }
                      value={columnsName && columnsName.find(item => item.value == columnsSelect?.name)}                                
                      onChangeSelect={(event, value) => 
                        setColumnsSelect(existingValues => ({
                          ...existingValues,
                          name: value === null ? '' : value.value,
                        }))
                      }             
                      error={!!columnsSelectError?.name}
                      helperText={columnsSelectError?.name}              
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle2" sx={{my:2, textAlign:'right'}}>E-mail</Typography>
                </Grid>
                <Grid item xs={7}>
                  <EASSelect 
                      options={columnsName}
                      disabled={!fileLoaded}
                      // disabled={!(programEdit?.match_program && programEdit.match_program == '1')}
                      onFocus={() =>
                        setColumnsSelectError(existingValues => ({
                          ...existingValues,
                          email: '',
                        }))
                      }
                      label="Selecione a Coluna"
                      getOptionLabel={(option) => option.text}   
                      getOptionDisabled={(option) => 
                        option != columnsName.find(item => item.value == columnsSelect?.email) && 
                        (
                          option == columnsName.find(item => item.value == columnsSelect?.name) 
                          ||
                          option == columnsName.find(item => item.value == columnsSelect?.type)
                          ||
                          option == columnsName.find(item => item.value == columnsSelect?.objectives)
                        )
                      }
                      value={columnsName && columnsName.find(item => item.value == columnsSelect?.email)}                               
                      onChangeSelect={(event, value) => 
                        setColumnsSelect(existingValues => ({
                          ...existingValues,
                          email: value === null ? '' : value.value,
                        }))
                      }        
                      error={!!columnsSelectError?.email}
                      helperText={columnsSelectError?.email}              
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle2" sx={{my:2, textAlign:'right'}}>Categoria de Participação</Typography>
                </Grid>
                <Grid item xs={7}>
                  <EASSelect 
                    options={columnsName}
                    disabled={!fileLoaded}                      
                    onFocus={() =>
                      setColumnsSelectError(existingValues => ({
                        ...existingValues,
                        type: '',
                      }))
                    }
                    label="Selecione a Coluna"
                    getOptionLabel={(option) => option.text}   
                    getOptionDisabled={(option) => 
                      option != columnsName.find(item => item.value == columnsSelect?.type) && 
                      (
                        option == columnsName.find(item => item.value == columnsSelect?.name) 
                        ||
                        option == columnsName.find(item => item.value == columnsSelect?.email)
                        ||
                        option == columnsName.find(item => item.value == columnsSelect?.objectives)
                      )
                    }
                    value={columnsName && columnsName.find(item => item.value == columnsSelect?.type)}                               
                    onChangeSelect={(event, value) => 
                      setColumnsSelect(existingValues => ({
                        ...existingValues,
                        type: value === null ? '' : value.value,
                      }))
                    } 
                    
                    error={!!columnsSelectError?.type}
                    helperText={columnsSelectError?.type}                   
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" sx={{my:2, textAlign:'right'}}>Competências</Typography>
                </Grid>
                <Grid item xs={7}>
                  <EASSelect 
                      options={columnsName}
                      disabled={!fileLoaded}
                      // disabled={!(programEdit?.match_program && programEdit.match_program == '1')}
                      label="Selecione a Coluna"
                      getOptionDisabled={(option) => 
                        option != columnsName.find(item => item.value == columnsSelect?.objectives) && 
                        (
                          option == columnsName.find(item => item.value == columnsSelect?.name) 
                          ||
                          option == columnsName.find(item => item.value == columnsSelect?.email)
                          ||
                          option == columnsName.find(item => item.value == columnsSelect?.type)
                        )
                      }
                      getOptionLabel={(option) => option.text}   
                      value={columnsName && columnsName.find(item => item.value == columnsSelect?.objectives)}                               
                      onChangeSelect={(event, value) => 
                        setColumnsSelect(existingValues => ({
                          ...existingValues,
                          objectives: value === null ? '' : value.value,
                        }))
                      }                  
                  />
                  <Typography variant="caption">Separar competências com vírgula(,)</Typography>
                </Grid>
              </Grid>
            </Grid>
            )}  
          
        </Grid>
      </DialogContent>
      <DialogActions>
        <EASButton onClick={() => setOpen(false)} variant="outlined">
          Cancelar
        </EASButton>
        <EASButton 
          variant="contained" 
          color="primary" 
          disabled={!fileLoaded || isAnalyzing || isViewErrors} 
          onClick={importList}
          endIcon={isAnalyzing ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <UploadIcon/>}
        >
          Importar
        </EASButton>
      </DialogActions>
    </Dialog>
    
  );
};
