import { IProgram, IProgramList } from '../../../types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

const createProgram = async (programs: IProgram): Promise<void> => {
  const { data } = await Api.post('/programs', { Program: programs });
  return data;
};

const updateProgram = async (Program: IProgram): Promise<void> => {
  const { data } = await Api.put(`/programs/${Program.id_program}`, Program);
  return data;
};

const deleteProgram = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/programs/${id}`);
  return data;
};


const getProgram = async (id?: string): Promise<IProgram> => {
  const { data } = await Api.get(`/programs/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};

const getProgramAddMentory = async (code: string): Promise<IProgram> => {
  const response = await Api.get(`/programs/?public_token=${Environment.PUBLIC_TOKEN}&code=${code}`);
  return response.data;
};


const getPrograms = async (page = 1, search = ''): Promise<IProgramList> => {
  const { data } = await Api.get(`/programs?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;
};

export const ProgramsService = {
  getPrograms,
  getProgram,
  createProgram,
  updateProgram,
  deleteProgram,
  getProgramAddMentory,

};
