import React, { ReactEventHandler } from 'react';
import { Dialog,  DialogContent, DialogContentText, DialogActions, Typography, Button, IconButton, Grid, Alert, CircularProgress, Box } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckIcon from '@mui/icons-material/Check';

interface IAlertComponent {
    title?: string | null;
    type: 'danger' | 'info' | 'warning' | 'success';
    statusOpen:boolean;
    loading?:boolean;
    actionCancel:ReactEventHandler;
    titleCancel?:string;
    actionConfirme?:ReactEventHandler;
    children: React.ReactNode;
}

export const AlertComponent:React.FC<IAlertComponent> = ({children, ...props}) => {

  const color = (()=> { 
    switch(props.type) {
      case 'danger': return 'error';//'#d32f2f';
      case 'info': return 'info';//'#0288d1';
      case 'warning': return 'warning';//'#ed6c02'; //
      case 'success': return 'success';//'#2e7d32'; //;
      default: return props.type;
  }})();

  const icon = (()=> { 
    switch(props.type) {
      case 'danger': return <CloseIcon sx={{ p:0, m:0,}} />;
      case 'info': return <QuestionMarkIcon sx={{ p:0, m:0,}}  />;
      case 'warning': return <PriorityHighIcon sx={{ p:0, m:0,}} />;
      case 'success': return <CheckIcon sx={{ p:0, m:0,}} />;
      default: return null;
  }})();


  return (
        <Dialog
          open={props.statusOpen}
          onClose={props.actionCancel}
          PaperProps={{ sx: { borderRadius: '12px' } }}
        >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={props.actionCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={props.loading}
          >
            <CloseIcon />
          </IconButton>
          <Grid container sx={{pr:1, minWidth:'500px'}}>
            <Grid item xs={1}>
              <Alert 
                severity={color} 
                icon={icon}
                sx={{ p:0, 
                      m:0, 
                      width: 31, 
                      height: 31, 
                      borderRadius:'50%',  
                      '& .MuiAlert-icon': {
                          m:0,
                          p:0.4,
                          pl:0.5,
                          alignContent:'center',
                          justifyContent: 'center',
                          textAlign:'center'
                        }
                    }}
              />
                  
            </Grid>
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{textAlign:'left', pb:2}}>
                    {props.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}> 
                  {props.loading && (                      
                      <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress variant='indeterminate' />
                      </Box>
                  )}

                  {!props.loading && (
                    <DialogContentText>
                      {children}
                    </DialogContentText>
                  )}
                </Grid>
          
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{mr:2, mb:1}}>


          {props.actionConfirme ? (
            <>
              <Button onClick={props.actionCancel} disabled={props.loading} variant="outlined">Desistir</Button>
              <Button onClick={props.actionConfirme} disabled={props.loading} variant="contained" autoFocus>
                Sim, Confirmar
              </Button>
            </>
          ) : (
            <Button onClick={props.actionCancel} disabled={props.loading} variant="contained">{props.titleCancel ? props.titleCancel : 'Entendido'}</Button>
          )}
        </DialogActions>
      </Dialog> 
  );
};
