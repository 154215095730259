import { Button, ButtonProps, Skeleton,  } from '@mui/material';

interface EASButtonProps extends ButtonProps {
    isLoading?: boolean | undefined;
    children: React.ReactNode;
}

export const EASButton: React.FC<EASButtonProps> = ({...props}) => {

    return (
        <>
          {props.isLoading && (
          <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px'}}/>
          )}
          {!props.isLoading && (
          <Button 
              color={props.color}
              sx={{borderRadius: 1, py:1, ...props.sx}}
              variant={props.variant} 
              onFocus={() => props.onFocus}
              type={props.type}
              value={props.value}
              onChange={props.onChange}  
              onKeyDown={props.onKeyDown} 
              size={props.size}
              disabled={props.disabled}
              onClick={props.onClick}
              fullWidth={props.fullWidth}    
              startIcon={props.startIcon}
              endIcon={props.endIcon}
              >
              {props.children}
          </Button>
          )}
        </>

    );
};
