import React, { Ref, useImperativeHandle, useRef, useState } from 'react';
import { Alert, Avatar, Badge, Box, Button, Card, CardContent, CardHeader, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid, Skeleton, Theme, ThemeProvider, Typography, useTheme } from '@mui/material';
import { IAcceptFile, IProgram } from '../../shared/types';
import { EASButton, EASColor, EASInput, EASRadio } from '../../shared/forms';
import BackupIcon from '@mui/icons-material/Backup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { EASFileUploadCrop } from '../../shared/components/eas-file-upload/EASFileUploadCrop';
import { centerCrop, convertToPixelCrop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';
import { useSnackbar } from 'notistack';
import { Environment } from '../../shared/environment';
import { DarkTheme, LightTheme } from '../../shared/themes';
import { useAuthContext } from '../../shared/contexts';


export interface IRefLayout {
  saveLayout: () => void;
}

type IProgramResourcesHumanParamsPanelProps = {
  program?: IProgram;
  isLoading?: boolean;
  storeUpdate: (operator: IProgram) => void;
}
const ProgramResourcesHumanLayoutPanel = React.forwardRef<IRefLayout, IProgramResourcesHumanParamsPanelProps>(({ program, isLoading, storeUpdate }: IProgramResourcesHumanParamsPanelProps, ref: Ref<IRefLayout>) => {

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const [programEdit, setProgramEdit] = useState<IProgram | undefined>(program);
  const [ImageNameProgram, setImageNameProgram] = useState<string | undefined>(undefined);
  const [ImageNameDarkProgram, setImageNameDarkProgram] = useState<string | undefined>(undefined);
  const [base64, setBase64] = useState<string | ArrayBuffer | null>();
  const [base64Dark, setBase64Dark] = useState<string | ArrayBuffer | null>();

  const { user } = useAuthContext();
  
  const [open, setOpen] = useState(false);
  const [openDark, setOpenDark] = useState(false);
  const [urlNewImage, setUrlNewImage] = useState<string | null>(null);
  const [urlNewImageDark, setUrlNewImageDark] = useState<string | null>(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileDark, setFileDark] = useState<File | undefined>(undefined);

  const ASPECT_RATIO = 2.5;
  const MIN_DIMENSION = 200;
  const deFaultCrop = makeAspectCrop({ unit: '%', width: 25, }, 200, 200, 200);

  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [percentCrop, setPercentCrop] = useState<PercentCrop>(deFaultCrop);
  const [isLoadingUploadDark, setIsLoadingUploadDark] = useState(false);
  const [percentCropDark, setPercentCropDark] = useState<PercentCrop>(deFaultCrop);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const previewCanvasRefDark = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const imgRefDark = useRef<HTMLImageElement>(null);

  const acceptFiles: IAcceptFile = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg']
  };

  useImperativeHandle(ref, () => ({
    saveLayout,
  }));

  const saveLayout = () => {
    if (!isLoading) {
      const updateProgram: IProgram = {
        id_program: program?.id_program,
        color_program: programEdit?.color_program,
        color_dark_program: programEdit?.color_dark_program,
        user_update: user?.id,
      };

      if(base64)
        updateProgram.base64_image_program = base64.toString();

      if(ImageNameProgram)
        updateProgram.name_temp_image_program = ImageNameProgram;

      if(base64Dark)
        updateProgram.base64_image_dark_program = base64Dark.toString();

      if(ImageNameDarkProgram)
        updateProgram.name_temp_image_dark_program = ImageNameDarkProgram;

      storeUpdate(updateProgram);
    }
  };
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDark = () => {
    setOpenDark(true);
  };

  const handleClose = () => {
    setFile(undefined);
    setPercentCrop(deFaultCrop);
    setOpen(false);
  };

  const handleCloseDark = () => {
    setFileDark(undefined);
    setPercentCropDark(deFaultCrop);
    setOpenDark(false);
  };

  const loadFile = (selectedFile: File) => {
    
    if (selectedFile) {
      setFile(selectedFile);

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const imageElement = new Image();
        const imageUrl = reader.result?.toString() || '';
        imageElement.src = imageUrl;

        imageElement.addEventListener('load', () => {

          const height = imageElement.height;
          const width = imageElement.width;

          const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

          const newCrop = makeAspectCrop(
            {
              unit: '%',
              width: cropWidthInPercent >= 25 ? cropWidthInPercent : 25,
            },
            ASPECT_RATIO,
            width,
            height
          );
          const centeredCrop: PercentCrop = centerCrop(newCrop, width, height);
          setPercentCrop(centeredCrop);
        });
      });
      reader.readAsDataURL(selectedFile);
    }
    else {
      setPercentCrop(deFaultCrop);
      setFile(undefined);
    }
  };

  const loadFileDark = (selectedFileDark: File) => {

    if (selectedFileDark) {
      setFileDark(selectedFileDark);

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const imageElementDark = new Image();
        const imageUrlDark = reader.result?.toString() || '';
        imageElementDark.src = imageUrlDark;

        imageElementDark.addEventListener('load', () => {

          const height = imageElementDark.height;
          const width = imageElementDark.width;

          const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

          const newCrop = makeAspectCrop(
            {
              unit: '%',
              width: cropWidthInPercent >= 25 ? cropWidthInPercent : 25,
            },
            ASPECT_RATIO,
            width,
            height
          );
          const centeredCrop: PercentCrop = centerCrop(newCrop, width, height);
          setPercentCropDark(centeredCrop);
        });
      });
      reader.readAsDataURL(selectedFileDark);
    }
    else {
      setPercentCropDark(deFaultCrop);
      setFileDark(undefined);
    }
  };

  const setCanvasPreview = async (image: HTMLImageElement | null, canvas: HTMLCanvasElement | null, crop: PixelCrop, scale = 1, rotate = 0) => {

    if (image === null || canvas === null)
      return;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    // Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
  };

  const toBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise((resolve) => {
      canvas.toBlob(resolve);
    });
  };

  const convertToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleCancelUpdate = () => {
    setFile(undefined);
    setPercentCrop(deFaultCrop);
    setImageNameProgram(undefined);
    setBase64(null);
    setUrlNewImage(null);
  };

  const handleCancelUpdateDark = () => {
    setFileDark(undefined);
    setPercentCrop(deFaultCrop);
    setImageNameDarkProgram(undefined);
    setBase64Dark(null);
    setUrlNewImageDark(null);
  };

  const storeAvatar = () => {

    setCanvasPreview(
      imgRef.current, // HTMLImageElement
      previewCanvasRef.current, // HTMLCanvasElement
      convertToPixelCrop(
        percentCrop,
        (imgRef.current == null ? 0 : imgRef.current.width),
        (imgRef.current == null ? 0 : imgRef.current.height)
      )
    );
    if (previewCanvasRef?.current && file) {

      const dataUrl = previewCanvasRef.current.toDataURL();
      setUrlNewImage(dataUrl);

      toBlob(previewCanvasRef.current).then((blob) => {

        if (!blob) {
          return '';
        }
        const newFileImage = new File([blob], file.name, { type: blob.type });
        setImageNameProgram(file.name);

        convertToBase64(newFileImage).then((response) => {
          if (response instanceof Error) {
            setUrlNewImage(null);
            setImageNameProgram(undefined);
            setBase64(null);
            setIsLoadingUpload(false);
            enqueueSnackbar('Erro ao carregar arquivo.', { variant: 'error' });
          }
          else {
            setBase64(response);
            setIsLoadingUpload(false);
          }
        });
      });
      setOpen(false);

    }
  };



  const storeAvatarDark = () => {
    setCanvasPreview(
      imgRefDark.current, // HTMLImageElement
      previewCanvasRefDark.current, // HTMLCanvasElement
      convertToPixelCrop(
        percentCropDark,
        (imgRefDark.current == null ? 0 : imgRefDark.current.width),
        (imgRefDark.current == null ? 0 : imgRefDark.current.height)
      )
    );
    if (previewCanvasRefDark?.current && fileDark) {
      const dataUrlDark = previewCanvasRefDark.current.toDataURL();
      setUrlNewImageDark(dataUrlDark);

      toBlob(previewCanvasRefDark.current).then((blob) => {

        if (!blob) {
          return '';
        }
        const newFileImageDark = new File([blob], fileDark.name, { type: blob.type });
        setImageNameDarkProgram(fileDark.name);

        convertToBase64(newFileImageDark).then((response) => {
          if (response instanceof Error) {
            setUrlNewImageDark(null);
            setImageNameDarkProgram(undefined);
            setBase64Dark(null);
            setIsLoadingUploadDark(false);
            enqueueSnackbar('Erro ao carregar arquivo.', { variant: 'error' });
          }
          else {
            setBase64Dark(response);
            setIsLoadingUploadDark(false);
          }
        });
      });
      setOpenDark(false);
    }
  };

  return (
    <Card sx={{ borderRadius: 2, m:2}} variant='outlined' elevation={0}>
      <CardHeader
        title='Configure o Logo e as cores de sua empresa para serem exibidos à quem cursa o programa.'
        titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', }}
      />
      <Divider />
      <CardContent>


      <Box sx={{ flexGrow: 1, display: 'flex', py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <ThemeProvider theme={LightTheme}>
              <Card sx={{ borderRadius: 2, }} variant='outlined' elevation={0}>
                <CardHeader
                  title='Configurações Modo Claro'
                  titleTypographyProps={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', }}
                />
                <Divider />
                <CardContent>
                  {(isLoading) && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
                      <Skeleton variant="circular" width={120} height={120} />
                    </Box>
                  )}
                  {(!isLoading) && (
                    <>
                      <Grid container spacing={2} sx={{ mt: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} md={8}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Card sx={{ borderRadius: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} variant='outlined' elevation={0}>
                                <CardHeader
                                  title='Imagem do programa'
                                  titleTypographyProps={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', }}
                                />
                                <Divider sx={{ width: '100%' }} />
                                <CardContent sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                  {urlNewImage ? (
                                    <>
                                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Badge
                                          overlap="circular"
                                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                          badgeContent={
                                            <Fab size="small" color="error" aria-label="add" onClick={handleCancelUpdate}>
                                              <DeleteForeverIcon />
                                            </Fab>
                                          }
                                        >
                                          <Avatar variant='square' sx={{ width: 250, height: 100 }} src={urlNewImage} />

                                        </Badge>
                                      </Box>
                                      <Alert severity="info" sx={{ marginTop: '15px' }}>Salve para confirmar a substituição da imagem ou cancele clicando no ícone da lixeira</Alert>
                                    </>
                                  ) : (
                                    <Badge
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                      badgeContent={
                                        <Fab size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
                                          <BackupIcon />
                                        </Fab>
                                      }
                                    >
                                      <Avatar variant="square" sx={{ width: 250, height: 100 }} src={programEdit?.image_program ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/program/medium_' + programEdit?.image_program : '/'} />
                                    </Badge>
                                  )}
                                </CardContent>
                                <Typography sx={{ fontSize: 11, fontStyle: 'italic' }}>
                                  Recomenda-se usar imagem com tamanho de 500x200.
                                </Typography>
                              </Card>
                            </Grid>
                            <Grid item xs={12} sx={{ position: 'initial', zIndex: 1000 }}>
                              <EASColor
                                label="Cor primária"
                                value={programEdit?.color_program ? programEdit.color_program : theme.palette.primary.main}
                                onChangeColor={(e) =>
                                  setProgramEdit(existingValues => ({
                                    ...existingValues,
                                    color_program: e,
                                  }))
                                }
                                onChange={(e) =>
                                  setProgramEdit(existingValues => ({
                                    ...existingValues,
                                    color_program: e.target.value,
                                  }))
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={(LightTheme: Theme) =>
                            createTheme({
                              ...LightTheme,
                              palette: {
                                ...LightTheme.palette,
                                primary: {
                                  main: (programEdit?.color_program ? programEdit.color_program : theme.palette.primary.main)
                                },
                                eas: {
                                  inputBackground: 'eas.inputBackground',
                                  backgroundTask: 'eas.backgroundTask',
                                },
                              },
                            })}
                          >
                            <Card sx={{ borderRadius: 2, mt: 3 }} variant='outlined' elevation={0}>
                              <CardHeader
                                title='Visualização Prévia do resultado da Cor nos elementos'
                                titleTypographyProps={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }}
                              />
                              <Divider />
                              <CardContent>
                                <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', px: 2 }}>
                                  <Grid item xs={12} md={6}>
                                    <EASRadio
                                      label='Radio'
                                      options={[{ text: 'Não', value: '0' }, { text: 'Sim', value: '1' }]}
                                      value={'0'}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <EASInput
                                      label='Entrada de texto'
                                      value='Texto'
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <EASButton size="large" fullWidth={true} variant="contained">
                                      Botão
                                    </EASButton>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <EASButton size="large" fullWidth={true} variant="outlined">
                                      Botão
                                    </EASButton>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </CardContent>
              </Card>
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} lg={6}>
            <ThemeProvider theme={DarkTheme}>
              <Card sx={{ borderRadius: 2, }} variant='outlined' elevation={0}>
                <CardHeader
                  title='Configurações Modo Claro'
                  titleTypographyProps={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', }}
                />
                <Divider />
                <CardContent>
                  {(isLoading) && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 5 }}>
                      <Skeleton variant="circular" width={120} height={120} />
                    </Box>
                  )}
                  {(!isLoading) && (
                    <>
                      <Grid container spacing={2} sx={{ mt: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} md={8}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Card sx={{ borderRadius: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} variant='outlined' elevation={0}>
                                <CardHeader
                                  title='Imagem do programa'
                                  titleTypographyProps={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', }}
                                />
                                <Divider sx={{ width: '100%' }} />
                                <CardContent sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                  {urlNewImageDark ? (
                                    <>
                                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Badge
                                          overlap="circular"
                                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                          badgeContent={
                                            <Fab size="small" color="error" aria-label="add" onClick={handleCancelUpdateDark}>
                                              <DeleteForeverIcon />
                                            </Fab>
                                          }
                                        >
                                          <Avatar variant='square' sx={{ width: 250, height: 100 }} src={urlNewImageDark} />

                                        </Badge>
                                      </Box>
                                      <Alert severity="info" sx={{ marginTop: '15px' }}>Salve para confirmar a substituição da imagem ou cancele clicando no ícone da lixeira</Alert>
                                    </>
                                  ) : (
                                    <Badge
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                      badgeContent={
                                        <Fab size="small" color="primary" aria-label="add" onClick={handleClickOpenDark}>
                                          <BackupIcon />
                                        </Fab>
                                      }
                                    >
                                      <Avatar variant="square" sx={{ width: 250, height: 100 }} src={programEdit?.image_dark_program ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/program/medium_' + programEdit?.image_dark_program : ''} />
                                    </Badge>
                                  )}
                                </CardContent>
                                <Typography sx={{ fontSize: 11, fontStyle: 'italic' }}>
                                  Recomenda-se usar imagem com tamanho de 500x200.
                                </Typography>
                              </Card>
                            </Grid>
                            <Grid item xs={12} sx={{ position: 'initial', zIndex: 1000 }}>
                              <EASColor
                                label="Cor primária"
                                value={programEdit?.color_dark_program ? programEdit.color_dark_program : theme.palette.primary.main}
                                onChangeColor={(e) =>
                                  setProgramEdit(existingValues => ({
                                    ...existingValues,
                                    color_dark_program: e,
                                  }))
                                }
                                onChange={(e) =>
                                  setProgramEdit(existingValues => ({
                                    ...existingValues,
                                    color_dark_program: e.target.value,
                                  }))
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <ThemeProvider theme={(DarkTheme: Theme) =>
                            createTheme({
                              ...DarkTheme,
                              palette: {
                                ...DarkTheme.palette,
                                primary: {
                                  main: (programEdit?.color_dark_program ? programEdit.color_dark_program : theme.palette.primary.main)
                                },
                                eas: {
                                  inputBackground: 'eas.inputBackground',
                                  backgroundTask: 'eas.backgroundTask',
                                },
                              },
                            })}
                          >
                            <Card sx={{ borderRadius: 2, mt: 3 }} variant='outlined' elevation={0}>
                              <CardHeader
                                title='Visualização Prévia do resultado da Cor nos elementos'
                                titleTypographyProps={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }}
                              />
                              <Divider />
                              <CardContent>
                                <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', px: 2 }}>
                                  <Grid item xs={12} md={6}>
                                    <EASRadio
                                      label='Radio'
                                      options={[{ text: 'Não', value: '0' }, { text: 'Sim', value: '1' }]}
                                      value={'0'}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <EASInput
                                      label='Entrada de texto'
                                      value='Texto'
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <EASButton size="large" fullWidth={true} variant="contained">
                                      Botão
                                    </EASButton>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <EASButton size="large" fullWidth={true} variant="outlined">
                                      Botão
                                    </EASButton>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </CardContent>
              </Card>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Enviar Imagem</DialogTitle>
          <Divider />
          <DialogContent sx={{ maxWidth: '380px' }}>
            <DialogContentText>
              <Typography component="span" variant="subtitle2" >
                Adicione ou atualize a imagem da pessoa. Recomendamos imagens quadradas.
              </Typography>
            </DialogContentText>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 3 }}>
              <EASFileUploadCrop
                titleButton="Enviar Arquivo"
                acceptFile={acceptFiles}
                isLoadingUpload={isLoadingUpload}
                elementIsDragAccept={<Box sx={{ p: 2, mb: 3, borderRadius: '8px', height: 140, border: '1px dashed green', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}><Typography color="green" variant="h6">Pode soltar a imagem agora</Typography></Box>}
                elementIsDragReject={<Box sx={{ p: 2, mb: 3, borderRadius: '8px', height: 140, border: '1px dashed red', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}><Typography color="red" variant="h6">Este arquivo não é permitido</Typography></Box>}
                elementIsNotDragActive={<Box sx={{ p: 2, mb: 3, borderRadius: '8px', height: 140, border: '1px dashed grey', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}><Typography color="grey" variant="h6">Clique ou arraste a imagem até aqui</Typography></Box>}
                setFile={(e) => loadFile(e)}
                file={file}
                previewCanvasRef={previewCanvasRef}
                imgRef={imgRef}
                setPercentCrop={(e) => setPercentCrop(e)}
                percentCrop={percentCrop}
                aspectRatio={2.5}
                circularCrop={false}
              />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} disabled={isLoadingUpload}>Cancelar</Button>
            <Button variant="contained" onClick={storeAvatar} disabled={isLoadingUpload}>Confirmar</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDark} onClose={handleCloseDark}>
          <DialogTitle>Enviar Imagem Dark</DialogTitle>
          <Divider />
          <DialogContent sx={{ maxWidth: '380px' }}>
            <DialogContentText>
              <Typography component="span" variant="subtitle2" >
                Adicione ou atualize a imagem da pessoa. Recomendamos imagens quadradas.
              </Typography>
            </DialogContentText>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 3 }}>
              <EASFileUploadCrop
                titleButton="Enviar Arquivo"
                acceptFile={acceptFiles}
                isLoadingUpload={isLoadingUploadDark}
                elementIsDragAccept={<Box sx={{ p: 2, mb: 3, borderRadius: '8px', height: 140, border: '1px dashed green', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}><Typography color="green" variant="h6">Pode soltar a imagem agora</Typography></Box>}
                elementIsDragReject={<Box sx={{ p: 2, mb: 3, borderRadius: '8px', height: 140, border: '1px dashed red', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}><Typography color="red" variant="h6">Este arquivo não é permitido</Typography></Box>}
                elementIsNotDragActive={<Box sx={{ p: 2, mb: 3, borderRadius: '8px', height: 140, border: '1px dashed grey', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}><Typography color="grey" variant="h6">Clique ou arraste a imagem até aqui</Typography></Box>}
                setFile={(e) => loadFileDark(e)}
                file={fileDark}
                previewCanvasRef={previewCanvasRefDark}
                imgRef={imgRefDark}
                setPercentCrop={(e) => setPercentCropDark(e)}
                percentCrop={percentCropDark}
                aspectRatio={2.5}
                circularCrop={false}
              />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDark} disabled={isLoadingUploadDark}>Cancelar</Button>
            <Button variant="contained" onClick={storeAvatarDark} disabled={isLoadingUploadDark}>Confirmar</Button>
          </DialogActions>
        </Dialog>

      </Box >
      </CardContent>

    </Card>
  );
});

ProgramResourcesHumanLayoutPanel.displayName = 'ProgramResourcesHumanLayoutPanel';

export default ProgramResourcesHumanLayoutPanel;


