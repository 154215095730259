import { IInvitation, IResponseInvitation } from '../../../types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

const create = async (invitation: IInvitation): Promise<IResponseInvitation> => {
  const { data } = await Api.post('/invitations', { Invitation: invitation });
  return data;
};

const read = async (page = 1, search = '', program = '', invitation = '' ): Promise<IResponseInvitation> => {
  const  {data} = await Api.get(`/invitations${invitation ? (`/${invitation}`) : ''}?public_token=${Environment.PUBLIC_TOKEN}&program=${program}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;
};

const update = async (invitation: IInvitation): Promise<IResponseInvitation> => {
  const { data } = await Api.put(`/invitations/${invitation.id_program_invitation}`, invitation);
  return data;
};

const destroy = async (id: string): Promise<IResponseInvitation> => {
  const { data } = await Api.delete(`/invitations/${id}`);
  return data;
};

export const InvitationService = {
  create,
  read,
  update,
  destroy,
};
