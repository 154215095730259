import React, { useMemo, useRef } from 'react';
import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { IMatch, IProgram } from '../../shared/types';

import { useHumanResourcesContext } from '../../shared/contexts';
import { ProgramsService } from '../../shared/services/api/programs/ProgramsService';
import { UtilService } from '../../shared/services/api/util/UtilService';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard, PaginationComponent } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import { MatchService } from '../../shared/services';
import { MatchResultsPanel } from './MatchResultsPanel';
import { useSearchParams } from 'react-router-dom';

export const MatchResults = () => {

    const { humanResource } = useHumanResourcesContext();

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const [searchParams, setSearchParams] = useSearchParams();
    
    const search = useMemo(() => {
        return searchParams.get('search') || '';
    }, [searchParams]);
    
    const page = useMemo(() => {
        return Number(searchParams.get('page') || '1');
      }, [searchParams]);

    const { refetch, data:match, isFetching:isLoadingMatch } =  useQuery(['match-result', humanResource?.id_program, page], () => MatchService.read(humanResource?.id_program, '1','', page), 
    {
      enabled: !!humanResource,
      staleTime: Infinity,
      onError: () => { 
        enqueueSnackbar('Erro ao carregar dados do perfil.', { variant:'error'});
      },
    });

/*
    const { data:match, isFetching: isLoadingMatch } = useQuery(['match-result', humanResource?.id_program], () => MatchService.read(humanResource?.id_program), {
      enabled: !!humanResource,
      staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Erro ao carregar dados do match.', { variant: 'error' });
        },
    });

*/
/*


    const { mutateAsync: storeUpdate, isLoading: isLoadingStore } = useMutation((updateMatch: IMatch) => MatchService.update(updateMatch), {
        onSuccess: () => {
            queryClient.invalidateQueries('match-edit');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant: 'error' });
        },
    });

*/
    return (
        <LayoutDefault >
          <EASCard
            titleCard={'Resultados do Match'}
            breadcrumbsCard=""
            actionCard={match?.item?.match_program == '1' ? (<EASButton variant="contained" onClick={() => refetch()}>Calcular Match</EASButton>) : <></>} 
          >
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card sx={{ borderRadius: 2, m:2}} variant='outlined' elevation={0}>
                    <CardHeader
                      action={<Stack></Stack>}
                      title='Sugestões do Match para formação de duplas'
                      titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold' }}
                    />
                    <Divider />
                    <CardContent>
                      {isLoadingMatch && (
                      <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress variant='indeterminate' />
                      </Box>
                      )}
                      {!isLoadingMatch && (
                      <>
                        <MatchResultsPanel match={match?.status === 'success' ? match?.list : undefined} />
                        <PaginationComponent page={page} total={match?.list?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
                      </>
                     )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </EASCard>
        </LayoutDefault>
    );
};
