import React, { useImperativeHandle, useState, Ref } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { ICustomInput, IMatch, IProgram } from '../../shared/types';

import { EASButton } from '../../shared/forms';
import { CustomInputComponent } from '../../shared/components';


export interface IRefMatchQuestions {
  saveMatch: () => void;
}

interface IMatchQuestionsPanelProps {
  match?:IMatch;
  isLoading?: boolean;
  storeUpdate: (operator: IMatch) => void;
}

const MatchQuestionsPanel = React.forwardRef<IRefMatchQuestions, IMatchQuestionsPanelProps>(({ match, isLoading, storeUpdate }: IMatchQuestionsPanelProps, ref: Ref<IRefMatchQuestions>) => {

  const [matchEdit, setMatchEdit] = useState<IMatch | undefined>(match);
  const [customInputs, setCustomInputs] = useState<Array<ICustomInput>>(match?.match_custom_input_program || []);

  const destroyCustomInput = (customInput:ICustomInput) => {
    const newCustomInputs =  customInputs?.filter(item =>item.id_custom_input !== customInput.id_custom_input);
    setCustomInputs(newCustomInputs);
  };

  const addCustomInput = () => {
    const newCustomInput:ICustomInput = {id_custom_input:Number(customInputs?.length + 1).toString()};
    setCustomInputs(existingValues => [...existingValues, newCustomInput]);
  };

  const setCustomInput = (customInput:ICustomInput) => {
    const newCustomInputs =  customInputs?.map(item => item.id_custom_input === customInput.id_custom_input ? customInput : item );
    setCustomInputs(newCustomInputs);
    setMatchEdit(existingValues => ({
      ...existingValues,
      match_custom_input_program: newCustomInputs,
    }));
  };

  useImperativeHandle(ref, () => ({
    saveMatch,
  }));
  
  
    const saveMatch = () => {
      if (!isLoading) {
        const updateMatch: IProgram = {
          id_program: match?.id_program,
          match_custom_input_program: matchEdit?.match_program == '1' ? matchEdit?.match_custom_input_program : undefined,  
        };
        storeUpdate(updateMatch);
      }
    };
  

  return (
    <Card sx={{ borderRadius: 2, m:2 }} variant='outlined' elevation={0}>
      <CardHeader
        action={<EASButton variant="contained" size="small" disabled={!(match?.match_program && match.match_program == '1')} onClick={() => addCustomInput()}>Criar Nova Pergunta</EASButton>}
        title='Lista de Perguntas do Match'
        titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold' }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {customInputs.length > 0 ? customInputs.map((customInput, index) => (
          <Grid item key={index} xs={12} lg={4} xl={3}>
            <CustomInputComponent isLoading={isLoading} customInput={customInput} newCustomInput={customInput?.name_custom_input ? false : true} setCustomInput={ (customInput)=> setCustomInput(customInput)} destroyCustomInput={(customInput) => destroyCustomInput(customInput)}/>
          </Grid>
          )) : (
          <Grid item xs={12}>
            <Typography variant="overline">Nenhuma Pergunta cadastrada</Typography>
          </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
});

MatchQuestionsPanel.displayName = 'MatchQuestionsPanel';
export default MatchQuestionsPanel;
