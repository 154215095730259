


import React, { useState , ReactElement } from 'react';

import { Box, Typography, Card, CardHeader, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Skeleton, Tooltip } from '@mui/material/';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { IInvitationList } from '../../shared/types';
import { EASButtonList } from '../../shared/forms';




interface IProgramResourcesHumanInvitationsListProps {
  invitations?: IInvitationList;
  isLoading?: boolean;
  handleResendInvite:(invitation?:string, email?:string) => void
  handleRemoveInvite:(invitation?:string) =>  void

}  

export const ProgramResourcesHumanInvitationsList: React.FC<IProgramResourcesHumanInvitationsListProps> = ({ invitations, isLoading, handleResendInvite, handleRemoveInvite }) => {

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', py: 2 }}>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">
                Nome
              </TableCell>
              <TableCell component="th" scope="row">
                E-mail
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Papel
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Conpetências
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Cadastro
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Status
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {isLoading && (
            <>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
          )}
          {!isLoading && ( (invitations?.data && invitations?.data.length > 0) ? (invitations.data.map((programsInvitation) => (
            <TableRow 
              hover 
              key={programsInvitation.id_program_invitation} 
              tabIndex={-1} 
              role="checkbox"
            >
              <TableCell>
                {programsInvitation?.name_program_invitation}
              </TableCell>
              <TableCell>
                {programsInvitation?.email_program_invitation}
              </TableCell>
              <TableCell align="center">
                {programsInvitation?.name_participation_role_invitation}
              </TableCell>
              <TableCell align="center">
                { programsInvitation?.name_objectives_invitation && 
                  programsInvitation.name_objectives_invitation.length > 25 ? (
                  <Tooltip title={programsInvitation.name_objectives_invitation}>
                    <Box>
                      {programsInvitation.name_objectives_invitation.slice(0, 25) + '...'}
                    </Box>
                  </Tooltip>
                ) : 
                (
                  programsInvitation?.name_objectives_invitation ? programsInvitation?.name_objectives_invitation : 'Não definido'
                )}
              </TableCell>
              <TableCell align="center" sx={{width:'70px'}}>
                { programsInvitation?.date_update && new Date(programsInvitation?.date_update).toLocaleDateString('pt-BR')}
              </TableCell>
              <TableCell align="center" sx={{width:'40px'}}>
                {programsInvitation?.status_program_invitation == '0' && (
                <Tooltip title="Convite Rejeitado" sx={{mr:0.5}}>
                  <CancelIcon  color="error" fontSize="small" />
                </Tooltip>
                )}
                {programsInvitation?.status_program_invitation == '1' && (
                <Tooltip title="Convite Pendente" sx={{mr:0.5}}>
                  <PendingIcon color="info" fontSize="small" />
                </Tooltip>
                )}
                {(programsInvitation?.status_program_invitation == '2' || programsInvitation?.status_program_invitation == '3') && (
                <Tooltip title="Convite Aceito" sx={{mr:0.5}}>
                  <CheckCircleIcon color="success" fontSize="small" />
                </Tooltip>
                )}
              </TableCell>
              <TableCell align="center" sx={{width:'60px'}}>
                <EASButtonList 
                    title="Reenviar Convite"
                    onClick={() => handleResendInvite(programsInvitation.id_program_invitation, programsInvitation?.email_program_invitation)} 
                    typeIcon="mail"
                    disabled={!!(programsInvitation?.status_program_invitation == '2'|| programsInvitation?.status_program_invitation == '3')}
                />  
                <EASButtonList 
                  title="Remover Convite"
                  onClick={() => handleRemoveInvite(programsInvitation.id_program_invitation)} 
                  typeIcon="remove"
                  disabled={!!( programsInvitation?.status_program_invitation == '3')}
                />
              </TableCell> 
            </TableRow>
          ))) : (
            <TableRow hover tabIndex={-1} role="checkbox" >
              <TableCell colSpan={6} scope="row" >
                Nenhum item encontrado 
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

