import { createTheme } from '@mui/material';
import { grey, orange } from '@mui/material/colors';

export const DarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: orange[700],
      dark: orange[800],
      light: orange[600],
      contrastText: '#F0F0F0',
    },
    secondary: {
      main: grey[800],
      dark: grey[900],
      light: grey[700],
      contrastText: '#ffffff',
    },
    background: {
      paper: '#303134',
      default: '#1f1f1f',
    },
    text: {
      primary: '#fff',
      secondary: '#dddddd',
      disabled: '#808183',
    },
    eas: {
      inputBackground: '#1f1f1f',
      inputBackgroundDisabled: '#494a4d',
      backgroundTask: '#191207',
    },
  },
  typography: {
    allVariants: {
      color: 'white',
    }
  }
});
