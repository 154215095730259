import React, { ReactEventHandler, useEffect, useState } from 'react';
import { Dialog,  DialogContent, Typography, IconButton, Grid, DialogTitle, Card, CardHeader, CardContent, Divider, ButtonGroup, DialogActions, FormHelperText, Alert, Box } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { EASButton, EASCustom, EASInput, EASSelect } from '../../forms';
import { ICustomInput, ICustomInputRules, IList } from '../../types';
import { AlertComponent } from '../AlertComponent';

import * as yup from 'yup';
import { isolateNotes } from 'spectacle';

interface ICustomInputComponentProps {
    isLoading?:boolean;
    newCustomInput?:boolean;
    customInput?: ICustomInput;
    setCustomInput: (customUmput: ICustomInput) => void;
    destroyCustomInput: (customUmput: ICustomInput) => void;
}

export const CustomInputComponent:React.FC<ICustomInputComponentProps> = ({customInput, setCustomInput, destroyCustomInput, newCustomInput, ...props}) => {

  const [openCustomInput, setOpenCustomInput] = useState<boolean>(false);
  const [openAlertRemove, setOpenAlertRemove] = useState<boolean>(false);
  const [customInputEdit, setCustomInputEdit] = useState<ICustomInput | undefined>();
  const [customInputEditError, setCustomInputEditError] = useState<ICustomInput | null>(null);
  const [optionsCustomInputError, setOptionsCustomInputError] = useState<boolean>(false);
  const [rulesCustomInputError, setRulesCustomInputError] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  
  const typeCustomInput:Array<IList> = [
    { text: 'Caixa de Seleção',   value: '1'  },
    { text: 'Escolha Única',   value: '2'  },
    { text: 'Escolha Multipla',   value: '3'  },
  ];

  const weightOptions = [ { text: 'Ideal', value: '1' },
                          { text: 'Aceitável', value: '0.5' },
                          { text: 'Tolerável', value: '0.1' },
                          { text: 'Descartar', value: '-1' },
  ];

  const weightCustomInput = [ { text: 'Normal', value: '1' },
                              { text: 'Importante', value: '1.5' },
                              { text: 'Muito Importante', value: '2' },
                            ];

  const isValidCustomImput = yup.object({
    name_custom_input: yup.string().required('Este campo é obrigatório'),
    type_custom_input: yup.string().required('Este campo é obrigatório'),
    
    weight_custom_input: yup.string().required('Este campo é obrigatório'),
        
    options_custom_input: yup.array()
    .of(
      yup.object({
        text: yup.string().required('Este campo é obrigatório'),
        value: yup.string().required('Este campo é obrigatório'),
      })
    )
    .min(2, 'É necessário criar no mínimo duas opções')
    .required('O campo de opções é obrigatório'),

    rules_custom_input: yup.array()
    .of(
      yup.object({
        id: yup.string().required('Este campo é obrigatório'),
        mentor: yup.array().min(1, 'Selecione no mínimo uma opção').required('Este campo é obrigatório'), 
        mentorado: yup.array().min(1, 'Selecione no mínimo uma opção').required('Este campo é obrigatório'),
        weight: yup.string().required('Campo obrigatório'),

      })
    )
    .min(1, 'É necessário criar no mínimo uma regra') // Valida se o array tem pelo menos 2 elementos
    .required('O campo de opções é obrigatório'),

  });

  useEffect(() => {
    if(newCustomInput)
      setOpenCustomInput(true);
  }, []);
  
  useEffect(() => {
    setOpenAlertRemove(false);
  }, [ destroyCustomInput]);

  useEffect(() => {
    const loadingCustomInput = async  () => {     
      setCustomInputEdit(customInput);
      const modifiedCustomInputError = {
        options_custom_input: customInput?.options_custom_input?.map(item => ({
          ...item,
          text: ''
        })),
        rules_custom_input: customInput?.rules_custom_input?.map(item => ({
          ...item,
          mentor: [],
          mentorado: [],
          weight: '',
        }))
      };
      setCustomInputEditError(modifiedCustomInputError);
    };

    if(openCustomInput === true ) {
      setSaveLoading(true);
      loadingCustomInput().then(() =>{
        setSaveLoading(false);
      });
    }
  }, [ openCustomInput]);

  const closeCustomInput = () => {
    setOpenCustomInput(false);
    setCustomInputEdit({});
    setCustomInputEditError(null);
    if(newCustomInput && customInputEdit)
      destroyCustomInput(customInputEdit);
  };

  const handleDestroyCustomInput = () => {
    if(customInputEdit)
      destroyCustomInput(customInputEdit);
    setOpenCustomInput(false);
    setSaveLoading(false);
  };

  const handleSaveCustomInput = () => {
    
    isValidCustomImput
      .validate(customInputEdit, { abortEarly: false })
      .then(validateProgram => {
        setSaveLoading(true);
        const updateCustomInputs: ICustomInput = customInputEdit ? customInputEdit : {};

        setCustomInput(updateCustomInputs);
        setCustomInputEdit({});
        setCustomInputEditError(null);
        setOpenCustomInput(false);
        setSaveLoading(false);
      })
      .catch((errors: yup.ValidationError) => {
        const optionsCustomInputErrors = customInputEditError?.options_custom_input || [];
        let updatedOptionsCustomInputErrors = [...optionsCustomInputErrors]; 

        const rulesCustomInputErrors = customInputEditError?.rules_custom_input || [];
        let updatedRulesCustomInputErrors = [...rulesCustomInputErrors]; 

        errors.inner.forEach(error => {
          if (error.path) {
            const keyError = error.path;
            const regex = /\[([0-9]+)\]/;
            if (keyError.includes('options_custom_input')) {
              if (keyError.includes('options_custom_input[')) {
                const match = keyError.match(regex);
                if (match) {
                  const indexError = Number(match[1]);
                  const valueSearch = customInputEdit?.options_custom_input?.[indexError]?.value;

                  updatedOptionsCustomInputErrors = updatedOptionsCustomInputErrors.map(item =>
                    item.value === valueSearch ? { ...item, text: error.message } : item
                  );
                }
              } else {
                setOptionsCustomInputError(true);
              }
            }
            else{
              if (keyError.includes('rules_custom_input')) {
                if (keyError.includes('rules_custom_input[')) {
                  const match = keyError.match(regex);
                  if (match) {
                    const indexError = Number(match[1]);
                    const valueSearch = customInputEdit?.rules_custom_input?.[indexError]?.id;

                    const matchKey = keyError.match(/\.(\w+)$/);
                    
                    if(matchKey) {
                      const key = matchKey[1];
                      
                      if(key !== 'mentor' && key  !== 'mentorado'){
                        updatedRulesCustomInputErrors = updatedRulesCustomInputErrors.map(item =>
                          item.id === valueSearch ? { ...item, [key]: error.message } : item
                        );
                      }
                      else {
                        updatedRulesCustomInputErrors = updatedRulesCustomInputErrors.map(item =>
                          item.id === valueSearch ? { ...item, [key]: error.message } : item
                        );
                      }
                    }
                  }
                } else {
                  setRulesCustomInputError(true);
                }
              } else {
                setCustomInputEditError(existingValues => ({
                  ...existingValues,
                  [keyError]: error.message
                }));
              }
            } 
          }
        });
        setCustomInputEditError(existingValues => ({
          ...existingValues,
          options_custom_input: updatedOptionsCustomInputErrors
        }));
        setCustomInputEditError(existingValues => ({
          ...existingValues,
          rules_custom_input: updatedRulesCustomInputErrors
        }));
      });
  };

  const addOptions = () => {
    
    const newItem: IList = {
      value: customInputEdit?.options_custom_input?.length
        ? (customInputEdit.options_custom_input.length + 1).toString()
        : '1',
      text: '',
    };
    
    const updatedList = [...(customInputEdit?.options_custom_input || []), newItem];
    const updatedListError = [...(customInputEditError?.options_custom_input || []), newItem];
    
    setCustomInputEdit({ ...customInputEdit, options_custom_input: updatedList });
    setCustomInputEditError({ ...customInputEditError, options_custom_input: updatedListError });

    setOptionsCustomInputError(false);
  };

  const deleteOptions = (value_option:string) => {
    const newList:Array<IList> =  customInputEdit?.options_custom_input?.length ? customInputEdit?.options_custom_input?.filter(
      (_: any) => _.value !== value_option
    ) : [];

    const newListError = customInputEditError?.options_custom_input || [];
    const filteredError = newListError.filter( item => item.value !== value_option);
    setCustomInputEdit(existingValues => ({
      ...existingValues,
      options_custom_input: newList,
    }));
    setCustomInputEditError(existingValues => ({
      ...existingValues,
      options_custom_input: filteredError,
    }));

  };
  
  const addRules = () => {
    
    const newRule: ICustomInputRules = {
      id: customInputEdit?.rules_custom_input?.length
        ? (customInputEdit.rules_custom_input.length + 1).toString()
        : '1',
      mentor: [],        
      mentorado: [],
      weight: '',
    };
    
    const updatedList = [...(customInputEdit?.rules_custom_input || []), newRule];
    const updatedListError = [...(customInputEditError?.rules_custom_input || []), newRule];
    
    setCustomInputEdit({ ...customInputEdit, rules_custom_input: updatedList });
    setCustomInputEditError({ ...customInputEditError, rules_custom_input: updatedListError });
    
    setRulesCustomInputError(false);
  };

  const deleteRules = (id_rules:string) => {
    const newList:Array<ICustomInputRules> =  customInputEdit?.rules_custom_input?.length ? customInputEdit?.rules_custom_input?.filter(
      (_: any) => _.id !== id_rules
    ) : [];

    const newListError = customInputEditError?.rules_custom_input || [];
    const filteredRules = newListError.filter( item => item.id !== id_rules);
    setCustomInputEdit(existingValues => ({
      ...existingValues,
      rules_custom_input: newList,
    }));
    setCustomInputEditError(existingValues => ({
      ...existingValues,
      rules_custom_input: filteredRules,
    }));

  };

  return (
        <>
        <EASInput
          isLoading={props.isLoading}
          handleIconLeft={() => setOpenCustomInput(true)}
          nameIconLeft={'edit'}
          handleIconRight={() => setOpenAlertRemove(true)}
          nameIconRight={'close'}
          label='Campo Personalizado'
          type={'text'}
          value={(customInput?.name_custom_input ? customInput.name_custom_input : ('CAMPO #' + customInput?.id_custom_input))}
        />
        <Dialog
          open={openCustomInput}
          PaperProps={{ sx: { borderRadius: '12px' } }}
          fullWidth
          maxWidth={'md'}
        >
        <DialogTitle>
          {'Parâmetros do Campo Personalizado'}
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={closeCustomInput}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={props.isLoading}
          >
            <CloseIcon />
          </IconButton>
        <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <EASInput
                    isLoading={saveLoading}
                    onFocus={() =>
                      setCustomInputEditError(existingValues => ({
                            ...existingValues,
                            name_custom_input: '',
                        }))
                    }
                    label="Nome do Campo"
                    type="text"
                    value={customInputEdit?.name_custom_input}
                    onChange={(e) =>
                        setCustomInputEdit(existingValues => ({
                            ...existingValues,
                            name_custom_input: e.target.value,
                        }))
                    }
                    error={!!customInputEditError?.name_custom_input}
                    helperText={customInputEditError?.name_custom_input}
                />
              </Grid>
              <Grid item md={6}>
                <EASInput
                    isLoading={saveLoading}
                    onFocus={() =>
                      setCustomInputEditError(existingValues => ({
                            ...existingValues,
                            label_custom_input: '',
                        }))
                    }
                    label="Enunciado do Campo"
                    type="text"
                    value={customInputEdit?.label_custom_input}
                    onChange={(e) =>
                        setCustomInputEdit(existingValues => ({
                            ...existingValues,
                            label_custom_input: e.target.value,
                        }))
                    }
                    error={!!customInputEditError?.label_custom_input}
                    helperText={customInputEditError?.label_custom_input}
                />
              </Grid>
              <Grid item xs={6}> 
                <EASSelect
                    isLoading={saveLoading}
                    onFocus={() =>
                      setCustomInputEditError(existingValues => ({
                            ...existingValues,
                            type_custom_input: '',
                        }))
                    }
                    defaultValue={null}
                    options={typeCustomInput ? typeCustomInput : []}
                    label="Tipo"
                    getOptionLabel={(option) => option.text}
                    value={typeCustomInput && typeCustomInput.find(item => item.value == customInputEdit?.type_custom_input)}
                    onChangeSelect={(event, value: IList) => {
                        if (value && value.value) {
                            setCustomInputEdit(existingValues => ({
                                ...existingValues,
                                type_custom_input: value.value,
                            }));
                        }

                    }
                    }
                    error={!!customInputEditError?.type_custom_input}
                    helperText={customInputEditError?.type_custom_input}
                />
              </Grid>
              <Grid item xs={6}> 
                <EASSelect
                  isLoading={saveLoading}
                  defaultValue={null}
                  onFocus={() =>
                    setCustomInputEditError(existingValues => ({
                          ...existingValues,
                          weight_custom_input: '',
                      }))
                  }
                  options={weightCustomInput ? weightCustomInput : []}
                  label="Relevância da Questão"
                  getOptionLabel={(option) => option.text}
                  value={weightCustomInput && weightCustomInput.find(item => item.value == customInputEdit?.weight_custom_input)}
                  onChangeSelect={(event, value: IList) => {
                      if (value && value.value) {
                        setCustomInputEdit(existingValues => ({
                            ...existingValues,
                            weight_custom_input: value.value,
                        }));
                      }
                    }
                  }
                  error={!!customInputEditError?.weight_custom_input}
                  helperText={customInputEditError?.weight_custom_input}
                />
              </Grid>
            </Grid>
          </Grid> 
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}> 
                    <Card elevation={0}                     
                          sx={{
                            border:'solid',
                            borderColor: optionsCustomInputError ? 'error.main' : 'grey.300',
                            borderWidth: 1,
                            borderRadius: 2,
                          }}
                    >
                      <CardHeader                 
                        title={<Typography sx={{color:'grey.600'}} >Opções do Campo</Typography>}
                        subheader={
                          <>
                          {optionsCustomInputError && (
                            <FormHelperText sx={{color:'error.main'}} >É necessário criar no mínimo duas opções</FormHelperText>
                          )}
                          </>
                        }
                        sx={{
                          backgroundColor:'grey.200'
                        }}
                        action={customInputEdit?.type_custom_input && customInputEdit.type_custom_input != '' && (
                          <EASButton variant="contained" size="small" onClick={() => addOptions()}>Adicionar</EASButton>
                        )}
                      />
                      <CardContent>
                        {customInputEdit?.type_custom_input && customInputEdit.type_custom_input != ''  ? (
                          <Grid container spacing={2}>
                            {customInputEdit?.options_custom_input && customInputEdit.options_custom_input.length > 0 ? customInputEdit.options_custom_input.map((options, index) => (
                              <Grid key={index} item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={true}>
                                    <EASInput
                                      isLoading={saveLoading}
                                      onFocus={() =>
                                        setCustomInputEditError(existingValues => ({
                                          ...existingValues,
                                          options_custom_input: customInputEditError?.options_custom_input?.length ? 
                                                                  customInputEditError?.options_custom_input.map(item =>
                                                                    item.value === options?.value ? { ...item, text: '' } : item
                                                                  )
                                                                : undefined
                                          }))
                                      }
                                      size="small"
                                      label={'Opção ' + (index + 1)} 
                                      type="text"
                                      value={options?.text}
                                      onChange={(e) =>
                                        setCustomInputEdit(existingValues => ({
                                          ...existingValues,
                                          options_custom_input: customInputEdit?.options_custom_input?.length ? 
                                                                  customInputEdit?.options_custom_input.map(item =>
                                                                    item.value === options?.value ? { ...item, text: e.target.value } : item
                                                                  )
                                                                : undefined
                                          }))
                                      }
                                      error={!!customInputEditError?.options_custom_input?.[index]?.text}
                                      helperText={customInputEditError?.options_custom_input?.[index]?.text}
                                    />
                                  </Grid>
                                  <Grid item zeroMinWidth>
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => deleteOptions(options?.value)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )):(
                              <Grid item>
                                <Typography>Nenhuma Opção Cadastrada</Typography>
                              </Grid>
                            )} 
                          </Grid>
                        ):(
                          <Grid item>
                            <Typography>Escolha o Tipo de Campo</Typography>
                          </Grid>
                        )} 
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Grid container spacing={2}>  
                  <Grid item xs={12}> 
                    <Card elevation={0}                     
                          sx={{
                            border:'solid',
                            borderColor: '#367cf7',
                            borderWidth: 1,
                            borderRadius: 2,
                          }}
                        >
                      <CardHeader title={<Typography sx={{color:'#367cf7'}} >Pré-Visualização</Typography>} />
                      <Divider sx={{borderColor:'#367cf7'}} />
                      <CardContent sx={{pb:0}}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            gap: 2,
                          }}
                        >
                          {customInputEdit?.label_custom_input && (
                          <Box>
                            <Alert severity="info">
                              {customInputEdit?.label_custom_input}
                            </Alert>
                          </Box>
                          )}
                          <Box sx={{ width: '100%', mb: 0 }}>
                            <EASCustom isLoading={saveLoading} customInput={customInputEdit} />
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}> 
            <Card elevation={0}                     
              sx={{
                border:'solid',
                borderColor: rulesCustomInputError ? 'error.main' : 'grey.300',
                borderWidth: 1,
                borderRadius: 2,
              }}
            >
              <CardHeader                 
                title={<Typography sx={{color:'grey.600'}} >Regras e Pesos</Typography>}
                subheader={<>
                  {rulesCustomInputError && (
                    <FormHelperText sx={{color:'error.main'}} >É necessário criar no mínimo uma regra</FormHelperText>
                  )}
                  </>}
                sx={{
                  backgroundColor:'grey.200'
                }}
                action={customInputEdit?.type_custom_input && customInputEdit.type_custom_input != '' && (
                  <EASButton variant="contained" size="small" onClick={addRules}>Adicionar</EASButton>
                )}
              />
              <CardContent>
                {customInputEdit?.type_custom_input && customInputEdit.type_custom_input != '' && (
                  <Grid container spacing={2}>
                    {customInputEdit?.rules_custom_input && customInputEdit.rules_custom_input.length > 0 ? customInputEdit.rules_custom_input.map((rules, index) => (
                      <Grid key={index} item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={true}>
                            <EASSelect
                              isLoading={saveLoading}
                              defaultValue={null}
                              multipleSelect={true}
                              size="small"
                              onFocus={() =>
                                setCustomInputEditError(existingValues => ({
                                  ...existingValues,
                                  rules_custom_input: customInputEditError?.rules_custom_input?.length ? 
                                                          customInputEditError?.rules_custom_input.map(item =>
                                                            item.id === rules?.id ? { ...item, mentorado: [] } : item
                                                          )
                                                        : undefined
                                }))
                              }
                              options={customInputEdit?.options_custom_input ? customInputEdit?.options_custom_input : []}
                              label="Se a escolha do mentorado conter algum"
                              getOptionLabel={(options) => options.text}
                              value={ rules?.mentorado && rules.mentorado.map((option) => (customInputEdit?.options_custom_input && customInputEdit.options_custom_input.find(item => item.value == option)))}
                              onChangeSelect={(event, values: Array<IList>) => {
                                const NewValue: Array<string> = [];
                                values.map((value) => NewValue.push(value.value));
                                setCustomInputEdit(existingValues => ({
                                  ...existingValues,
                                  rules_custom_input: customInputEdit?.rules_custom_input?.length ? 
                                                          customInputEdit?.rules_custom_input.map(item =>
                                                            item.id === rules?.id ? { ...item, mentorado: NewValue } : item
                                                          )
                                                        : 
                                                        undefined
                                  }));
                                }
                              }

                              error={!!customInputEditError?.rules_custom_input?.[index]?.mentorado?.length}
                              helperText={customInputEditError?.rules_custom_input?.[index]?.mentorado}
                            />
                          </Grid>
                          <Grid item xs={true}>   
                            <EASSelect
                              isLoading={saveLoading}
                              defaultValue={null}
                              onFocus={() =>
                                setCustomInputEditError(existingValues => ({
                                  ...existingValues,
                                  rules_custom_input: customInputEditError?.rules_custom_input?.length ? 
                                                          customInputEditError?.rules_custom_input.map(item =>
                                                            item.id === rules?.id ? { ...item, mentor: [] } : item
                                                          )
                                                        : undefined
                                  }))
                              }
                              options={customInputEdit?.options_custom_input ? customInputEdit?.options_custom_input : []}
                              label="E a escolha do mentor conter algum"
                              multipleSelect={true}
                              size="small"
                              getOptionLabel={(options) => options.text}
                              value={ rules?.mentor && rules.mentor.map((option) => (customInputEdit?.options_custom_input && customInputEdit.options_custom_input.find(item => item.value == option)))}
                              onChangeSelect={(event, values: Array<IList>) => {
                                const NewValue: Array<string> = [];
                                values.map((value) => NewValue.push(value.value));
                                setCustomInputEdit(existingValues => ({
                                  ...existingValues,
                                  rules_custom_input: customInputEdit?.rules_custom_input?.length ? 
                                                          customInputEdit?.rules_custom_input.map(item =>
                                                            item.id === rules?.id ? { ...item, mentor: NewValue } : item
                                                          )
                                                        : 
                                                        undefined
                                  }));
                                }
                              }
                              error={!!customInputEditError?.rules_custom_input?.[index]?.mentor?.length}
                              helperText={customInputEditError?.rules_custom_input?.[index]?.mentor}
                            />
                          </Grid>
                          <Grid item xs={2}  >
                            <EASSelect
                                isLoading={saveLoading}
                                defaultValue={null}
                                
                                onFocus={() =>
                                  setCustomInputEditError(existingValues => ({
                                    ...existingValues,
                                    rules_custom_input: customInputEditError?.rules_custom_input?.length ? 
                                                            customInputEditError?.rules_custom_input.map(item =>
                                                              item.id === rules?.id ? { ...item, weight: '' } : item
                                                            )
                                                          : undefined
                                    }))
                                }
                                options={weightOptions ? weightOptions : []}
                                label="Compatibilidade"
                                size="small"
                                getOptionLabel={(option) => option.text}
                                value={weightOptions && weightOptions.find(item => item.value == rules?.weight)}
                                onChangeSelect={(event, value: IList) => {
                                    if (value && value.value) {
                                        setCustomInputEdit(existingValues => ({
                                            ...existingValues,
                                            rules_custom_input: customInputEdit?.rules_custom_input?.length ? 
                                                                  customInputEdit?.rules_custom_input.map(item =>
                                                                    item.id === rules?.id ? { ...item, weight: value.value } : item
                                                                  )
                                                                : 
                                                                undefined                                           
                                        }));
                                    }
                                  }
                                }
                                error={!!customInputEditError?.rules_custom_input?.[index]?.weight}
                                helperText={customInputEditError?.rules_custom_input?.[index]?.weight}
                            />
                            {/* 
                            <EASInput
                                isLoading={saveLoading}
                                onFocus={() =>
                                  setCustomInputEditError(existingValues => ({
                                        ...existingValues,
                                        name_custom_input: '',
                                    }))
                                }
                                size="small"
                                label="Peso" 
                                type="number"
                                value={rules?.weight}
                                onChange={(e) =>
                                  setCustomInput(existingValues => ({
                                    ...existingValues,
                                    rules_custom_input: customInput?.rules_custom_input?.length ? 
                                                            customInput?.rules_custom_input.map(item =>
                                                              item.id === rules?.id ? { ...item, weight: e.target.value } : item
                                                            )
                                                          : 
                                                          undefined
                                    }))
                                }
                                error={!!customInputEditError?.name_custom_input}
                                helperText={customInputEditError?.name_custom_input}
                            />
                            */}
                          </Grid>
                          <Grid item zeroMinWidth>
                            <IconButton
                              aria-label="close"
                              onClick={() => deleteRules(rules.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    )):(
                      <Grid item>

                      </Grid>
                    )} 
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </DialogContent>
        <Divider/>
        <DialogActions>
            
            {/* 
            <Button variant="outlined" onClick={() => company?.cnpj_company ? isValidCNPJ2(company.cnpj_company) : handleerror}>testar CNPJ</Button>
            */}
            <EASButton variant="outlined" onClick={closeCustomInput}>Cancelar</EASButton>
            <EASButton variant="contained" onClick={handleSaveCustomInput}>Confirmar Alterações</EASButton>
        </DialogActions>
      </Dialog> 
      <AlertComponent
        type="warning"
        title={'Remover Pergunta do Match'}
        statusOpen={openAlertRemove}
        actionCancel={() => setOpenAlertRemove(false)}
        //actionConfirme={destroyCustomInput}
        actionConfirme={handleDestroyCustomInput}
       // loading={loadingDialog}
      >
        Você tem certeza que deseja remover esta pergunta do match?
      </AlertComponent>
      </>
  );
};
