import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutDefault } from '../../shared/layouts';
import { MentoryTabPresentationFinal } from './MentoryTabPresentationFinal';
import { EASButton } from '../../shared/forms';
import { Grid } from '@mui/material';
import { IMentory } from '../../shared/types';



interface IMentoryTabPresentationsProps {
  mentory?:IMentory;
}  

export const MentoryTabPresentations: React.FC<IMentoryTabPresentationsProps> = ({mentory}) => {

  const navigate = useNavigate();
  return (
    <Grid container spacing={1}>
        <Grid item xs={12} >
          <MentoryTabPresentationFinal mentory={mentory} toPrint={false} />
        </Grid>
        <Grid  item xs={12} >
          <EASButton variant="contained" onClick={() => navigate('/apresentacao-final/imprimir?exportMode=true')} >Imprimir</EASButton>
        </Grid>
    </Grid>
  );
};
