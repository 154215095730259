import { IProgram, IResponseProgram } from '../../../types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

const create = async (programs: IProgram): Promise<void> => {
  const { data } = await Api.post('/programs', { Program: programs });
  return data;
};

const read = async (program = '', page = 1, search = '' ): Promise<IResponseProgram> => {
  const  {data} = await Api.get(`/programs${program ? (`/${program}`) : ''}?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;
};

const update = async (Program: IProgram): Promise<void> => {
  const { data } = await Api.put(`/programs/${Program.id_program}`, Program);
  return data;
};

const destroy = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/programs/${id}`);
  return data;
};

export const ProgramsService = {
  create,
  read,
  update,
  destroy,
};
