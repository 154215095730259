import { HTMLAttributes, ReactNode, useState } from 'react';
import {Autocomplete, AutocompleteProps, TextFieldProps, TextField, Skeleton } from '@mui/material';


type EASSelectProps = {
    options: Array<any>
    isLoading?: boolean | undefined;
    inputValue?: string ;
    freeSolo?: boolean;
    multipleSelect?: boolean;
    disableClearable?: boolean;
    filterSelectedOptions?: boolean;
    getOptionDisabled?: ((option: unknown) => boolean) | undefined;
    onChangeTextField?: (event: any | null) => void;
    getOptionLabel?: (option: any) => string
    onChangeSelect?: (event: any, newValue: any | null) => void;
    onInputChangeSelect?:  (event: any, newValue: any | null) => void;
    renderOption?:(props: HTMLAttributes<HTMLLIElement>, option: any) => ReactNode
  } & TextFieldProps;



export const EASSelect: React.FC<EASSelectProps> = ({...props}) => {
    

    return (
      <>
        {props.isLoading && (
          <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px'}}/>
        )}
        {!props.isLoading && (
          <Autocomplete
            size={props.size}
            autoHighlight
            clearOnEscape
            includeInputInList
            freeSolo={props.freeSolo}
            value={props.value ? props.value : (props.multipleSelect ? [] : null)} 
            getOptionDisabled={props.getOptionDisabled}
            getOptionLabel={props.getOptionLabel}
            onChange={props.onChangeSelect}
            onInputChange={props.onInputChangeSelect}            
            inputValue={props.inputValue}
            options={props.options}
            renderOption={props.renderOption}
            multiple={!!(props.multipleSelect)}
            disableClearable={props.disableClearable}
            disabled={props.disabled}
            fullWidth={props.fullWidth}
            filterSelectedOptions={props.filterSelectedOptions}
            renderInput={({ InputLabelProps, ...params })=> <TextField sx={{   fontSize: '0.85rem', 
                                                        borderRadius: 2,  
                                                        '& .MuiOutlinedInput-root': {
                                                          borderRadius:2,
                                                          backgroundColor: (props.disabled ? 'eas.inputBackgroundDisabled': 'eas.inputBackground'),
                                                         
                                                        }
                                                    }}
                                                    
                                                  label={props.label}
                                                  onChange={props.onChangeTextField}                       
                                                  error={props.error}
                                                  helperText={props.helperText}
                                                  onFocus={props.onFocus}
                                                  onBlur={props.onBlur}
                                                  {...params}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    ...InputLabelProps,
                                                   }} />
                        }
          />
        )}
      </>
    );
};
