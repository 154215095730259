
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

import { IResponseMatch, IMatch } from '../../../types';

const create = async (programsInvitation: IMatch): Promise<IResponseMatch> => {
  const { data } = await Api.post('/programsinvitation', { ProgramInvitation: programsInvitation } );
  return data;
};

const read = async (program = '', match='', invitation = '', page = 1, search = '' ): Promise<IResponseMatch> => {
  const  {data} = await Api.get(`/match${program ? (`/${program}`) : ''}?public_token=${Environment.PUBLIC_TOKEN}${program && match ? `&match=${match}` : ''}${program && match && invitation? `&invitation=${invitation}` : ''}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;
};

const update  = async (match: IMatch): Promise<IResponseMatch> => {
  const { data } = await  Api.put(`/match/${match.id_program}`, match);
  return data;  
};

const destroy = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/programsinvitation/${id}`);
  return data;   
};

export const MatchService = {
  create,
  read,
  update,
  destroy,
};
