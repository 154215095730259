import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid, Stack, Avatar } from '@mui/material';

import { useNavigate, useParams} from 'react-router-dom';

import { LayoutDefault } from '../../shared/layouts';

import  MentoryTabDefault, { IRefMentory } from './MentoryTabDefault';

import { MentoringService } from '../../shared/services/api/mentoring/MentoringService';
import { UtilService } from '../../shared/services/api/util/UtilService';
import { IList, IMentory } from '../../shared/types';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { Environment } from '../../shared/environment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const Mentory = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    
    const { data:programs, isLoading:loadingPrograms  } = useQuery(['programs-list'],() => UtilService.getPrograms(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Programas.', { variant:'error'});
        },
    });

    const { data:programTypes, isLoading:isLoadingProgramTypes } = useQuery(['list-mentory-types'], () =>  UtilService.getMentoryTypes(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:companies, isLoading:isLoadingCompanies } = useQuery(['list-companies'], () =>  UtilService.getCompanies(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:tags, isLoading:loadingTags  } = useQuery(['tags-list'],() => UtilService.getTags(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Tags.', { variant:'error'});
        },
    });
    
    const { data:mentory, isLoading, isFetching } = useQuery(['operator-mentory-edit', id],() =>  MentoringService.getMentory(id),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a oprocesso de mentoria.', { variant:'error'});
        },
    });
      
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updateMentory:IMentory) =>MentoringService.updateMentory(updateMentory), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-mentory-edit');
            queryClient.invalidateQueries('operator-mentoring-list');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar processo de mentoria.', { variant:'error'});
        },
    });

    const refDisplay = useRef<IRefMentory>(null);
    
    const storeDisplay = () => {
        if(refDisplay.current !== null)
        refDisplay.current.saveMentory();
    };

    return (
        <LayoutDefault >
            <EASCard 
                titleCard="Editar Processo"
                breadcrumbsCard=""
                actionCard={<Stack direction="row" spacing={1}>
                                <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                <EASButton variant="outlined" onClick={() => navigate('/processos')}>Sair</EASButton>
                            </Stack>} >
                <Box sx={{mt: 2}}>
                    <MentoryTabDefault mentoryEdit={mentory} tags={tags} companies={companies} programs={programs} programTypes={programTypes} isLoading={(isFetching || isLoading || isLoadingCompanies || loadingTags || loadingPrograms || isLoadingProgramTypes ||isLoadingStore)} storeUpdate={(mentory) => storeUpdate(mentory)} ref={refDisplay} />
                </Box>
            </EASCard>
        </LayoutDefault> 
    );
};
