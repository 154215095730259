import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Typography,
  Box,
  Skeleton,
  Grid,
  Card,
  useTheme,
  Tooltip,
  CardContent,
  Icon,
  Button,
} from '@mui/material';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

import { Environment } from '../../shared/environment';
import { EASCard, EASProgressList, SelectMentorComponent } from '../../shared/components';
import { IMentoryInvitation, IInvitation, IMentory } from '../../shared/types';
import { EASButton } from '../../shared/forms';

import { useAuthContext, useMentoringContext } from '../../shared/contexts';

import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import { useMutation, useQueryClient } from 'react-query';

import { ProgramsInvitationService } from '../../shared/services/api/programsinvitation/ProgramsInvitationService';
import { useSnackbar } from 'notistack';
import { StepperMatchComponent } from '../../shared/components';

export const SelectMentoringList = () => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const { mentoring, mentoringInvitation, isLoadingMentory, mentoringBuy, markMentory } = useMentoringContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [openMatch, setOpenMatch] = useState<boolean>(false);
  const [openSelector, setOpenSelector] = useState<boolean>(false);
  const [invitationMatch, setInvitationMatch] = useState<IMentoryInvitation>({});
  const [statusInvitation, setStatusInvitation] = useState<string>();

  const { mutateAsync: markInvitation, isLoading } = useMutation(
    (resendProgramsInvitation: IInvitation) =>
      ProgramsInvitationService.updateProgramsInvitation(resendProgramsInvitation),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mentory');
        queryClient.invalidateQueries('program-invitation-list');
        enqueueSnackbar('Convite ' + (statusInvitation == '2' ? 'aceito' : 'rejeitado') + ' com sucesso.', {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar('Erro ao tentar ' + (statusInvitation == '2' ? 'aceitar' : 'rejeitar') + ' convite.', {
          variant: 'error',
        });
      },
    },
  );

  const handleUpdateInvite = (invitation?: IMentoryInvitation, status_program_invitation?: string) => {
    if (status_program_invitation == '0') {
      setStatusInvitation(status_program_invitation);
      const updateInvitation: IInvitation = {
        id_program_invitation: invitation?.id_program_invitation,
        status_program_invitation: status_program_invitation,
        id_user: user?.id,
      };
      markInvitation(updateInvitation);
    } else {
      if (invitation && invitation.match_invitation == '1') {
        setInvitationMatch(invitation);
        setOpenMatch(true);
      } else {
        setStatusInvitation(status_program_invitation);
        const updateInvitation: IInvitation = {
          id_program_invitation: invitation?.id_program_invitation,
          status_program_invitation: status_program_invitation,
          id_user: user?.id,
        };
        markInvitation(updateInvitation);
      }
    }
  };

  const handlerOpenSelector = (invitation?: IMentoryInvitation) => {
    if (invitation) {
      setInvitationMatch(invitation);
      setOpenSelector(true);
    }
  };

  const handleSelectMentory = (mentory: IMentory) => {
    markMentory(mentory);
    navigate('/pagina-inicial');
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <>
      <EASCard titleCard="Meus Processos" bgcolorContent={theme.palette.background.default}>
        <Grid container spacing={2}>
          {isLoadingMentory && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Skeleton variant="rectangular" sx={{ borderRadius: '8px' }} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Skeleton variant="rectangular" sx={{ borderRadius: '8px' }} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Skeleton variant="rectangular" sx={{ borderRadius: '8px' }} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Skeleton variant="rectangular" sx={{ borderRadius: '8px' }} width="100%" height={380} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isLoadingMentory &&
            mentoringInvitation?.length &&
            mentoringInvitation?.map((invitation) => {
              const textConvite = (() => {
                switch (invitation?.status_program_invitation) {
                  case '1':
                    return 'Você foi convidado para participar de um processo de Mentoria';
                  case '2':
                    return 'Aguarde a definição de sua dupla';
                  default:
                    return 'Você recusou este convite.';
                }
              })();

              const buttonConvite = (() => {
                switch (invitation?.status_program_invitation) {
                  case '0': //recusou o convite
                    return (
                      <EASButton color="primary" disabled={true} variant="outlined" fullWidth>
                        Convite Recusado
                      </EASButton>
                    );
                  case '1': //não respondeu o convite
                    if (invitation?.match_invitation === '1') {
                      if (
                        invitation?.program_invitation?.start_enroll_program &&
                        invitation?.program_invitation?.close_enroll_program
                      )
                        switch (true) {
                          case today < invitation?.program_invitation?.start_enroll_program:
                            return (
                              <EASButton
                                startIcon={<HourglassEmptyIcon sx={{ pb: '4.3px' }} />}
                                color="primary"
                                disabled
                                variant="outlined"
                                fullWidth
                              >
                                Aguardando abertura
                              </EASButton>
                            );
                          case today > invitation?.program_invitation?.close_enroll_program:
                            return (
                              <EASButton
                                startIcon={<HourglassDisabledIcon sx={{ pb: '4.3px' }} />}
                                color="primary"
                                disabled
                                variant="outlined"
                                fullWidth
                              >
                                Prazo Encerrado
                              </EASButton>
                            );
                          default:
                            return (
                              <Grid container columnSpacing={2} sx={{ mt: 0 }}>
                                <Grid item xs={6}>
                                  <EASButton
                                    color="primary"
                                    variant="outlined"
                                    startIcon={<ThumbDownIcon sx={{ pb: '4.3px' }} />}
                                    fullWidth
                                    disabled={isLoading}
                                    onClick={() => handleUpdateInvite(invitation, '0')}
                                  >
                                    Recusar
                                  </EASButton>
                                </Grid>
                                <Grid item xs={6}>
                                  <EASButton
                                    color="primary"
                                    variant="contained"
                                    startIcon={<ThumbUpIcon sx={{ pb: '4.3px' }} />}
                                    fullWidth
                                    disabled={isLoading}
                                    //onClick={handleAcceptInvitation}
                                    onClick={() => handleUpdateInvite(invitation, '2')}
                                  >
                                    Aceitar
                                  </EASButton>
                                </Grid>
                              </Grid>
                            );
                        }
                    } else {
                      return (
                        <Grid container columnSpacing={2} sx={{ mt: 0 }}>
                          <Grid item xs={6}>
                            <EASButton
                              color="primary"
                              variant="outlined"
                              startIcon={<ThumbDownIcon sx={{ pb: '4.3px' }} />}
                              fullWidth
                              disabled={isLoading}
                              onClick={() => handleUpdateInvite(invitation, '0')}
                            >
                              Recusar
                            </EASButton>
                          </Grid>
                          <Grid item xs={6}>
                            <EASButton
                              color="primary"
                              variant="contained"
                              startIcon={<ThumbUpIcon sx={{ pb: '4.3px' }} />}
                              fullWidth
                              disabled={isLoading}
                              //onClick={handleAcceptInvitation}
                              onClick={() => handleUpdateInvite(invitation, '2')}
                            >
                              Aceitar
                            </EASButton>
                          </Grid>
                        </Grid>
                      );
                    }
                    return;
                  case '2': //aceitou o convite
                    switch (true) {
                      case (  invitation?.match_invitation === '0' 
                              ||
                              invitation?.match_invitation === 'NULL' 
                              ||
                              (
                                invitation?.match_invitation === '1' 
                                &&
                                invitation?.program_invitation?.match_type_program === '1'
                              ) 
                              ||
                              (
                                invitation?.match_invitation === '1' 
                                &&
                                invitation?.program_invitation?.match_select_program === '1'
                              ) 
                              ||
                              (
                                  invitation?.match_invitation === '1' 
                                  &&
                                  invitation?.program_invitation?.match_select_program === '2' 
                                  &&
                                  invitation?.participation_role_invitation === '0'
                              )
                              ||
                              (
                                  invitation?.match_invitation === '1' 
                                  &&
                                  invitation?.responsed_suggestions_program_invitation === '1'
                              )
                          ):
                        return (
                          <EASButton color="primary" disabled={true} variant="outlined" fullWidth>
                            Aguarde formação da dupla
                          </EASButton>
                        );


                      case invitation?.program_invitation?.start_match_program &&
                        today < invitation?.program_invitation?.start_match_program:
                        return (
                          <EASButton
                            startIcon={<HourglassEmptyIcon sx={{ pb: '4.3px' }} />}
                            color="primary"
                            disabled
                            variant="outlined"
                            fullWidth
                          >
                            Aguardando abertura
                          </EASButton>
                        );
                      case invitation?.program_invitation?.close_match_program &&
                        today > invitation?.program_invitation?.close_match_program:
                        return (
                          <EASButton
                            startIcon={<HourglassDisabledIcon sx={{ pb: '4.3px' }} />}
                            color="primary"
                            disabled
                            variant="outlined"
                            fullWidth
                          >
                            Prazo Encerrado
                          </EASButton>
                        );

                      case (  invitation?.match_invitation === '1' 
                              &&
                              (
                                invitation?.program_invitation?.match_type_program === '2'
                                ||
                                invitation?.program_invitation?.match_type_program === '3'
                              )
                              &&
                              (
                                (
                                  invitation?.program_invitation?.match_select_program === '2' 
                                  &&
                                  invitation?.participation_role_invitation === '1'
                                ) 
                                ||
                                (
                                  invitation?.program_invitation?.match_select_program === '3' 
                                )
                              )
                              &&
                              invitation?.responsed_suggestions_program_invitation !== '1'
                            ):
                        return (
                          <EASButton
                            color="primary"
                            variant="outlined"
                            startIcon={<ConnectWithoutContactIcon sx={{ pb: '4.3px' }} />}
                            fullWidth
                            disabled={isLoading}
                            // onClick={() => setOpenSelector(true)}
                            onClick={() => handlerOpenSelector(invitation)}
                          >
                            Escolha suas preferências
                          </EASButton>
                        );


                      default:
                        return (
                          <EASButton color="primary" disabled={true} variant="outlined" fullWidth>
                            Aguarde formação da dupla
                          </EASButton>
                        );
                    }
                }
              })();

              return (
                <Grid item key={invitation.id_program_invitation} xs={12} sm={6} lg={4} xl={3}>
                  <Card variant="outlined" sx={{ p: '24px', borderRadius: '12px' }}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexShrink: '0',
                          }}
                        >
                          {invitation?.status_program_invitation == '0' && (
                            <Tooltip title="Convite Rejeitado">
                              <CancelIcon color="error" sx={{ width: 60, height: 60 }} />
                            </Tooltip>
                          )}
                          {invitation?.status_program_invitation == '1' && (
                            <Tooltip title="Convite Pendente">
                              <PendingIcon color="info" sx={{ width: 60, height: 60 }} />
                            </Tooltip>
                          )}
                          {invitation?.status_program_invitation == '2' && (
                            <Tooltip title="Convite Aceito">
                              <CheckCircleIcon color="success" sx={{ width: 60, height: 60 }} />
                            </Tooltip>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            component="h4"
                            sx={{
                              textAlign: 'center',
                              fontWeight: 500,
                              fontSize: '1.095rem',
                              lineHeight: '1rem',
                            }}
                          >
                            {invitation?.name_program}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            color="primary"
                            component="h6"
                            sx={{
                              textAlign: 'center',
                              fontWeight: 400,
                              fontSize: '0.75rem',
                              lineHeight: '0',
                              my: 1,
                            }}
                          >
                            Convite Para Participar de Processo
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexShrink: '0',
                          }}
                        >
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant="caption">
                                  VOCÊ PARTICIPARÁ COMO {invitation?.name_participation_role_invitation?.toUpperCase()}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Typography
                      component="h3"
                      sx={{
                        textAlign: 'center',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: '1.6rem',
                      }}
                    >
                      {textConvite}
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                      <Grid item xs={12}>
                        {buttonConvite}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
          {!isLoadingMentory &&
            mentoring &&
            mentoring?.map((mentory) => (
              <Grid item key={mentory.id_mentory} xs={12} sm={6} lg={4} xl={3}>
                <Card variant="outlined" sx={{ p: '24px', borderRadius: '12px' }}>
                  <CardContent>
                    <Grid container spacing={1} sx={{ mb: '12px' }}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexShrink: '0',
                        }}
                      >
                        <Avatar
                          sx={{
                            border: '3px solid #EA5B0C',
                            width: 60,
                            height: 60,
                          }}
                          alt={mentory?.id_user_mentor == user?.id ? mentory?.name_mentorado : mentory?.name_mentor}
                          src={
                            (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) +
                            'images/stories/avatar/medium_' +
                            (mentory?.id_user_mentor == user?.id ? mentory?.image_mentorado : mentory?.image_mentor)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="h3"
                          sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            fontSize: '0.95rem',
                            lineHeight: '1.6rem',
                          }}
                        >
                          {mentory?.id_user_mentor == user?.id ? mentory?.name_mentorado : mentory?.name_mentor}
                        </Typography>
                        <Typography
                          color="primary"
                          component="h6"
                          sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            fontSize: '0.75rem',
                            lineHeight: '1.66',
                          }}
                        >
                          {mentory?.id_user_mentor == user?.id ? 'MENTORADO' : 'MENTOR'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          sx={{
                            textAlign: 'center',
                            fontWeight: 500,
                            fontSize: '1.095rem',
                            lineHeight: '1.6rem',
                          }}
                        >
                          {mentory?.name_mentory}
                        </Typography>
                      </Grid>
                    </Grid>
                    <EASProgressList
                      dateInit={mentory?.start_mentory}
                      dateFinish={mentory.end_mentory}
                      progress={mentory?.progress_mentory}
                      textProgress={mentory?.name_progress_mentory}
                    />
                  </CardContent>
                  <Button
                    color="primary"
                    disabled={mentory?.status_mentory == '0' ? true : false}
                    variant="outlined"
                    fullWidth
                    onClick={() => handleSelectMentory(mentory)}
                  >
                    <OpenInBrowserIcon sx={{ mr: 1 }} /> Acessar Processo
                  </Button>
                </Card>
              </Grid>
            ))}
          {!isLoadingMentory && mentoringBuy > 0 && (
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Card
                onClick={() => navigate('/novo-processo')}
                variant="outlined"
                sx={{
                  p: '25px',
                  cursor: 'pointer',
                  borderRadius: '12px',
                  border: '4px dashed',
                  borderColor: theme.palette.text.secondary,
                  color: theme.palette.text.secondary,
                  background: 'transparent',
                }}
              >
                <CardContent>
                  <Grid
                    container
                    sx={{
                      px: 4,
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textTransform: 'none',
                    }}
                  >
                    <Grid item xs={12}>
                      <Avatar
                        sx={{
                          width: 80,
                          height: 80,
                          background: theme.palette.background.default,
                        }}
                      >
                        <Icon
                          sx={{
                            fontSize: 50,
                            color: theme.palette.text.secondary,
                          }}
                        >
                          control_point
                        </Icon>
                      </Avatar>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{
                          textAlign: 'center',
                          fontWeight: 600,
                          fontSize: '1.0375rem',
                        }}
                      >
                        Novo Processo
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          textAlign: 'center',
                          fontWeight: 400,
                          fontSize: '0.9125rem',
                        }}
                      >
                        Configure seu Processo de Mentoria
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          pt: 5,
                          textAlign: 'center',
                          fontWeight: 200,
                          fontSize: '0.6125rem',
                        }}
                      >
                        {mentoringBuy + (mentoringBuy > 1 ? ' licenças' : ' licença')} restantes
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {!isLoadingMentory && !mentoring && mentoringBuy <= 0 && (
            <Grid item xs={12}>
              Nenhum item encontrado
            </Grid>
          )}
        </Grid>
      </EASCard>
      <StepperMatchComponent open={openMatch} setOpen={() => setOpenMatch(false)} invitation={invitationMatch} />
      <SelectMentorComponent open={openSelector} setOpen={() => setOpenSelector(false)} invitation={invitationMatch} />
    </>
  );
};
