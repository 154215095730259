
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

import { IMentoryInvitation, IInvitation, IInvitationList, IResponseMentoryInvitation, IResponseInvitation } from '../../../types';

const create = async (programsInvitation: IInvitation): Promise<IResponseInvitation> => {
  const { data } = await Api.post('/programsinvitation', { ProgramInvitation: programsInvitation } );
  return data;
};

const update  = async (programsInvitation: IMentoryInvitation): Promise<IResponseMentoryInvitation> => {
  const { data } = await  Api.put(`/mentoryinvitation/${programsInvitation.id_program_invitation}`, programsInvitation);
  return data;  
};

const destroy = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/programsinvitation/${id}`);
  return data;   
};

const read = async (program = '', page = 1, search = '' ): Promise<IInvitationList> => {
  const  {data} = await Api.get(`/programsinvitation?public_token=${Environment.PUBLIC_TOKEN}&program=${program}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;
};

export const MentoryInvitationService = {

  create,
  read,
  update,
  destroy,
};
