
import React, { useState , useMemo, ReactElement } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Typography, Card, CardHeader, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Skeleton, Tooltip, Box, Stack  } from '@mui/material/';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ProgramInviteAdd } from './ProgramInviteAdd';

import { useSnackbar } from 'notistack';
import { EASDialogAlert, PaginationComponent } from '../../shared/components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProgramsInvitationService } from '../../shared/services/api/programsinvitation/ProgramsInvitationService';
import { IProgram, IInvitation, IList } from '../../shared/types';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { ProgramInviteImport } from './ProgramInviteImport';

interface IProgramInvite {
  program?: IProgram;
  tags?: Array<IList>;
}  

export const ProgramInvite: React.FC<IProgramInvite> = ({ program, tags }) => {



  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogImport, setOpenDialogImport] = useState(false);
  
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'resend' | null>(null);




  const [idProgramsInvitation, setIdProgramsInvitation] = useState<string | null>(null);


  
  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['program-invitation-list', [program?.id_program, search, page]],() =>  ProgramsInvitationService.getProgramsInvitation(program?.id_program, page, search ), {
    //staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de Pessoas.', { variant:'error'});
    },
  });

  const { mutateAsync:storeRemove } = useMutation((idProgramsInvitation:string) => ProgramsInvitationService.deleteProgramsInvitation(idProgramsInvitation), {
    onSuccess: () => {
      queryClient.invalidateQueries('program-invitation-list');
      enqueueSnackbar('Convite removido com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();      
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover o convite!', { variant:'error'});
      setLoadingDialog(false);
    },
  });

  const { mutateAsync:reSend } = useMutation((resendProgramsInvitation:IInvitation) => ProgramsInvitationService.updateProgramsInvitation(resendProgramsInvitation), {
    onSuccess: () => {
      enqueueSnackbar('Convite reenviado com sucesso.', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao reenviar convite.', { variant:'error'});
      setLoadingDialog(false);
    },
  });

  const  handleResendInvite = (id_program_invitation?:string, email_program_invitation?:string) => {
    if(id_program_invitation){
      setIdProgramsInvitation(id_program_invitation);
      setTitleDialog('Reenviar Convite');
      setTextDialog(<><Typography >Deseja reenviar o email para este convidado ?</Typography><Typography sx={{display:'flex'}}>Email de destino:&nbsp;<Typography sx={{fontWeight:'bold'}}>{email_program_invitation}</Typography></Typography></>);
      setExecuteDialog('resend');
      setOpenDialog(true);
    }
  }; 

  const  handleRemoveInvite = (id_evaluation?:string) => {
    if(id_evaluation){
      setIdProgramsInvitation(id_evaluation);
      setTitleDialog('Excluir Convite');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene este convite?</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 

  const handleCancelDialog = () => {
    setIdProgramsInvitation(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
  };

  const handleConfirmeDialog = () => {
    switch (executeDialog) {
      case 'remove':
        removeProgramsInvitation();
        break;
      case 'resend':
        resendProgramsInvitation();
        break;
    }
  };

  const resendProgramsInvitation = () => {
    if (idProgramsInvitation) {
      setLoadingDialog(true);
      const resend: IInvitation = {
        id_program_invitation: idProgramsInvitation,
        resend:'1',
      };  
      reSend(resend);
    }
  };

  const removeProgramsInvitation = () => {
    if(idProgramsInvitation){
      setLoadingDialog(true);
      storeRemove(idProgramsInvitation);
    }
  };


  return (
    <Card elevation={0} >
      <CardHeader
        action={
          <Stack direction="row" spacing={1}>
            <EASButton variant="contained" onClick={() =>setOpenDialogImport(true)}>Importar Lista</EASButton>
            <EASButton variant="contained" size="small" onClick={() =>setOpenDialogAdd(true)}>Cadastrar nova Inscrição</EASButton>
          </Stack>}
        title={<Typography variant="h6">Inscrições do Programa</Typography>}
        subheader="Cadastre ou importe um lista de pessoas que você deseja que participem deste programa."
      />
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">
                Nome
              </TableCell>
              <TableCell component="th" scope="row">
                E-mail
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Papel
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Conpetências
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Cadastro
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Status
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {isLoading && (
            <>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
          )}
          {!isLoading && ( (data?.data && data.data.length) ? (data.data.map((programsInvitation) => (
            <TableRow 
              hover 
              key={programsInvitation.id_program_invitation} 
              tabIndex={-1} 
              role="checkbox"
            >
              <TableCell>
                {programsInvitation?.name_program_invitation}
              </TableCell>
              <TableCell>
                {programsInvitation?.email_program_invitation}
              </TableCell>
              <TableCell align="center">
                {programsInvitation?.name_participation_role_invitation}
              </TableCell>
              <TableCell align="center">
                { programsInvitation?.name_objectives_invitation && 
                  programsInvitation.name_objectives_invitation.length > 25 ? (
                  <Tooltip title={programsInvitation.name_objectives_invitation}>
                    <Box>
                      {programsInvitation.name_objectives_invitation.slice(0, 25) + '...'}
                    </Box>
                  </Tooltip>
                ) : 
                (
                  programsInvitation?.name_objectives_invitation ? programsInvitation?.name_objectives_invitation : 'Não definido'
                )}
              </TableCell>
              <TableCell align="center" sx={{width:'70px'}}>
                { programsInvitation?.date_update && new Date(programsInvitation?.date_update).toLocaleDateString('pt-BR')}
              </TableCell>
              <TableCell align="center" sx={{width:'40px'}}>
                {programsInvitation?.status_program_invitation == '0' && (
                <Tooltip title="Convite Rejeitado" sx={{mr:0.5}}>
                  <CancelIcon  color="error" fontSize="small" />
                </Tooltip>
                )}
                {programsInvitation?.status_program_invitation == '1' && (
                <Tooltip title="Convite Pendente" sx={{mr:0.5}}>
                  <PendingIcon color="info" fontSize="small" />
                </Tooltip>
                )}
                {(programsInvitation?.status_program_invitation == '2' || programsInvitation?.status_program_invitation == '3') && (
                <Tooltip title="Convite Aceito" sx={{mr:0.5}}>
                  <CheckCircleIcon color="success" fontSize="small" />
                </Tooltip>
                )}
              </TableCell>
              <TableCell align="center" sx={{width:'60px'}}>
                  <EASButtonList 
                      title="Reenviar Convite"
                      onClick={() => handleResendInvite(programsInvitation.id_program_invitation, programsInvitation?.email_program_invitation)} 
                      typeIcon="mail"
                      disabled={!!(programsInvitation?.status_program_invitation == '2'|| programsInvitation?.status_program_invitation == '3')}
                  />  
                  <EASButtonList 
                    title="Remover Convite"
                    onClick={() => handleRemoveInvite(programsInvitation.id_program_invitation)} 
                    typeIcon="remove"
                    disabled={!!( programsInvitation?.status_program_invitation == '3')}
                  />
              </TableCell> 
            </TableRow>
            ))) : (
            <TableRow hover tabIndex={-1} role="checkbox" >
              <TableCell colSpan={7} scope="row" >
                Nenhum item encontrado 
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading  && (
        <>
          <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
          <ProgramInviteAdd open={openDialogAdd} setOpen={(e) => setOpenDialogAdd(e)} program={program} tags={tags} listInvitation={data?.data}/>
          <ProgramInviteImport open={openDialogImport} setOpen={(e) => setOpenDialogImport(e)} program={program} tags={tags} listInvitation={data?.data}/>
          <EASDialogAlert 
            type="warning"
            title={titleDialog}
            statusOpen={openDialog}
            actionCancel={handleCancelDialog}
            actionConfirme={handleConfirmeDialog}
            loading={loadingDialog}
          >
            {textDialog}
          </EASDialogAlert>
        </>
      )}  
    </Card>
  );
};
