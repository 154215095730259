
import React from 'react';
import { Button, ButtonProps, Skeleton, styled } from '@mui/material';


interface IEASButtonUploadProps extends ButtonProps {
    isLoading?: boolean | undefined;
    children: React.ReactNode;
    accept?:string;
    onChangeFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export const EASButtonUpload: React.FC<IEASButtonUploadProps> = ({...props}) => {

    return (
        <>

                {props.isLoading && (
                <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px'}}/>
                )}
                {!props.isLoading && (

                  <Button
                  component="label"                    
                  color={props.color}
                  sx={{borderRadius: 1, py:1, ...props.sx}}
                  variant={props.variant} 
                  size={props.size}
                  disabled={props.disabled}
                  fullWidth={props.fullWidth}    
                  startIcon={props.startIcon}
                  endIcon={props.endIcon}
                  role={undefined}
                  tabIndex={-1}
                  >
                  {props.children}
                  <VisuallyHiddenInput 
                    type="file" 
                    accept={props?.accept}
                    onChange={props.onChangeFile}  

                  />
                  </Button>
                  /*
                <Button 
                    color={props.color}
                    sx={{borderRadius: 1, py:1, ...props.sx}}
                    variant={props.variant} 
                    type={props.type}
                    value={props.value}
                    size={props.size}
                    disabled={props.disabled}
                    fullWidth={props.fullWidth}    
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    role={undefined}
                    tabIndex={-1}
                  >
                    <VisuallyHiddenInput
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={props.onChangeFile}  
                    />
                    {props.children}
                </Button>*/
                )}
        </>

    );
};
