import React, { Ref, useImperativeHandle, useState } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import * as yup from 'yup';

import { useAuthContext } from '../../shared/contexts';
import { IList, IProgram } from '../../shared/types';

import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

export interface IRefParams {
  saveParams: () => void;
}
/*
export interface IRefParams {
  saveProgram: () => void;
}*/

type IProgramResourcesHumanParamsPanelProps = {
  program?: IProgram;
  programTypes?: Array<IList>;
  tags?: Array<IList>;
  isLoading?: boolean;
  storeUpdate: (operator: IProgram) => void;
}

const ProgramResourcesHumanParamsPanel = React.forwardRef<IRefParams, IProgramResourcesHumanParamsPanelProps>(({ program, isLoading, programTypes, tags, storeUpdate }: IProgramResourcesHumanParamsPanelProps, ref: Ref<IRefParams>) => {

  const { user } = useAuthContext();

  const [programEdit, setProgramEdit] = useState<IProgram | undefined>(program);
  const [programError, setProgramEditError] = useState<IProgram | null>(null);

  const isValidProgram = yup.object({
    name_program: yup.string().required('Este campo é obrigatório'),
    id_module: yup.string().required('Este campo é obrigatório'),
    start_program: yup.string().required('Este campo é obrigatório'),
    close_program: yup.string().required('Este campo é obrigatório'),
  });
    
  useImperativeHandle(ref, () => ({
    saveParams,
  }));
  
  const saveParams = () => {
    if (!isLoading) {
      isValidProgram
        .validate(programEdit, { abortEarly: false })
        .then(validateProgram => {
          const updateProgram: IProgram = {
            id_program: programEdit?.id_program,
            start_program: validateProgram.start_program,
            close_program: validateProgram.close_program,
            name_program: validateProgram.name_program,
            description_program: programEdit?.description_program,
            id_module: validateProgram.id_module,
            objective_sample_program: programEdit?.objective_sample_program,
            min_grade_program: programEdit?.min_grade_program,
            max_grade_program: programEdit?.max_grade_program,
            step_grade_program: programEdit?.step_grade_program,
            tags: programEdit?.tags,
            user_update: user?.id,

          };
          storeUpdate(updateProgram);
        })
        .catch((errors: yup.ValidationError) => {
          errors.inner.forEach(error => {
            if (error.path) {
              const keyError = error.path;
              setProgramEditError(existingValues => ({
                ...existingValues,
                [keyError]: error.message
              }));
            }
          });
        });
    }
  };


  return (
    <Card sx={{ borderRadius: 2, m:2}} variant='outlined' elevation={0}>
      <CardHeader
        title='Configurações básicas do Programa'
        titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  onFocus={() =>
                    setProgramEditError(existingValues => ({
                      ...existingValues,
                      name_program: '',
                    }))
                  }
                  label="Nome do Programa"
                  type="text"
                  value={programEdit?.name_program}
                  onChange={(e) =>
                    setProgramEdit(existingValues => ({
                      ...existingValues,
                      name_program: e.target.value,
                    }))
                  }

                  error={!!programError?.name_program}
                  helperText={programError?.name_program}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <EASPicker
                  isLoading={isLoading}
                  onFocus={() =>
                    setProgramEditError(existingValues => ({
                      ...existingValues,
                      start_program: '',
                    }))
                  }
                  onOpen={() =>
                    setProgramEditError(existingValues => ({
                      ...existingValues,
                      start_program: '',
                    }))
                  }
                  label="Data de Início"
                  value={programEdit?.start_program}
                  error={!!programError?.start_program}
                  helperText={programError?.start_program}
                  onChangePiker={(e) => {
                    if (e instanceof Date) {
                      setProgramEdit(existingValues => ({
                        ...existingValues,
                        start_program: e.toISOString().split('T')[0],
                      }));
                    }
                  }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <EASPicker
                  isLoading={isLoading}
                  onFocus={() =>
                    setProgramEditError(existingValues => ({
                      ...existingValues,
                      close_program: '',
                    }))
                  }
                  onOpen={() =>
                    setProgramEditError(existingValues => ({
                      ...existingValues,
                      close_program: '',
                    }))
                  }
                  label="Data de Término"
                  value={programEdit?.close_program}
                  error={!!programError?.close_program}
                  helperText={programError?.close_program}
                  onChangePiker={(e) => {
                    if (e instanceof Date) {
                      setProgramEdit(existingValues => ({
                        ...existingValues,
                        close_program: e.toISOString().split('T')[0],
                      }));
                    }
                  }
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <EASInput
                  isLoading={isLoading}
                  type="number"
                  min="-10"
                  max="100"
                  defaultValue="0"
                  label="Nota Mínima"
                  value={programEdit?.min_grade_program ? programEdit.min_grade_program : '0'}
                  onChange={(e) =>
                    setProgramEdit(existingValues => ({
                      ...existingValues,
                      min_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                    }))
                  }
                  error={!!programError?.min_grade_program}
                  helperText={programError?.min_grade_program}

                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <EASInput
                  isLoading={isLoading}
                  type="number"
                  min="-10"
                  max="100"
                  defaultValue="10"
                  label="Nota Máxima"
                  value={programEdit?.max_grade_program ? programEdit?.max_grade_program : '10'}
                  onChange={(e) =>
                    setProgramEdit(existingValues => ({
                      ...existingValues,
                      max_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                    }))
                  }
                  error={!!programError?.max_grade_program}
                  helperText={programError?.max_grade_program}

                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <EASInput
                  isLoading={isLoading}
                  type="number"
                  min="0"
                  max="10"
                  defaultValue="1"
                  label="Incremento"
                  value={programEdit?.step_grade_program ? programEdit?.step_grade_program : '1'}
                  onChange={(e) =>
                    setProgramEdit(existingValues => ({
                      ...existingValues,
                      step_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                    }))
                  }
                  error={!!programError?.step_grade_program}
                  helperText={programError?.step_grade_program}

                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <EASRadio
                  label="Sugerir Comportamentos"
                  isLoading={isLoading}
                  options={[{ text: 'Não', value: '0' }, { text: 'Sim', value: '1' }]}
                  value={programEdit?.objective_sample_program}
                  onChange={(e) =>
                    setProgramEdit(existingValues => ({
                      ...existingValues,
                      objective_sample_program: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <EASSelect
                  isLoading={isLoading}
                  defaultValue={null}
                  options={programTypes ? programTypes : []}
                  label="Tipo"
                  getOptionLabel={(option) => option.text}
                  value={programTypes && programTypes.find(item => item.value == programEdit?.id_module)}
                  onChangeSelect={(event, value: IList) => {
                    if (value && value.value) {
                      setProgramEdit(existingValues => ({
                        ...existingValues,
                        id_module: value.value,
                      }));
                    }

                  }
                  }
                  error={!!programError?.id_module}
                  helperText={programError?.id_module}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>

              </Grid>
              <Grid item xs={12}>
                <EASSelect
                  isLoading={isLoading}
                  options={tags ? tags : []}
                  label="Tags"
                  getOptionLabel={(option) => option.text}
                  value={programEdit?.tags && tags && programEdit.tags.map((tag) => tags.find(item => item.value == tag))}
                  onChangeSelect={(event, values: Array<IList>) => {
                    const NewValue: Array<string> = [];
                    values.map((value) => NewValue.push(value.value));
                    setProgramEdit(existingValues => ({
                      ...existingValues,
                      tags: NewValue,
                    }));
                  }
                  }
                  fullWidth
                  multipleSelect
                  filterSelectedOptions
                  disableClearable
                />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  multiline={true}
                  rows={6}
                  onFocus={() =>
                    setProgramEditError(existingValues => ({
                      ...existingValues,
                      description_program: '',
                    }))
                  }
                  label="Descrição"
                  type='text'
                  value={programEdit?.description_program}
                  onChange={(e) =>
                    setProgramEdit(existingValues => ({
                      ...existingValues,
                      description_program: e.target.value,
                    }))
                  }
                  error={!!programError?.description_program}
                  helperText={programError?.description_program}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});

ProgramResourcesHumanParamsPanel.displayName = 'ProgramResourcesHumanParamsPanel';

export default ProgramResourcesHumanParamsPanel;


