
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

import { IMentory,  IResponseMentory } from '../../../types';

const create = async (mentory: IMentory): Promise<IResponseMentory> => {
  const { data } = await Api.post('/mentoring', { Mentory: mentory } );
  return data;
};

const read = async (page = 1, search = '', mentory = '', user = '', module = '', program = ''): Promise<IResponseMentory> => {
  const {data} = await Api.get(`/mentoring${mentory ? (`/${mentory}`) : ''}?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}&user=${user}&module=${module}&program=${program}`);
  return data;
};

const update  = async (mentory: IMentory): Promise<IResponseMentory> => {
  const { data } = await  Api.put(`/mentoring/${mentory.id_mentory}`, mentory);
  return data;  
};

const destroy = async (id: string): Promise<IResponseMentory> => {
  const { data } = await Api.delete(`/mentoring/${id}`);
  return data;   
};

export const MentoringService = {
  create,
  read,
  update,
  destroy,
};


