
import React, { useState, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';
import {  Card, CardHeader, Box, Stack, Divider, CardContent } from '@mui/material/';
import { MatchPairsFormedAdd } from './MatchPairsFormedAdd';

import { useSnackbar } from 'notistack';
import { EASCard, PaginationComponent } from '../../shared/components';
import { useQuery } from 'react-query';
import { MentoringService } from '../../shared/services';
import { EASButton, EASInput } from '../../shared/forms';
import { MatchPairsFormedList } from './MatchPairsFormedList';
import { useHumanResourcesContext } from '../../shared/contexts';



export const MatchPairsFormedPanel = (() => {

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogAdd, setOpenDialogAdd] = useState(false);

  const { humanResource } = useHumanResourcesContext();

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);


  const { data:mentoringList, isLoading } = useQuery(['program-mentoring-list', [humanResource?.id_program, search, page]], () => MentoringService.read( page, search, '', '','', humanResource?.id_program), {
    enabled: !!humanResource,
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de duplas.', { variant: 'error' });
    },
  });


  return (

    <>
      <EASCard
        titleCard={'Inscrições do Programa'}
        breadcrumbsCard=""
        actionCard={<Stack direction="row" sx={{display:'flex'}} spacing={1}>
                      <EASButton variant="contained" size="small" sx={{ whiteSpace:'nowrap', px:5 }} onClick={() => setOpenDialogAdd(true)}>Criar Nova Dupla</EASButton>
                      <EASInput label="Pesquisar" type='search' size="small" sx={{maxWidth:'280px'}} value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                    </Stack>} 
      >
        <Box sx={{ mt: 2 }}>
          <Card sx={{ borderRadius: 2, m:2}} variant='outlined' elevation={0}>
            <CardHeader
              title='Duplas Formadas para cursar o Programa'
              titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', }}
            />
            <Divider />
            <CardContent>
              <MatchPairsFormedList
                mentoringList={mentoringList?.status === 'success' ? mentoringList?.list : undefined}
                isLoading={isLoading}              
              />
              {!isLoading && (
                <PaginationComponent page={page} total={mentoringList?.list?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
              )}

            </CardContent>
          </Card>
        </Box>
      </EASCard>
      {!(isLoading )  && (
        <MatchPairsFormedAdd open={openDialogAdd} setOpen={(e) => setOpenDialogAdd(e)} />
       )}
    </>
  );
});
