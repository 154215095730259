import React from 'react';
import { Box, FormControlLabel, RadioGroup, Radio, FormLabel, Skeleton, RadioGroupProps, FormControl, FormHelperText} from '@mui/material';

import { IList } from '../../shared/types';

type EASRadioProps = {
    isLoading?: boolean | undefined;
    disabled?: boolean;
    row?: boolean;
    label?: string;
    options?: Array<IList>;
    error?:boolean;
    helperText?:string;
  } & RadioGroupProps;



export const EASRadio: React.FC<EASRadioProps> = ({isLoading, error, helperText, ...props}) => {
  return (
    <>
      {isLoading && (
        <Box sx={{display:'flex', mt:2}}>
          {props.options && props.options.length > 0 ? (props.options.map((_,index) => (
          <Box key={index}>
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="text" width={100} sx={{ fontSize: '1rem', mr:3 }} />
          </Box>
          ))):(
            <>
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton variant="text" width={100} sx={{ fontSize: '1rem', mr:3 }} />
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton variant="text" width={100} sx={{ fontSize: '1rem', mr:3 }} />
            </>
          )}
        </Box>
      )}
      {!isLoading && (
      <FormControl error={error} >
        <FormLabel 
          sx={{ 
            marginTop: '-8px', 
            display: '-webkit-box', 
            fontSize: '0.8em', 
            color: error ? 'error.main' : (props.disabled ? 'text.disabled' : 'text.primary')
          }}
        >
          {props.label}
        </FormLabel>
        <RadioGroup
          row={props.row !== false ?true : false}
          value={props.value === '' ? '1' : props.value}
          onChange={props.onChange}
          sx={{mt:'-1px'}} 
          {...props}                      
        >
          {props.options && props.options.length > 0 && (props.options.map((option, index) => (
            <FormControlLabel
              disabled={(!!props.disabled) }
              key={index} 
              value={option.value} 
              control={<Radio checked={props.value === option.value ? true : false}     />} 
              label={option.text} 
              sx={{ 
                color: (error ? 'error.main' : (props.disabled ? 'text.disabled' : 'text.primary')),
                '& .MuiTypography-root' : {color: (error ? 'error.main' : (props.disabled ? 'text.disabled' : 'text.primary'))}
              }}
            />
          )))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
      )}       
    </>
  );
};
