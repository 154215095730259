import { useState } from 'react';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, Grid, Typography, CircularProgress} from '@mui/material';

import { useSnackbar } from 'notistack';
import { EASButton, EASCheck, EASCheckChips, EASInput, EASSelect } from '../../shared/forms';
import { IList, IProgram, IInvitation } from '../../shared/types';
import { useMutation, useQueryClient } from 'react-query';
import { InvitationService } from '../../shared/services';
import { useAuthContext } from '../../shared/contexts';


interface IProgramResourcesHumanInvitetationsAddProps {
  program?: IProgram;
  tags?: Array<IList>;
  open: boolean;
  setOpen: (open:boolean) => void;
  listInvitation?: Array<IInvitation> | null;
}

export const ProgramResourcesHumanInvitationsAdd: React.FC<IProgramResourcesHumanInvitetationsAddProps> = ({ program, tags, open, setOpen, listInvitation}) => {
  
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [saveLoading, setSaveLoading] = useState(false);
  const [programsInvitation, setProgramsInvitation] = useState<IInvitation>({});
  const [programsInvitationError, setProgramsInvitationError] = useState<IInvitation | null>(null);

  const participation_roles =  [{value:'0', text:'Mentor'},{value:'1', text:'Mentorado'}];
  const tags_program = program?.tags && tags ? tags.filter(tagItem => program.tags?.includes(tagItem.value)) : [];

  const { mutateAsync:storeCreate, } = useMutation((newInvitation:IInvitation) => InvitationService.create(newInvitation), {
    onSuccess: (data) => {
      if (data.status === 'success') {
          queryClient.invalidateQueries('invitation-list');
          enqueueSnackbar('Inscrição salva com sucesso! Um convite foi enviado ao e-mail cadastrado.', { variant:'success'});
          setOpen(false);
          setSaveLoading(false);
          setProgramsInvitation({});
          setProgramsInvitationError(null);
      } else {
          enqueueSnackbar('Erro ao tentar salvar a inscrição.', { variant:'error'});
          setSaveLoading(false);
      }

    },
    onError: () => {
        enqueueSnackbar('Erro inesperado. Recarregue a página e tente novamente.', { variant:'error'});
        setSaveLoading(false);
    },
});

const isUniqueMail = (email_program_invitation:string):boolean => {
  
  if(email_program_invitation && listInvitation && listInvitation?.length > 0){
    return !(listInvitation && listInvitation.find(item => item.email_program_invitation == email_program_invitation));
  
  }
  return true;
}; 


  const isUniqueMailParticipation = (email_program_invitation: string): boolean => {
    
    if (email_program_invitation && listInvitation && listInvitation.length > 0) {
      return !(listInvitation.find(item => 
        item.email_program_invitation === email_program_invitation && 
        item.participation_role_invitation === programsInvitation?.participation_role_invitation
      ));
    }
    return true; 
  };

  const programsInvitationSchema = yup.object().shape({
    name_program_invitation: yup.string().required('Campo obrigatório'),
    email_program_invitation: yup.string().test('email_program_invitation', 'Inscrição já cadastrada para este e-mail', (email) => (email ? isUniqueMailParticipation(email) :  false)).email('Informe um e-mail válido').required('Campo obrigatório'),
    participation_role_invitation: yup.string().required('Campo obrigatório'),
  });


  const handleCancelDialog = () => {
    setOpen(false);
    setSaveLoading(false);
    setProgramsInvitation({});
    setProgramsInvitationError(null);
  };


  const handleConfirmeDialog = async () => {
    programsInvitationSchema
    .validate( programsInvitation, { abortEarly: false })
    .then(validateValues => {
      setSaveLoading(true);
      const newProgramsInvitation: IInvitation = {
        id_program: program?.id_program,
        status_program_invitation: '1',
        name_program_invitation: validateValues.name_program_invitation,
        email_program_invitation: validateValues.email_program_invitation,
        participation_role_invitation: programsInvitation?.participation_role_invitation,
        objectives_invitation: programsInvitation?.objectives_invitation,
        user_register: user?.id,
      }; 
      storeCreate(newProgramsInvitation);
    })
    .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setProgramsInvitationError(existingValues => ({
                                              ...existingValues,
                                              [keyError]: error.message
                                          }));                        
            }
        });
    });
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleCancelDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {'Cadastrar nova inscrição'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        <Typography variant="subtitle2">
          Informe o nome, o endereço de e-mail e a categoria de participação para que possamos enviar o convite para participação neste programa.
        </Typography>                  
        <Typography variant="caption">
          Caso deseje, você também pode informar as competências que o participante deverá trabalhar.
        </Typography>
        </DialogContentText>
        <Box sx={{mt:2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          {saveLoading && (                   
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress variant='indeterminate' />
              </Box>
          )}
          {!saveLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EASInput
                onFocus={() => 
                    setProgramsInvitationError(existingValues => ({
                    ...existingValues,
                    name_program_invitation: '',
                    }))
                }
                label="Nome"
                type='text'
                value={programsInvitation?.name_program_invitation}                                
                onChange={(e) => 
                  setProgramsInvitation(existingValues => ({
                        ...existingValues,
                        name_program_invitation: e.target.value,
                    }))
                }
                error={!!programsInvitationError?.name_program_invitation}
                helperText={programsInvitationError?.name_program_invitation}
              /> 
            </Grid>
            <Grid item xs={12}>
              <EASInput
                onFocus={() => 
                    setProgramsInvitationError(existingValues => ({
                    ...existingValues,
                    name_program_invitation: '',
                    }))
                }
                label="E-mail"
                type='text'
                value={programsInvitation?.email_program_invitation}                                
                onChange={(e) => 
                  setProgramsInvitation(existingValues => ({
                        ...existingValues,
                        email_program_invitation: e.target.value,
                    }))
                }
                error={!!programsInvitationError?.email_program_invitation}
                helperText={programsInvitationError?.email_program_invitation}
              /> 
            </Grid>
            <Grid item xs={12}>
              <EASSelect 
                options={participation_roles}
                label="Categoria de Participação"
                getOptionLabel={(option) => option.text || ''}
                onFocus={() => 
                    setProgramsInvitationError(existingValues => ({
                    ...existingValues,
                    participation_role_invitation: '',
                    }))
                }
                value={participation_roles ? participation_roles.find(item => item.value == programsInvitation?.participation_role_invitation) : ''}                                  
                onChangeSelect={(event, value:IList) => (
                  setProgramsInvitation(existingValues => ({
                    ...existingValues,
                    participation_role_invitation: (value && value.value) ? value.value : undefined,
                  }))
                )}  

                error={!!programsInvitationError?.participation_role_invitation}
                helperText={programsInvitationError?.participation_role_invitation}
              />

            </Grid>
            <Grid item xs={12}>
                <EASCheckChips 
                  label="Comportamentos do Programa"
                  options={ tags_program ?  tags_program : []}
                  
                  value={programsInvitation?.objectives_invitation}
                  onChange={(e) => {
                    const { name, checked } = e.target;
                    const newTags = checked
                      ? [...(programsInvitation?.objectives_invitation || []), name]
                      : (programsInvitation?.objectives_invitation || []).filter(tag => tag !== name);
                  
                      setProgramsInvitation(existingValues => ({
                      ...existingValues,
                      objectives_invitation: newTags
                    }));
                  }}                            
                />
            </Grid>
          </Grid>
          )}
        </Box>            
      
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <EASButton onClick={handleCancelDialog} disabled={saveLoading} variant="outlined">Cancelar</EASButton>
        <EASButton onClick={handleConfirmeDialog} disabled={saveLoading} variant="contained" autoFocus>
          Convidar
        </EASButton>
      </DialogActions>
    </Dialog>  
  );
};
