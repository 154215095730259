import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext, useDrawerContext } from '../shared/contexts';
import {
  DashboardResourcesHuman,
  MentoringResourcesHuman,
  MentoryResourcesHuman,
  
  ProgramResourcesHumanParams,
  ProgramResourcesHumanLayout,
  ProgramResourcesHumanInvitations,

  PresentationsResourcesHuman,
  Profile,
  MatchConfigure,
  MatchQuestions,
  MatchResults,
  MatchPairsFormed,

} from '../pages';

export const IsAuth5Routes = () => {

  const { user } = useAuthContext();
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {


    const MenuItens = [{
      icon: 'home',
      path: '/pagina-inicial',
      label: 'Página inicial',
      group: 'Principal',
    },
    {
      icon: 'group',
      path: '/processos',
      label: 'Processos',
    },
    {
      icon: 'settings',
      path: '/parametros-programa',
      label: 'Parametros',
      group: 'Configurações do Programa',
    },
    {
      icon: 'display_settings',
      path: '/layout-do-programa',
      label: 'Customizar Layout',
    },
    {
      icon: 'switch_account',
      path: '/inscricoes-do-programa',
      label: 'Inscrições',
    },
    /*
    {
      icon: 'school',
      path: '/dados-do-programa',
      label: 'Dados do Programa',
    },
<Route path="/dados-do-programa" element={<ProgramResourcesHuman />} />
    */
    {
      icon: 'join_inner',
      path: '/configurar-match',
      label: 'Configurações',
      group: 'Ferramenta para o Match',
    },
    {
      icon: 'quiz',
      path: '/perguntas-match',
      label: 'Questionário',
    },
    {
      icon: 'safety_divider_inner',
      path: '/resultados-match',
      label: 'Resultados',
    },
    {
      icon: 'people',
      path: '/duplas-formadas',
      label: 'Duplas Formadas',
    },
    {
      icon: 'co_present',
      path: '/relatorio-final',
      label: 'Relatório Final',
      group: 'Resultados',
    },
    ];
    setDrawerOptions(MenuItens);


  }, []);

  return (
    <Routes>

      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/pagina-inicial" element={<DashboardResourcesHuman />} />

      <Route path="/processos" element={<MentoringResourcesHuman />} />
      <Route path="/processos/detalhes/:id" element={<MentoryResourcesHuman />} />


      <Route path="/parametros-programa" element={<ProgramResourcesHumanParams />} />
      <Route path="/layout-do-programa" element={<ProgramResourcesHumanLayout />} />
      <Route path="/inscricoes-do-programa" element={<ProgramResourcesHumanInvitations />} />

      <Route path="/configurar-match" element={<MatchConfigure />} />
      <Route path="/perguntas-match" element={<MatchQuestions />} />
      <Route path="/resultados-match" element={<MatchResults />} />
      <Route path="/duplas-formadas" element={<MatchPairsFormed />} />
      <Route path="/relatorio-final" element={<PresentationsResourcesHuman />} />

     

      


      <Route path="*" element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
