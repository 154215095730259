
import { IMentory } from '../../types';

export function setMentoryLocalStorange(mentory: IMentory | null) {
    if(mentory)
        localStorage.setItem('easmen', JSON.stringify(mentory));
    else
        localStorage.removeItem('easmen');    
}

export function getMentoryLocalStorange() {
    const json = localStorage.getItem('easmen');
    if(!json){
        return null;
    }
    const mentory = JSON.parse(json);
    return mentory ?? null;
}




