import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthService } from '../../services/api/auth/AuthService';
import { setUserLocalStorange, getUserLocalStorange} from './util';
import { IAuthContextData, IAuthProviderProps, IUser, IAuth} from '../../types';

import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PeopleService } from '../../services/api/people/PeopleService';
import { IPerson } from '../../types';

export const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);
export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  
  
  const [accessAuth, setAccessAuth] = useState<boolean | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [profile, setProfile] = useState<IPerson>({});
  const [auth, setAuth] = useState<IAuth>({});
  
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      const userStorage = await getUserLocalStorange();
      if(userStorage){
        setUser(userStorage);
        setAccessAuth(true);
        refetch();

      } else {
        setUser(null);
        setUserLocalStorange(null);
        setAccessAuth(false);
      }
    })();
  
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

    
  const handleLogin = useCallback(async (email?: string, password?: string) => {
    if(email && password) {
      const auth:IAuth ={
        email:email,
        password:password,
      };
      setAuth(auth);
      executeLogin(auth);
    }
    else {
      setAuth({});
      setUser(null);
      setUserLocalStorange(null);
      setAccessAuth(false);
    }
  }, []);
  
  const { mutateAsync:executeLogin } = useMutation((auth:IAuth) => AuthService.auth(auth), {
    onSuccess: data => {
        if (data.status === 'success') {
          if (data.item){
            setUser(data.item);
            setUserLocalStorange(data.item);
            setAccessAuth(true);
            refetch();
          }

        } else {
          setUser(null);       
          setUserLocalStorange(null);
          setAccessAuth(false);
          enqueueSnackbar('E-mail ou senha informados nâo estão corretos.', { variant:'error'});
        }
    },
    onError: () => {
      enqueueSnackbar('Erro ao comunicar com Servidor. Recarregue a página e tente novamente.', { variant:'error'});
     // setLoadingDialog(false);
    },
  });

  const { refetch, isLoading:isLoadingProfile } = useQuery(['user-profile', user], () => PeopleService.getPerson(user?.id), 
  {
    enabled: !!user,
    staleTime: Infinity,
    onSuccess: data => {
      setProfile(data);
    },
    onError: () => { 
      setProfile({});       
      enqueueSnackbar('Erro ao carregar dados do perfil.', { variant:'error'});
    },
  });
/*

*/

  /*
  const { data } = useQuery('connect', () => ConnectService.connect(Environment.PUBLIC_TOKEN, Environment.CLIENT_ID), 
    {
      staleTime: Infinity,
      onSuccess: data => {
        if(data.connectToken){
          localStorage.setItem(Environment.CLIENT_ID, data.connectToken);
          setConnectToken(JSON.stringify(data.connectToken, null, 2));
        }
      },
      onError: () => {
        setConnectToken('error');
      },
    }
  );
*/






  const handleLogout = useCallback(() => { 
      setAuth({});
      setUser(null);
      setAccessAuth(false);
      setUserLocalStorange(null);
      queryClient.clear();
  }, []);


  const handleUpdate = useCallback(async (id_user:string, password?: string) => {
    
    if(id_user && password) {
      const responseUser = await AuthService.update(id_user, password);
      if (responseUser instanceof Error) {
        enqueueSnackbar('Ocorreu algum erro ao tentar atualizar a senha.', { variant:'error'});
      } else {  
        enqueueSnackbar('Senha alterada com sucesso.', { variant:'success'});
        setUser(responseUser);
        setUserLocalStorange(responseUser);
        setAccessAuth(true);
      }
    }
    else {
      enqueueSnackbar('Ocorreu algum erro ao tentar atualizar a senha.', { variant:'error'});
    }
  }, []);

  const isAuthenticated = useMemo(() => accessAuth, [accessAuth]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, profile, isLoadingProfile, login:(email,password) => handleLogin(email,password), logout: () => handleLogout(), update:(id_user, password) => handleUpdate(id_user, password) }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
