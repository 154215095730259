import { useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { IProgram } from '../../shared/types';

import { useHumanResourcesContext } from '../../shared/contexts';
import { ProgramsService, UtilService } from '../../shared/services/';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import ProgramResourcesHumanParamsPanel, { IRefParams } from './ProgramResourcesHumanParamsPanel';

export const ProgramResourcesHumanParams = () => {

    const { humanResource } = useHumanResourcesContext();

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refParams = useRef<IRefParams>(null);

    const storeDisplay = () => {
      if (refParams.current)
        refParams.current.saveParams();
    };

    const { data: program, isFetching: isLoadingProgram } = useQuery(['program-edit', humanResource?.id_program], () => ProgramsService.read(humanResource?.id_program), {
      enabled: !!humanResource,
      staleTime: Infinity,
      onSuccess: (response) => {
        if (response.status !== 'success') {
            queryClient.invalidateQueries('program-edit');
            enqueueSnackbar('As informações não foram carregadas corretamente. Recarregue a página e tente novamente.', { variant:'warning'});
        }
      },
      onError: () => {
          enqueueSnackbar('Erro inesperado. Recarregue a página e tente novamente.', { variant:'error'});
      }
    });

    const { data: programTypes, isLoading: isLoadingProgramTypes } = useQuery(['list-mentory-types'], () => UtilService.getMentoryTypes(), {
      staleTime: Infinity,
      onError: () => {
          enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant: 'error' });
      },
    });

    const { data: tags, isLoading: loadingTags } = useQuery(['tags-list'], () => UtilService.getTags(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Tags.', { variant: 'error' });
        },
    });

    const { mutateAsync: storeUpdate, isLoading: isLoadingStore } = useMutation((updateProgram: IProgram) => ProgramsService.update(updateProgram), {
        onSuccess: () => {
            queryClient.invalidateQueries('program-list');
            queryClient.invalidateQueries('program-edit');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant: 'error' });
        },
    });

    return (
      <LayoutDefault >
      <EASCard
          titleCard={'Parâmetros do Programa'}
          breadcrumbsCard=""
          actionCard={<Stack direction="row" spacing={1}>
              <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
          </Stack>} >
          <Box sx={{ mt: 2 }}>
              <ProgramResourcesHumanParamsPanel
                  program={program?.status === 'success' ? program?.item : undefined}
                  isLoading={isLoadingProgram || isLoadingStore || isLoadingProgramTypes || loadingTags}
                  programTypes={programTypes}
                  tags={tags}
                  storeUpdate={(program) => storeUpdate(program)}
                  ref={refParams}
              />
          </Box>
      </EASCard>
  </LayoutDefault>
    );
};
