import { SyntheticEvent, useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';

import Close from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import * as yup from 'yup';

import { EASButton, EASCheckSuggestions } from '../../forms';

import { IMentoryInvitation } from '../../types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuthContext } from '../../contexts';
import { MatchService, MentoryInvitationService } from '../../services';
import { useSnackbar } from 'notistack';
import { EASDialogAlert } from '../eas-dialog-alert/EASDialogAlert';

interface Mentor {
  id?: string;
  name?: string;
  image?: string;
  curriculum?: string;
}

interface IMentorSelectionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  invitation?: IMentoryInvitation;
}

export const SelectMentorComponent: React.FC<IMentorSelectionModalProps> = ({ open, setOpen, invitation }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();

  const [activeIcon, setActiveIcon] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(0);

  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const [mentoryInvitation, setMentoryInvitation] = useState<IMentoryInvitation>();
  const [mentoryInvitationError, setMentoryInvitationError] = useState<IMentoryInvitation | null>({});

  const minSuggestion = Number(invitation?.program_invitation?.match_select_amount_program) || 1;
  const steps = 3;
  const stepsLabel = ['Bem-vindo', 'Sugestões', 'Finalizar'];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
      }),
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
        },
      },
    ],
  }));

  const handlerCancelDialog = () => {
    setOpenAlert(false);
    setOpen(false);
  };

  useEffect(() => {
    if (open === true) {
      setActiveStep(0);
      setActiveIcon(0);
      setMentoryInvitation(invitation);
      setMentoryInvitationError(null);
      queryClient.invalidateQueries('match-invitation');
    }
  }, [open]);

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement<unknown> } = {
      1: <HomeIcon />,
      2: <PersonIcon />,
      3: <CheckIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const { data: match, isFetching: isLoading } = useQuery(
    ['match-invitation', invitation?.id_program, invitation?.id_program_invitation],
    () =>
      MatchService.read(
        invitation?.id_program,
        invitation?.participation_role_invitation,
        invitation?.id_program_invitation,
      ),
    {
      enabled: !!invitation?.id_program && !!invitation?.id_program_invitation && open,
      staleTime: Infinity,
      onSuccess: (data) => {
        if (data.status === 'success') {
          if (!data?.list?.data?.[0]?.mentors_match || data?.list?.data?.[0]?.mentors_match.length < minSuggestion) {

            setOpenAlert(true);
          }
        } else {
          enqueueSnackbar('Erro ao tentar carregar as sugestões de match para a inscrição.', { variant: 'error' });
        }
      },
      onError: () => {
        enqueueSnackbar('Erro ao carregar dados do match.', { variant: 'error' });
      },
    },
  );

  const { mutateAsync: markInvitation, isLoading:saveLoading } = useMutation(
    (suggestionsProgramsInvitation: IMentoryInvitation) => MentoryInvitationService.update(suggestionsProgramsInvitation),
    {
      onSuccess: (data) => {
        if (data.status === 'success') {
          queryClient.invalidateQueries('mentory');
          queryClient.invalidateQueries('program-invitation-list');
          enqueueSnackbar('Suas Preferências foram registradas com sucesso! Em breve enviaremos notícias da formação de sua dupla.', {
            variant: 'success',
          });
          setOpen(false);
        } else {
          enqueueSnackbar('Erro ao tentar salvar suas preferências.', { variant: 'error' });
        }
      },
      onError: () => {
        enqueueSnackbar('Erro inesperado. Recarregue a página e tente novamente.', { variant: 'error' });
      },
    },
  );

  const isValidInvitation = yup.object({
    ...(activeStep === 1 && {
      suggestions_program_invitation: yup
        .array()
        .min(minSuggestion, `Você deve selecionar ${minSuggestion > 1 ? `${minSuggestion} opções` : '1 opção'}`)
        .max(minSuggestion, `Você deve selecionar ${minSuggestion > 1 ? `${minSuggestion} opções` : '1 opção'}`)
        .nullable()
        .required(`Você deve selecionar ${minSuggestion > 1 ? `${minSuggestion} opções` : '1 opção'}`),
    }),
  });

  const handleBack = () => {
    const numberActiveIcon = (() => {
      switch (true) {
        case activeStep - 1 == 0:
          return 0;
        case activeStep - 1 == 1:
          return 1;
        case activeStep + 1 == steps - 1:
          return 2;
        default:
          return 0;
      }
    })();
    setActiveIcon(numberActiveIcon);
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    if (activeStep < steps - 1) {
      isValidInvitation
        .validate(mentoryInvitation, { abortEarly: false })
        .then(() => {
          const numberActiveIcon = (() => {
            switch (true) {
              case activeStep + 1 == 1:
                return 1;
              case activeStep + 1 == 2:
                return 2;
              case activeStep + 1 == steps - 1:
                return 3;
              /*
              case activeStep + 1 == steps - 1:
                return 4;
                */
              default:
                return 0;
            }
          })();
          setActiveIcon(numberActiveIcon);
          setActiveStep(activeStep + 1);
        })
        .catch((errors: yup.ValidationError) => {
          errors.inner.forEach((error) => {
            if (error.path) {
              const keyError = error.path;
              setMentoryInvitationError((existingValues) => ({
                ...existingValues,
                [keyError]: [error.message],
              }));
            }
          });
        });
    } else {
      const storeMentoryInvitationSuggestions: IMentoryInvitation = {
        id_program_invitation: mentoryInvitation?.id_program_invitation,
        suggestions_program_invitation:mentoryInvitation?.suggestions_program_invitation,
        responsed_suggestions_program_invitation:'1',
        id_user: user?.id,
      };
       markInvitation(storeMentoryInvitationSuggestions);
    }
  };

  const handleClose = () => {
    setActiveStep(0);
    setActiveIcon(0);
    setMentoryInvitation(undefined);
    setMentoryInvitationError(null);
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Escolha suas Preferências</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              sx={{ color: (theme) => theme.palette.grey[500] }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: '100%',
              pt: 1,
            }}
          >
            <Stepper alternativeLabel activeStep={activeIcon} connector={<ColorlibConnector />}>
              {stepsLabel.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minHeight: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {activeStep == 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: '5px' }}>
                <Typography align="center" variant="h6" sx={{ fontWeight: '400', paddingBottom: '15px' }}>
                  Hora de Escolher!
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Com base nas respostas que você forneceu anteriormente, já realizamos uma análise para encontrar os perfis mais alinhados com seus objetivos e expectativas.
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Agora, chegou o momento de indicar suas preferências entre as opções que foram selecionadas especialmente para você. Escolha com atenção os participantes com quem mais se identifica e que acredita ter uma conexão significativa para essa jornada.
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Suas escolhas ajudarão a tornar o Match ainda mais assertivo. Em breve, sua dupla será formada e a experiência de mentoria poderá começar com o pé direito!
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Faça suas seleções e prepare-se para crescer em boa companhia.
                </Typography>

              </Box>
            )}
            {activeStep == 1 && (
              <>
                {isLoading && <Typography>Loading</Typography>}
                {!isLoading && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <EASCheckSuggestions
                        rows={
                          !!(
                            match?.list?.data?.[0]?.mentors_match?.length &&
                            match?.list?.data?.[0]?.mentors_match?.length < 4
                          )
                        }
                        onFocus={(e) => {
                          setMentoryInvitationError((existingValues) => ({
                            ...existingValues,
                            suggestions_program_invitation: undefined,
                          }));
                        }}
                        options={match?.list?.data?.[0]?.mentors_match ? match?.list?.data?.[0]?.mentors_match : []}
                        value={mentoryInvitation?.suggestions_program_invitation}
                        onChange={(e) => {
                          const { name, checked } = e.target;
                          const newTags = checked
                            ? [...(mentoryInvitation?.suggestions_program_invitation || []), name]
                            : (mentoryInvitation?.suggestions_program_invitation || []).filter(
                                (suggestion) => suggestion !== name,
                              );
                          setMentoryInvitation((existingValues) => ({
                            ...existingValues,
                            suggestions_program_invitation: newTags,
                          }));
                        }}
                        error={!!mentoryInvitationError?.suggestions_program_invitation}
                        helperText={mentoryInvitationError?.suggestions_program_invitation?.[0]}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {activeStep === steps - 1 && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography align="center" variant="h6" sx={{ fontWeight: '400', paddingBottom: '15px' }}>
                  Chegamos ao Final!
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Agora que você já indicou suas preferências para a formação da sua dupla neste programa de mentoria, basta clicar no botão Finalizar.
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Assim que o período de seleção de preferências for encerrado, sua dupla será formada com base nas sugestões enviadas.
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Você será notificado assim que o processo for concluído. Depois disso, é só acessar a plataforma e dar início à sua jornada!
                </Typography>
                <Typography paragraph align="center" sx={{ fontWeight: '400', lineHeight: 1.6 }}>
                  Bom trabalho e sucesso nessa nova etapa!
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <EASButton
            
              variant="outlined"
              size="small"
              onClick={handleBack}
              startIcon={<KeyboardArrowLeft />}
              disabled={activeStep === 0 || isLoading || saveLoading}
            >
              Voltar
            </EASButton>
            <EASButton
              variant="contained"
              onClick={handleNext}
              disabled={isLoading || saveLoading}
              endIcon={
                isLoading || saveLoading ? (
                  <CircularProgress variant="indeterminate" color="inherit" size={20} />
                ) : (
                  <KeyboardArrowRight />
                )
              }
            >
              {activeStep === steps - 1 ? 'Finalizar' : 'Próximo'}
            </EASButton>
          </Box>
        </DialogActions>
      </Dialog>
      <EASDialogAlert
        type={'info'}
        statusOpen={openAlert}
        actionCancel={handlerCancelDialog}
        title={'Não há sugestões suficientes'}
      >
        <Typography>
          Infelizmente, ainda não há mentores suficientes para gerar seu match. Por favor, tente novamente mais tarde ou
          entre em contato com o nosso time de suporte.
        </Typography>
      </EASDialogAlert>
    </>
  );
};
