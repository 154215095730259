
import React, { useState, useMemo, ReactElement } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Typography, Card, Tooltip, Grid, AvatarGroup, Avatar, Skeleton } from '@mui/material/';

import { useSnackbar } from 'notistack';
import { EASDialogAlert, PaginationComponent } from '../../shared/components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProgramsMentoringService } from '../../shared/services/api/programsmentoring/ProgramsMentoringService';
import { IMentoringList, IProgram, IProgramMentoring } from '../../shared/types';
import { EASButton } from '../../shared/forms';
import { Environment } from '../../shared/environment';

interface IMatchPairsFormedListProps {
  mentoringList?: IMentoringList;
  isLoading?: boolean;
}

export const MatchPairsFormedList: React.FC<IMatchPairsFormedListProps> = ({ mentoringList, isLoading }) => {





  return (
      <Grid container spacing={2}>
        {isLoading && (
        <>
          <Grid item xs={12} md={6}>
            <Card sx={{ borderRadius: 2, bgcolor: 'transparent', padding: '10px' }} variant='outlined' elevation={0}>
              <Grid container spacing={2}>
                <Grid item sx={{display:'flex'}}>
                  <Skeleton variant="circular" height={80} width={80} />
                  <Skeleton variant="circular" height={80} width={80} sx={{ marginLeft: '-1rem' }} />
                </Grid>
                <Grid item zeroMinWidth sm={true}>
                  <Grid container spacing={0} >
                    <Grid item xs={12} sx={{ mt: 0.2 }}>
                      <Skeleton variant="text" width={80} sx={{ fontSize: '.8rem' }} />
                      <Skeleton variant="text" width="70%" sx={{ fontSize: '1rem' }} />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                    <Skeleton variant="text" width={80} sx={{ fontSize: '.8rem' }} />
                      <Skeleton variant="text" width="70%" sx={{ fontSize: '1rem' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ borderRadius: 2, bgcolor: 'transparent', padding: '10px' }} variant='outlined' elevation={0}>
              <Grid container spacing={2}>
                <Grid item sx={{display:'flex'}}>
                  <Skeleton variant="circular" height={80} width={80} />
                  <Skeleton variant="circular" height={80} width={80} sx={{ marginLeft: '-1rem' }} />
                </Grid>
                <Grid item zeroMinWidth sm={true}>
                  <Grid container spacing={0} >
                    <Grid item xs={12} sx={{ mt: 0.2 }}>
                      <Skeleton variant="text" width={80} sx={{ fontSize: '.8rem' }} />
                      <Skeleton variant="text" width="70%" sx={{ fontSize: '1rem' }} />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                    <Skeleton variant="text" width={80} sx={{ fontSize: '.8rem' }} />
                      <Skeleton variant="text" width="70%" sx={{ fontSize: '1rem' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </>
        )}
        {!isLoading && ((mentoringList?.data && mentoringList?.data.length) ? (mentoringList?.data.map((programsMentoring) => (
          <Grid item xs={12} md={6} key={programsMentoring?.id_mentory}>
            <Card sx={{ borderRadius: 2, bgcolor: 'transparent', padding: '10px' }} variant='outlined' elevation={0}>
              <Grid container spacing={2}>
                <Grid item>
                  <AvatarGroup max={2}>
                    <Tooltip title={'Mentorado: ' + programsMentoring?.name_mentorado}>
                      <Avatar sx={{ width: 80, height: 80 }} alt={programsMentoring?.name_mentorado} src={programsMentoring?.image_mentorado ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + programsMentoring?.image_mentorado : ''} />
                    </Tooltip>
                    <Tooltip title={'Mentor: ' + programsMentoring?.name_mentor}>
                      <Avatar sx={{ width: 80, height: 80 }} alt={programsMentoring?.name_mentor} src={programsMentoring?.image_mentor ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + programsMentoring?.image_mentor : ''} />
                    </Tooltip>
                  </AvatarGroup>
                </Grid>
                <Grid item zeroMinWidth sm={true}>
                  <Grid container spacing={0} >
                    <Grid item xs={12} sx={{ mt: 0.2 }}>
                      <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>Mentorado:</Typography>
                      <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500 }}>{programsMentoring?.name_mentorado}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 0.2 }}>
                      <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>Mentor:</Typography>
                      <Typography component="h6" variant="caption">{programsMentoring?.name_mentor}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))) : (
          <Grid item xs={12} sm={6} md={4}>
              Nenhum item encontrado
          </Grid>
        ))}
      </Grid>
  );
};
