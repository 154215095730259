
import React from 'react';

import { Avatar, Box, Chip, CircularProgress, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Environment } from '../../shared/environment';
import { IMatchUserCompare } from '../../shared/types';

interface IMatchResultsPanelMentorProps {
  mentor?:IMatchUserCompare;
  isLoading?: boolean;
}

export const MatchResultsPanelMentor: React.FC<IMatchResultsPanelMentorProps> = ({mentor, isLoading}) => {
  return (
    <Box key={mentor?.id_program_invitation} gap={1} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', my: 2, '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: 2, cursor: 'pointer', } }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} position='relative'>
        <CircularProgress
          variant="determinate"
          sx={{ 
            position: 'absolute', 
            color: (() => {
              switch (true) {
                case (Number(mentor?.percentage_match) < 20):
                  return 'red';
                case (Number(mentor?.percentage_match) < 30):
                  return 'primary';
                case (Number(mentor?.percentage_match) < 50):
                    return 'gold';
                case (Number(mentor?.percentage_match) < 65):
                  return '#8ed4d5';
                case (Number(mentor?.percentage_match) < 80):
                  return '#77ddff';
                default:
                  return '#7fd183';
              }
            })(),
            opacity:0.2
          }}
          size={55}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant='determinate'
          value={Number(mentor?.percentage_match)}
          size={55}
          thickness={4}
          sx={{ 
            position: 'absolute', 
            top: 3.2,
            left: 11,                                                 
            color: (() => {
              switch (true) {
                case (Number(mentor?.percentage_match) < 20):
                  return 'red';
                case (Number(mentor?.percentage_match) < 30):
                  return 'primary';
                case (Number(mentor?.percentage_match) < 50):
                    return 'gold';
                case (Number(mentor?.percentage_match) < 65):
                  return '#8ed4d5';
                case (Number(mentor?.percentage_match) < 80):
                  return '#77ddff';
                default:
                  return '#7fd183';
                  
              }
            })()
          }}
        />
        <Tooltip title={`${mentor?.name_user} - ${mentor?.percentage_match}% match`} >
          <Box gap={4} px={2} py={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'right' }}>
          <Avatar sx={{ width: 45, height: 45, border: 'none', top: 0, left: 0}} alt={mentor?.name_user} src={mentor?.image_user ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + mentor?.image_user : ''} />
          </Box>
        </Tooltip>
      </Box>
      <Box>
        <Typography variant="h6" fontSize={14} sx={{ color: 'text.secondary' }} >
          Mentor: {mentor?.name_user}
        </Typography>
        <Chip icon={<InfoIcon />} label={`${Number(mentor?.percentage_match)}% de match`} variant='outlined' size='small' color={Number(mentor?.percentage_match) <= 30 ? 'error' : (Number(mentor?.percentage_match) > 30 && Number(mentor?.percentage_match) <= 70) ? 'primary' : 'success'} />
      </Box>
    </Box>
  );
};

