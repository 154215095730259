import React, { useState, Ref, useImperativeHandle } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Divider, Avatar, Tabs, Tab,Skeleton, Typography } from '@mui/material';
 
 
import * as yup from 'yup';

import { Environment } from '../../shared/environment';
import { useAuthContext } from '../../shared/contexts';

import { EASButtonList } from '../../shared/forms';
import { IList, IMentory } from '../../shared/types';


import { MentoryResourcesHumanTabDetailing } from './MentoryResourcesHumanTabDetailing';

import { EASProgressList } from '../../shared/components';
import { MentoryResourcesHumanTabDashboard } from './MentoryResourcesHumanTabDashboard';
import { MentoryResourcesHumanTabMeetings } from './MentoryResourcesHumanTabMeetings';
import { MentoryResourcesHumanTabPresentations } from './MentoryResourcesHumanTabPresentations';


export interface IRefMentory {
    saveMentory: () => void;
}

interface TabMentoryProps {
    children: React.ReactNode;
    index: number;
    value: number;
}
  

const MentoryTabPanel = (props: TabMentoryProps) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  };
  
  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  interface IMentoryResourcesHumanTabDefaultProps {
    mentoryEdit?: IMentory;
    programs?:Array<IList>;
    tags?:Array<IList>;
    isLoading?:boolean;
    storeUpdate:(mentory:IMentory) => void;
  }

const MentoryResourcesHumanTabDefault = React.forwardRef<IRefMentory, IMentoryResourcesHumanTabDefaultProps>(({mentoryEdit, tags, programs, isLoading, storeUpdate}:IMentoryResourcesHumanTabDefaultProps, ref:Ref<IRefMentory>) => {

    const { user } = useAuthContext();
    const [mentoryError, setMentoryError] = useState<IMentory | null>(null);
    const [mentory, setMentory] = useState<IMentory | undefined>(mentoryEdit);
    const [openUpdateTeam, setOpenUpdateTeam] = useState(false);
    const [typeUpdateTeam, setTypeUpdateTeam] = useState<string>('');

    const [valueTab, setValueTab] = React.useState<number>(0);



    const handleChangeValueTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };



    //const [programs, setPrograms] = useState<Array<IList>>([]);
    //const [tags, setTags] = useState<Array<IList>>([]);

    const handleClickUpdate = (type?:string) => {
        if(type){
            setTypeUpdateTeam(type);
            setOpenUpdateTeam(true); 
        }
    }; 
    

    useImperativeHandle(ref, () => ({
        saveMentory,
    }));

    const isValidMentory = yup.object({
        name_mentory: yup.string().required('Este campo é obrigatório'),
        start_mentory: yup.string().required('Este campo é obrigatório'),
        end_mentory: yup.string().required('Este campo é obrigatório'),
    });

    const saveMentory = () => {
        isValidMentory
            .validate( mentory , { abortEarly: false })
            .then(validateMentory => {
                const updateMentory: IMentory = {
                    id_mentory: mentory?.id_mentory,
                    name_mentory: validateMentory.name_mentory,
                    start_mentory: validateMentory.start_mentory,
                    end_mentory: validateMentory.end_mentory,
                    description_mentory: mentory?.description_mentory,
                    objective_sample_mentory: mentory?.objective_sample_mentory,
                    id_program: mentory?.id_program,
                    tags: mentory?.tags,
                    user_update: user?.id,
                };     
              storeUpdate(updateMentory);
          })
          .catch((errors: yup.ValidationError) => {
              errors.inner.forEach(error => {
                  if(error.path){
                      const keyError = error.path;                    
                      setMentoryError(existingValues => ({
                                          ...existingValues,
                                          [keyError]: error.message
                                      }));                        
                  }
              });
          });
    };

    return (
      <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
              <CardHeader
                sx={{py:'2.5px'}}
                title={
                  <Tabs variant="scrollable" value={valueTab} onChange={handleChangeValueTab} allowScrollButtonsMobile  textColor='primary' sx={{'& .MuiTabs-indicator': { backgroundColor: 'none', height: 0}}}>
                    <Tab label="Visão Geral" {...a11yProps(0)} sx={{fontSize: 16, }}/>
                    <Tab label="Competências" {...a11yProps(1)} sx={{fontSize: 16}}/>
                    <Tab label="Sessões" {...a11yProps(1)} sx={{fontSize: 16}}/>
                  {/*  <Tab label="Tarefas" {...a11yProps(2)} sx={{fontSize: 16}}/>
                    <Tab label="Diário" {...a11yProps(3)} sx={{fontSize: 16}}/>
                    <Tab label="Avaliações" {...a11yProps(4)} sx={{fontSize: 16}}/>*/}
                    <Tab label="Apresentação Final" {...a11yProps(5)} sx={{fontSize: 16}}/>
                  </Tabs>}
              />
              <Divider/>
              <CardContent>
                <MentoryTabPanel value={valueTab} index={0}>
                  <MentoryResourcesHumanTabDashboard mentory={mentory} />
                </MentoryTabPanel>
                <MentoryTabPanel value={valueTab} index={1}>
                  <MentoryResourcesHumanTabDetailing mentory={mentory} />
                </MentoryTabPanel>
                <MentoryTabPanel value={valueTab} index={2}>
                  <MentoryResourcesHumanTabMeetings mentory={mentory} />
                </MentoryTabPanel>
                <MentoryTabPanel value={valueTab} index={3}>
                  <MentoryResourcesHumanTabPresentations mentory={mentory} />
                </MentoryTabPanel>  
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
});

MentoryResourcesHumanTabDefault.displayName = 'MentoryResourcesHumanTabDefault';

export default MentoryResourcesHumanTabDefault;
