import { IInvitation, IInvitationList, IResponseInvitation } from '../../../types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';
/*


const getProgram = async (id?: string): Promise<IProgram> => {
  const { data } = await Api.get(`/programs/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};

const getProgramAddMentory = async (code: string): Promise<IProgram> => {
    const response  = await Api.get(`/programs/?public_token=${Environment.PUBLIC_TOKEN}&code=${code}`);
    return response.data;
};*/

const createProgramsInvitation = async (programsInvitation: IInvitation): Promise<IResponseInvitation> => {
  const { data } = await Api.post('/programsinvitation', { ProgramInvitation: programsInvitation } );
  return data;
};

const updateProgramsInvitation  = async (programsInvitation: IInvitation): Promise<IResponseInvitation> => {
  const { data } = await  Api.put(`/programsinvitation/${programsInvitation.id_program_invitation}`, programsInvitation);
  return data;  
};


const deleteProgramsInvitation = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/programsinvitation/${id}`);
  return data;   
};

const getProgramsInvitation = async (program = '', page = 1, search = '' ): Promise<IInvitationList> => {
  const  {data} = await Api.get(`/programsinvitation?public_token=${Environment.PUBLIC_TOKEN}&program=${program}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;
};

export const ProgramsInvitationService = {
  getProgramsInvitation,
  deleteProgramsInvitation,
  createProgramsInvitation,
  updateProgramsInvitation
};
