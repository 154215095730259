
import { LayoutDefault } from '../../shared/layouts';
import { MatchPairsFormedPanel } from './MatchPairsFormedPanel';


export const MatchPairsFormed = () => {
    return (
        <LayoutDefault >
          <MatchPairsFormedPanel />
        </LayoutDefault>
    );
};
