import React, { useState } from 'react';
import {
  FormLabel,
  Skeleton,
  Checkbox,
  FormGroup,
  CheckboxProps,
  FormControl,
  FormHelperText,
  Box,
  Chip,
  Stack,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Popover,
  Typography,
  Grid,
} from '@mui/material';
import { IList, IMatchUserCompare } from '../../shared/types';

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { Environment } from '../environment';

type IEASCheckSuggestionsProps = {
  isLoading?: boolean | undefined;
  options?: Array<IMatchUserCompare>;
  value?: Array<string>;
  error?: boolean;
  rows?: boolean;
  helperText?: string;
} & CheckboxProps;

export const EASCheckSuggestions: React.FC<IEASCheckSuggestionsProps> = ({ ...props }) => {
  const [curriculum, setCurriculum] = useState<string | undefined>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, value?: string) => {
    setCurriculum(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {props.isLoading && (
        <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" sx={{ mt: 2 }}>
          {props.options && props.options.length > 0 ? (
            props.options.map((option, index) => (
              <Skeleton key={index} variant="rectangular" width={200} height={30} sx={{ borderRadius: 12 }} />
            ))
          ) : (
            <>
              <Skeleton variant="rectangular" width={200} height={30} sx={{ borderRadius: 12 }} />
              <Skeleton variant="rectangular" width={200} height={30} sx={{ borderRadius: 12 }} />
            </>
          )}
        </Stack>
      )}
      {!props.isLoading && (
        <FormControl error={props.error} sx={{ width: '100%' }}>
          <FormGroup sx={{ p: 0, m: 0 }} row={true}>
            <Grid container spacing={0}>
              {props.options &&
                props.options.length > 0 &&
                props.options.map((option) => (
                  <Grid
                    item
                    key={option.id_program_invitation}
                    xs={props?.options?.length && props.options.length < 4 ? 12 : 6}
                  >
                    <Checkbox
                      sx={{ m: 0, p: 1, width: '100%', height: '100%' }}
                      icon={
                        <Card variant="outlined" elevation={0} sx={{ borderRadius: 2, width: '100%', height: '100%' }}>
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{ width: 30, height: 30, border: 1, borderColor: 'transparent' }}
                                alt={option?.name_user}
                                src={
                                  option?.image_user
                                    ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) +
                                      'images/stories/avatar/medium_' +
                                      option?.image_user
                                    : ''
                                }
                              />
                            }
                            title={option?.name_user}
                            titleTypographyProps={{ fontSize: 13, fontWeight: '500', ml: '-6px' }}
                            action={
                              option?.curriculum_user && (
                                <IconButton
                                  sx={{ zIndex: 1000, color: 'primary.main' }}
                                  aria-label="info"
                                  onClick={(e) => handleClick(e, option?.curriculum_user)}
                                >
                                  <SpeakerNotesIcon />
                                </IconButton>
                              )
                            }
                          />
                        </Card>
                      }
                      checkedIcon={
                        <Card
                          variant="outlined"
                          elevation={0}
                          sx={{ borderRadius: 2, width: '100%', height: '100%', borderColor: 'primary.main' }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{ width: 30, height: 30, border: 1, borderColor: 'primary.main' }}
                                alt={option?.name_user}
                                src={
                                  option?.image_user
                                    ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) +
                                      'images/stories/avatar/medium_' +
                                      option?.image_user
                                    : ''
                                }
                              />
                            }
                            title={option.name_user}
                            titleTypographyProps={{ fontSize: 13, fontWeight: '500', ml: '-6px' }}
                            action={
                              option?.curriculum_user && (
                                <IconButton
                                  sx={{ zIndex: 1000, color: 'primary.main' }}
                                  aria-label="info"
                                  onClick={(e) => handleClick(e, option?.curriculum_user)}
                                >
                                  <SpeakerNotesIcon />
                                </IconButton>
                              )
                            }
                          />
                        </Card>
                      }
                      checked={props.value?.length && props.value.find((e) => e === option.id_program_invitation) ? true : false}
                      onChange={props.onChange}
                      name={option.id_program_invitation}
                      {...props}
                    />
                  </Grid>
                ))}
            </Grid>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 2, fontSize: 12, maxWidth: '280px' }}>{curriculum}</Typography>
            </Popover>
          </FormGroup>
          <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
      )}
    </>
  );
};
