import React, { Ref,  useState, useImperativeHandle } from 'react';
import { Grid, Box }from '@mui/material';


import * as yup from 'yup';

import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

import { IList, IMentory } from '../../shared/types';
import { useAuthContext, useModulesContext } from '../../shared/contexts';



/*
interface IMentoryInfoProps {
  mentory: IMentory | null;
  handleNextInfo:() => void;
  setMentoryUpdate:(mentory : IMentory | null) => void;
}  

export const MentoryInfo: React.FC<IMentoryInfoProps> = ({ mentory, handleNextInfo }) => {
*/

export interface IRefMentoryInfo {
  handleNextInfo: () => void;
}

type IMentoryInfoProps = {
  Mentory: IMentory;
  isLoading:boolean;
  tags?:Array<IList>;
  setMentoryUpdate:(mentory : IMentory) => void;
  setActiveStep:() => void
};

const MentoryInfo = React.forwardRef<IRefMentoryInfo, IMentoryInfoProps>(({Mentory, tags, isLoading, setMentoryUpdate, setActiveStep}:IMentoryInfoProps, ref:Ref<IRefMentoryInfo>) => {

  const { user } = useAuthContext();
  const { module } = useModulesContext();

  const [mentoryError, setMentoryError] = useState<IMentory | null>(null);
  const [mentory, setMentory] = useState<IMentory>(Mentory);

  

  useImperativeHandle(ref, () => ({
    handleNextInfo,
  }));

  const isValidMentory = yup.object({
    name_mentory: yup.string().required('Este campo é obrigatório'),
    start_mentory: yup.string().required('Este campo é obrigatório'),
    end_mentory: yup.string().required('Este campo é obrigatório'),
  });

  const handleNextInfo = () => {

    
      isValidMentory
      .validate( mentory , { abortEarly: false })
      .then(validateMentory => {
        
        const createNewMentory: IMentory = {
          id_mentory: mentory?.id_mentory,
          id_module: module?.id_module,
          name_mentory: validateMentory.name_mentory,
          start_mentory: new Date(validateMentory.start_mentory).toLocaleString('sv-SE',{timeZone:'America/Sao_Paulo',dateStyle:'short', timeStyle: 'short'}),
          end_mentory: new Date(validateMentory.end_mentory).toLocaleString('sv-SE',{timeZone:'America/Sao_Paulo',dateStyle:'short', timeStyle: 'short'}),
          description_mentory: mentory?.description_mentory,
          objective_sample_mentory: mentory?.objective_sample_mentory,
          id_program: mentory?.id_program,
          tags: mentory?.tags,
          user_update: user?.id,
        };     
        setMentoryUpdate(createNewMentory);
        setActiveStep();
      })
      .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
          if(error.path){
            const keyError = error.path;              
            setMentoryError(existingValues => ({
                                ...existingValues,
                                [keyError]: error.message
            }));                        
          }
        });
      });
  };

  return (
    <Box sx={{py:'24px', px:{xs:0, md:'12px', lg:'24px'}}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EASInput
            label="Nome do Processo"
            type="text"           
            onFocus={(e) => 
              setMentoryError(existingValues => ({
                  ...existingValues,
                  name_mentory: undefined,
              }))
            }
            value={mentory?.name_mentory || '' }
            onChange={(e) => 
                setMentory(existingValues => ({
                    ...existingValues,
                    name_mentory: e.target.value,
                }))
            }
            error={!!mentoryError?.name_mentory}
            helperText={mentoryError?.name_mentory}
          />
        </Grid>
        <Grid item xs={12} >
          <EASInput
            isLoading={isLoading}
            label="Encomenda"
            type='text'
            value={mentory?.description_mentory}                                
            onChange={(e) => 
                setMentory(existingValues => ({
                          ...existingValues,
                          description_mentory: e.target.value,
                }))
            }
            multiline
            rows="4"
          />
        </Grid>
        <Grid item xs={12} md={6} >
          <EASPicker 
            isLoading={isLoading}
            onFocus={() => 
              setMentoryError(existingValues => ({
                ...existingValues,
                start_mentory: '',
                }))
            }
            onOpen={() => 
              setMentoryError(existingValues => ({
                ...existingValues,
                start_mentory: '',
                }))
            }
            label="Data Inicial"
            value={mentory?.start_mentory?.split(' ')[0]} 
            error={!!mentoryError?.start_mentory}
            helperText={mentoryError?.start_mentory}
            onChangePiker={(e) => {  
                                if(e instanceof Date) {  
                                  setMentory(existingValues => ({
                                        ...existingValues,
                                        start_mentory: e.toISOString().split('T')[0].split(' ')[0],
                                    }));
                                }}
                            }
            />
        </Grid>
        <Grid item xs={12} md={6} >
          <EASPicker
            isLoading={isLoading}
            onFocus={() => 
              setMentoryError(existingValues => ({
                  ...existingValues,
                  end_mentory: '',
              }))
            }
            onOpen={() => 
              setMentoryError(existingValues => ({
                ...existingValues,
                end_mentory: '',
                }))
            }
            label="Data Final"
            value={mentory?.end_mentory?.split(' ')[0]}
            error={!!mentoryError?.end_mentory}
            helperText={mentoryError?.end_mentory}
            onChangePiker={(e) => {  
              if(e instanceof Date) {         
                setMentory(existingValues => ({
                      ...existingValues,
                      end_mentory: e.toISOString().split('T')[0].split(' ')[0],
                  }));
              }}
            }
          />
        </Grid>
        <Grid item xs={12} >
          <EASSelect 
            isLoading={isLoading}
            options={tags ? tags :[]}
            label="Tags para o Programa"
            getOptionLabel={(option) => option.text}                                  
            value={mentory?.tags && tags && mentory.tags.map((tag) => tags.find(item => item.value == tag))}                                  

            onInputChangeSelect={(event, values:Array<IList>) => {
              if(!event){
                const NewValue:Array<string> = [];
                alert(NewValue);
                values.map((value) => NewValue.push(value.value));
                alert(NewValue);
                setMentory(existingValues => ({
                  ...existingValues,
                  tags: NewValue,
                }));
                alert(NewValue);
              }}} 


                
            fullWidth
            multipleSelect
            filterSelectedOptions
            disableClearable
          />
        </Grid>
        
        <Grid item xs={12} >
          <EASRadio
            label="Ativar Sugestões de Competências"
            isLoading={isLoading}
            options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
            value={mentory?.objective_sample_mentory || ''}
            onChange={(e) => 
                setMentory(existingValues => ({
                    ...existingValues,
                    objective_sample_mentory: e.target.value,
                }))
            }
          />
        </Grid> 
      </Grid>
    </Box>
  );
});

MentoryInfo.displayName = 'MentoryInfo';

export default MentoryInfo;



{/*
<Dialog 
  open={open}
  keepMounted
  onClose={cancel}
  aria-describedby="alert-dialog-slide-description"
  fullWidth
  maxWidth="xs"
>
  <DialogTitle>{'Adicionar Novo Programa'}</DialogTitle>
  <DialogContent>
    {isFetching && (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress variant='indeterminate' />
    </Box>
    )}
    {!isFetching && ( 
      program.id_program ? (

        <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
        <Grid sx={{width:'100%'}} container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                  <Grid item  zeroMinWidth sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>Programa Encontrado</Typography>
                  </Grid>
                  <Grid item xs={true} >
                    <Box sx={{  display: 'flex', alignItems: 'flex-end', flexDirection:'column', textAlign: 'center', marginTop:'5px', marginRight:'10px' }}>
                      {program?.enroll_program == '0' && (
                        <Chip label="Inscrições Fechadas" color="error" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                      )}
                      {program?.enroll_program == '1' && (
                        <Chip label="Inscrições Abertas" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight:'100px' }}>
                  <Typography variant="caption" sx={{ fontSize: 14 }}>
                    Nome do Programa: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.name_program}</Typography>
                  </Typography>
                  <Typography variant="caption">
                    Início: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.start_program && new Date(program?.start_program).toLocaleString('pt-BR', {dateStyle: 'medium'}) }</Typography>
                  </Typography>
                  <Typography variant="caption">
                    Tipo: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.name_type_program}</Typography>
                  </Typography>
                  {program?.mentor_program ? (
                  <Typography variant="caption">
                    Mentor: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.mentor_program ? 'Fase 1' : 'Matching'}</Typography>
                  </Typography>
                  ) : (
                  <Typography variant="caption">
                    Fase: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>Matching</Typography>
                  </Typography>
                  )}
                  {program?.name_company && (
                  <Typography variant='caption'>
                    Empresa: <Typography variant='caption' sx={{fontWeight: 600, fontSize: 14 }}>{program?.name_company}</Typography>
                  </Typography>
                  )}

                </Box>
              </Grid>


               // 686661961571206656
              <Grid item xs={12}>
                <EASButton color="primary" variant="outlined" fullWidth> Acessar Programa</EASButton>
                <EASButton color="primary" variant="outlined" fullWidth> Acessar Programa</EASButton>
              </Grid>
             
            </Grid>
          </Grid> 
      </Grid>
    </Box> 
     ) : (
    <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
      <Grid sx={{width:'100%'}} container spacing={2}>
        <Grid item xs={12}>
          <EASInput
            //isLoading={isLoading}
            label={'Insira o Código Aqui'}
            multiline
            disabled={isFetching}
            value={codeProgram}                
            onChange={(e) => setCodeProgram(e.target.value)}
            onFocus={() => setCodeProgramError(null)}
            fullWidth
            error={!!codeProgramError}
            helperText={codeProgramError}
          />    
        </Grid> 
      </Grid>
    </Box> 
    ))}
  </DialogContent>
  <DialogActions>

    <EASButton onClick={cancel} disabled={isFetching} variant="outlined">Cancelar</EASButton>

    {program.id_program ? (
    <EASButton 
    variant='contained'
    disabled={isFetching || program?.enroll_program == '0' }


    endIcon={isFetching ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
    onClick={searchCode}>Inscrever-se</EASButton>
    ):(
      <EASButton 
      variant='contained'
      disabled={isFetching}
      endIcon={isFetching ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <SearchIcon/>}
      onClick={searchCode}>Pesquisar</EASButton>
    )}

    <EASButton onClick={dialogSave} disabled={isLoading} variant="contained" autoFocus>Adicionar</EASButton>
    
  </DialogActions>
</Dialog>
*/
}
