
import { LayoutDefault } from '../../shared/layouts';
import { ProgramResourcesHumanInvitationsPanel } from './ProgramResourcesHumanInvitationsPanel';

export const ProgramResourcesHumanInvitations = () => {


  return (
    <LayoutDefault >
      <ProgramResourcesHumanInvitationsPanel />
    </LayoutDefault>
  );
};
