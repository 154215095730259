import React from 'react';
import { FormLabel, Skeleton, Checkbox, FormGroup, CheckboxProps, FormControl, FormHelperText, Box, Chip, Stack } from '@mui/material';
import { IList } from '../../shared/types';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

type EASCheckChipsProps = {
    isLoading?: boolean | undefined;
    label?: string;
    options?: Array<IList>;
    value?:Array<string>;
    error?: boolean;
    helperText?:string;
  } & CheckboxProps;

export const EASCheckChips: React.FC<EASCheckChipsProps> = ({...props}) => {

    return (
      <>
        {props.isLoading && (
          <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" sx={{mt:2}}>
          {props.options && props.options.length > 0 ? (props.options.map((option,index) => (
            <Skeleton  key={index} variant="rectangular" width={200} height={30} sx={{borderRadius:12}}/>
          ))) : (
            <>
              <Skeleton variant="rectangular" width={200} height={30} sx={{borderRadius:12}}/>
              <Skeleton variant="rectangular" width={200} height={30} sx={{borderRadius:12}}/>
            </>
            )
          }
          </Stack>
        )}
        {!props.isLoading && (
        <FormControl error={props.error} >
          <FormLabel sx={{ display: '-webkit-box', fontSize: '0.8em', ml:2, mb:1}}>{props.label}</FormLabel>
          <FormGroup
            sx={{p:0, m:0}}
            row={true}
          >
            {props.options && props.options.length > 0 && (props.options.map((option) => (
              <Checkbox 
                key={option.value} 
                sx={{m:0, p:'0 5px 15px 0', }}
                icon={
                  <Chip
                    icon={<RadioButtonUncheckedIcon />}  
                    label={option.text}
                    clickable
                    color={'default'}
                  />
                } 
                checkedIcon={ 
                  <Chip
                    icon={<CheckCircleIcon />} 
                    label={option.text}
                    clickable
                    color={'primary'}
                  />
                } 
                checked={props.value?.length && props.value.find(e => e === option.value) ? true : false} 
                onChange={props.onChange} 
                name={option.value} 
                {...props} 
              />                    
            )))}
          </FormGroup>
          <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
        )}       
      </>
    );
};
