import React, { useImperativeHandle, useEffect, useState, Ref, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  Skeleton,
  Avatar,
  Badge,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Tabs,
  Tab,
  Hidden,
  Alert,
} from '@mui/material';

import BackupIcon from '@mui/icons-material/Backup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { useSnackbar } from 'notistack';

import { IPerson, IList } from '../../shared/types';
import { useAuthContext } from '../../shared/contexts';

import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';
import { EASFileUploadCrop } from '../../shared/components/eas-file-upload/EASFileUploadCrop';

import * as yup from 'yup';
import { isValidCPF, UtilService, uniqueCPF, uniqueMail } from '../../shared/services/api/util/UtilService';
import { Environment } from '../../shared/environment';
import { useQuery } from 'react-query';

import { centerCrop, convertToPixelCrop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';

interface IAcceptFile {
  [key: string]: string[];
}

export interface IRefProfile {
  savePerson: () => void;
}

type IProfileTabProps = {
  personEdit?: IPerson;
  isLoading?: boolean;
  estadoCivil?: Array<IList>;
  generos?: Array<IList>;
  estados?: Array<IList>;
  storeUpdate: (operator: IPerson) => void;
};

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const PersonTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 200;

const ProfileTab = React.forwardRef<IRefProfile, IProfileTabProps>(
  ({ personEdit, isLoading, estadoCivil, generos, estados, storeUpdate }: IProfileTabProps, ref: Ref<IRefProfile>) => {
    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoadingUpload, setIsLoadingUpload] = useState(false);

    const [person, setPerson] = useState<IPerson | undefined>(personEdit);
    const [personError, setPersonError] = useState<IPerson | null>(null);

    const [file, setFile] = useState<File | undefined>(undefined);
    const [urlNewImage, setUrlNewImage] = useState<string | null>(null);
    const [ImageName, setImageName] = useState<string | undefined>(undefined);
    const [base64, setBase64] = useState<string | ArrayBuffer | null>();

    const deFaultCrop = makeAspectCrop({ unit: '%', width: 25 }, 200, 200, 200);

    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [percentCrop, setPercentCrop] = useState<PercentCrop>(deFaultCrop);

    const [open, setOpen] = useState(false);

    const [valueTab, setValueTab] = useState<number>(0);

    const handleChangeValueTab = (event: React.SyntheticEvent, newValue: number) => {
      setValueTab(newValue);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setFile(undefined);
      setPercentCrop(deFaultCrop);
      setOpen(false);
    };

    const handleCancelUpdate = () => {
      setFile(undefined);
      setPercentCrop(deFaultCrop);
      setImageName(undefined);
      setBase64(null);
      setUrlNewImage(null);
    };

    const {
      data: cidades,
      isFetching: isFetchingCidades,
      isLoading: isLoadingCidades,
    } = useQuery(['list-cidades-person', [person?.id_estado]], () => UtilService.getCidades(person?.id_estado), {
      enabled: !isLoading && !!person?.id_estado,
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de cidades.', { variant: 'error' });
      },
    });

    useEffect(() => {
      setValueTab(0);
    }, []);

    useImperativeHandle(ref, () => ({
      savePerson,
    }));

    const isValidPerson = yup.object({
      name_user: yup.string().required('Este campo é obrigatório'),
      //email_user: yup.string().test('email_user', 'Email já cadastrado para outra pessoa', (email) => (email ? uniqueMail(email, person?.id_user) : false)).email('Informe um e-mail válido').required('Este campo é obrigatório'),
      data_nascimento_user: yup.string().required('Este campo é obrigatório'),
    });

    const savePerson = () => {
      if (!isLoading) {
        isValidPerson
          .validate(person, { abortEarly: false })
          .then((validatePerson) => {
            const updatePerson: IPerson = {
              id_user: person?.id_user,
              name_user: validatePerson.name_user,
              //email_user: validatePerson.email_user,
              sexo_user: person?.sexo_user,
              data_nascimento_user: validatePerson.data_nascimento_user,
              id_estado_civil: person?.id_estado_civil,
              nacionalidade_user: person?.nacionalidade_user,
              profissao_user: person?.profissao_user,
              nmae_user: person?.nmae_user,
              npai_user: person?.npai_user,
              tsangue_user: person?.tsangue_user,
              rg_user: person?.rg_user,
              tel_celular_user: person?.tel_celular_user,
              tel_residencial_user: person?.tel_residencial_user,
              id_estado: person?.id_estado,
              id_cidade: person?.id_cidade,
              cep_user: person?.cep_user,
              curriculum_user: person?.curriculum_user,
              base64_image_user: base64 ? base64.toString() : undefined,
              name_temp_image_user: ImageName,
              bairro_user: person?.bairro_user,
              logradouro_user: person?.logradouro_user,
              numero_user: person?.numero_user,
              complemento_user: person?.complemento_user,
              user_update: user?.id,
            };
            storeUpdate(updatePerson);
          })
          .catch((errors: yup.ValidationError) => {
            // setIsLoading(false);
            errors.inner.forEach((error) => {
              if (error.path) {
                const keyError = error.path;
                setPersonError((existingValues) => ({
                  ...existingValues,
                  [keyError]: error.message,
                }));
              }
            });
          });
      }
    };

    const convertToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const toBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
      return new Promise((resolve) => {
        canvas.toBlob(resolve);
      });
    };

    const storeAvatar = () => {
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(
          percentCrop,
          imgRef.current == null ? 0 : imgRef.current.width,
          imgRef.current == null ? 0 : imgRef.current.height,
        ),
      );
      if (previewCanvasRef?.current && file) {
        const dataUrl = previewCanvasRef.current.toDataURL();
        setUrlNewImage(dataUrl);

        toBlob(previewCanvasRef.current).then((blob) => {
          if (!blob) {
            return '';
          }
          const newFileImage = new File([blob], file.name, { type: blob.type });
          setImageName(file.name);

          convertToBase64(newFileImage).then((response) => {
            if (response instanceof Error) {
              setUrlNewImage(null);
              setImageName(undefined);
              setBase64(null);
              setIsLoadingUpload(false);
              enqueueSnackbar('Erro ao carregar arquivo.', { variant: 'error' });
            } else {
              setBase64(response);
              setIsLoadingUpload(false);
            }
          });
        });
        setOpen(false);
      }
    };

    const loadFile = (selectedFile: File) => {
      if (selectedFile) {
        setFile(selectedFile);

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const imageElement = new Image();
          const imageUrl = reader.result?.toString() || '';
          imageElement.src = imageUrl;

          imageElement.addEventListener('load', () => {
            const height = imageElement.height;
            const width = imageElement.width;

            const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

            const newCrop = makeAspectCrop(
              {
                unit: '%',
                width: cropWidthInPercent >= 25 ? cropWidthInPercent : 25,
              },
              ASPECT_RATIO,
              width,
              height,
            );
            const centeredCrop: PercentCrop = centerCrop(newCrop, width, height);
            setPercentCrop(centeredCrop);
          });
        });
        reader.readAsDataURL(selectedFile);
      } else {
        setPercentCrop(deFaultCrop);
        setFile(undefined);
      }
    };

    const setCanvasPreview = async (
      image: HTMLImageElement | null,
      canvas: HTMLCanvasElement | null,
      crop: PixelCrop,
      scale = 1,
      rotate = 0,
    ) => {
      if (image === null || canvas === null) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('No 2d context');
      }

      // devicePixelRatio slightly increases sharpness on retina devices
      // at the expense of slightly slower render times and needing to
      // size the image back down if you want to download/upload and be
      // true to the images natural size.
      const pixelRatio = window.devicePixelRatio;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
      canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = 'high';
      ctx.save();

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      // Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
      );

      ctx.restore();
    };

    const NewacceptFile: IAcceptFile = {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    };

    return (
      <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card sx={{ borderRadius: 2, bgcolor: 'transparent' }} variant="outlined" elevation={0}>
              <CardHeader
                title="Avatar"
                titleTypographyProps={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold' }}
              />
              <Divider />
              <CardContent>
                {isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 5,
                    }}
                  >
                    <Skeleton variant="circular" width={120} height={120} />
                  </Box>
                )}
                {!isLoading && (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 5,
                      }}
                    >
                      {urlNewImage ? (
                        <>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Badge
                              overlap="circular"
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              badgeContent={
                                <Fab size="small" color="error" aria-label="add" onClick={handleCancelUpdate}>
                                  <DeleteForeverIcon />
                                </Fab>
                              }
                            >
                              <Avatar sx={{ width: 120, height: 120 }} alt={person?.name_user} src={urlNewImage} />
                            </Badge>
                            <PublishedWithChangesIcon sx={{ m: '-15px', p: 0, margin: 'auto', textAlign: 'center' }} />
                            <Avatar
                              sx={{ width: 120, height: 120 }}
                              alt={person?.name_user}
                              src={
                                person?.image_user
                                  ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) +
                                    'images/stories/avatar/medium_' +
                                    person?.image_user
                                  : ''
                              }
                            />
                          </Box>
                          <Alert severity="info" sx={{ marginTop: '15px' }}>
                            Salve para confirmar a substituição da imagem ou cancele clicando no ícone da lixeira
                          </Alert>
                        </>
                      ) : (
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <Fab size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
                              <BackupIcon />
                            </Fab>
                          }
                        >
                          <Avatar
                            sx={{ width: 120, height: 120 }}
                            alt={person?.name_user}
                            src={
                              person?.image_user
                                ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) +
                                  'images/stories/avatar/medium_' +
                                  person?.image_user
                                : ''
                            }
                          />
                        </Badge>
                      )}
                    </Box>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card sx={{ borderRadius: 2, bgcolor: 'transparent' }} variant="outlined" elevation={0}>
              <CardHeader
                sx={{ py: '2.5px' }}
                title={
                  <Tabs
                    variant="scrollable"
                    value={valueTab}
                    onChange={handleChangeValueTab}
                    allowScrollButtonsMobile
                    textColor="primary"
                    sx={{ '& .MuiTabs-indicator': { backgroundColor: 'none', height: 0 } }}
                  >
                    <Tab label="Dados Pessoais" {...a11yProps(0)} sx={{ fontSize: 16 }} />
                    <Tab label="Endereço e Contato" {...a11yProps(1)} sx={{ fontSize: 16 }} />
                  </Tabs>
                }
              />
              <Divider />
              <CardContent>
                <PersonTabPanel value={valueTab} index={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <EASInput
                            isLoading={isLoading}
                            label="Nome"
                            type="text"
                            value={person?.name_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                name_user: e.target.value,
                              }))
                            }
                            error={!!personError?.name_user}
                            helperText={personError?.name_user}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                          <EASInput
                            disabled
                            isLoading={isLoading}
                            label="E-mail"
                            type="text"
                            value={person?.email_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                email_user: e.target.value,
                              }))
                            }
                            error={!!personError?.email_user}
                            helperText={personError?.email_user}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          <EASPicker
                            isLoading={isLoading}
                            label="Data de Nascimento"
                            value={person?.data_nascimento_user}
                            error={!!personError?.data_nascimento_user}
                            helperText={personError?.data_nascimento_user}
                            onChangePiker={(e) => {
                              if (e instanceof Date) {
                                setPerson((existingValues) => ({
                                  ...existingValues,
                                  data_nascimento_user: e.toISOString().split('T')[0],
                                }));
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <EASSelect
                            isLoading={isLoading}
                            options={estadoCivil ? estadoCivil : []}
                            label="Estado Civil"
                            getOptionLabel={(option) => option.text || ''}
                            value={estadoCivil ? estadoCivil.find((item) => item.value == person?.id_estado_civil) : ''}
                            onChangeSelect={(event, value: IList) => {
                              if (value && value.value) {
                                setPerson((existingValues) => ({
                                  ...existingValues,
                                  id_estado_civil: value.value,
                                }));
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <EASInput
                            isLoading={isLoading}
                            label="Profissão"
                            type="text"
                            value={person?.profissao_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                profissao_user: e.target.value,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <EASInput
                            isLoading={isLoading}
                            label="Nacionalidade"
                            type="text"
                            value={person?.nacionalidade_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                nacionalidade_user: e.target.value,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <EASRadio
                            label="Sexo"
                            isLoading={isLoading}
                            options={generos}
                            value={person?.sexo_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                sexo_user: e.target.value,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <EASInput
                            isLoading={isLoading}
                            label="Currículo"
                            type="text"
                            value={person?.curriculum_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                curriculum_user: e.target.value,
                              }))
                            }
                            multiline
                            minRows={3}
                            rows={3}
                            error={!!personError?.curriculum_user}
                            helperText={personError?.curriculum_user}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </PersonTabPanel>
                <PersonTabPanel value={valueTab} index={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <EASInput
                            mask={'maskTel'}
                            isLoading={isLoading}
                            label="Telefone Movel"
                            type="text"
                            value={person?.tel_celular_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                tel_celular_user: e.target.value,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <EASInput
                            mask={'maskTel'}
                            isLoading={isLoading}
                            label="Telefone Residencial"
                            type="text"
                            value={person?.tel_residencial_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                tel_residencial_user: e.target.value,
                              }))
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={3}>
                          <EASInput
                            isLoading={isLoading}
                            mask={'maskCEP'}
                            label="CEP"
                            type="text"
                            value={person?.cep_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                cep_user: e.target.value,
                              }))
                            }
                            error={!!personError?.cep_user}
                            helperText={personError?.cep_user}
                          />
                        </Grid>
                        <Hidden lgDown>
                          <Grid item sm={6} lg={9}></Grid>
                        </Hidden>

                        <Grid item xs={8} sm={10}>
                          <EASInput
                            isLoading={isLoading}
                            label="Logradouro"
                            type="text"
                            value={person?.logradouro_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                logradouro_user: e.target.value,
                              }))
                            }
                            error={!!personError?.logradouro_user}
                            helperText={personError?.logradouro_user}
                          />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          <EASInput
                            isLoading={isLoading}
                            label="Número"
                            type="text"
                            value={person?.numero_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                numero_user: e.target.value,
                              }))
                            }
                            error={!!personError?.numero_user}
                            helperText={personError?.numero_user}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EASInput
                            isLoading={isLoading}
                            label="Complemento"
                            type="text"
                            value={person?.complemento_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                complemento_user: e.target.value,
                              }))
                            }
                            error={!!personError?.complemento_user}
                            helperText={personError?.complemento_user}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EASInput
                            isLoading={isLoading}
                            label="Bairro"
                            type="text"
                            value={person?.bairro_user}
                            onChange={(e) =>
                              setPerson((existingValues) => ({
                                ...existingValues,
                                bairro_user: e.target.value,
                              }))
                            }
                            error={!!personError?.bairro_user}
                            helperText={personError?.bairro_user}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EASSelect
                            isLoading={isLoading}
                            options={estados ? estados : []}
                            label="Estado"
                            getOptionLabel={(option) => option.text || ''}
                            value={estados ? estados.find((item) => item.value == person?.id_estado) : ''}
                            onChangeSelect={(event, value: IList) => {
                              if (value && value.value) {
                                setPerson((existingValues) => ({
                                  ...existingValues,
                                  id_estado: value.value,
                                }));
                              }
                            }}
                            error={!!personError?.id_estado}
                            helperText={personError?.id_estado}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <EASSelect
                            isLoading={isLoading || (isFetchingCidades && isLoadingCidades)}
                            options={cidades ? cidades : []}
                            label="Cidade"
                            getOptionLabel={(option) => option.text || ''}
                            value={
                              cidades && person?.id_cidade
                                ? cidades.find((item) => item.value == person?.id_cidade)
                                  ? cidades.find((item) => item.value == person?.id_cidade)
                                  : null
                                : null
                            }
                            onChangeSelect={(event, value: IList) => {
                              if (value && value.value) {
                                setPerson((existingValues) => ({
                                  ...existingValues,
                                  id_cidade: value.value,
                                }));
                              }
                            }}
                            error={!!personError?.id_cidade}
                            helperText={personError?.id_cidade}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </PersonTabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Enviar Imagem</DialogTitle>
          <Divider />
          <DialogContent sx={{ maxWidth: '380px' }}>
            <DialogContentText>
              <Typography component="span" variant="subtitle2">
                Adicione ou atualize a imagem da pessoa. Recomendamos imagens quadradas.
              </Typography>
            </DialogContentText>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 3,
              }}
            >
              <EASFileUploadCrop
                titleButton="Enviar Arquivo"
                acceptFile={NewacceptFile}
                isLoadingUpload={isLoadingUpload}
                elementIsDragAccept={
                  <Box
                    sx={{
                      p: 2,
                      mb: 3,
                      borderRadius: '8px',
                      height: 140,
                      border: '1px dashed green',
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <Typography color="green" variant="h6">
                      Pode soltar a imagem agora
                    </Typography>
                  </Box>
                }
                elementIsDragReject={
                  <Box
                    sx={{
                      p: 2,
                      mb: 3,
                      borderRadius: '8px',
                      height: 140,
                      border: '1px dashed red',
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <Typography color="red" variant="h6">
                      Este arquivo não é permitido
                    </Typography>
                  </Box>
                }
                elementIsNotDragActive={
                  <Box
                    sx={{
                      p: 2,
                      mb: 3,
                      borderRadius: '8px',
                      height: 140,
                      border: '1px dashed grey',
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <Typography color="grey" variant="h6">
                      Clique ou arraste a imagem até aqui
                    </Typography>
                  </Box>
                }
                setFile={(e) => loadFile(e)}
                file={file}
                previewCanvasRef={previewCanvasRef}
                imgRef={imgRef}
                setPercentCrop={(e) => setPercentCrop(e)}
                percentCrop={percentCrop}
              />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} disabled={isLoadingUpload}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={storeAvatar} disabled={isLoadingUpload}>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  },
);

ProfileTab.displayName = 'ProfileTab';

export default ProfileTab;
