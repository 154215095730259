import React from 'react';
import { FormControlLabel, FormLabel, Skeleton, Checkbox, FormGroup, CheckboxProps, FormControl, FormHelperText, Box } from '@mui/material';
import { IList } from '../../shared/types';

type EASCheckProps = {
    isLoading?: boolean | undefined;
    label?: string;
    options?: Array<IList>;
    value?:Array<string>;
    error?: boolean;
    helperText?:string;
  } & CheckboxProps;

export const EASCheck: React.FC<EASCheckProps> = ({...props}) => {

    return (
        <>
        {props.isLoading && (
            <Box sx={{display: 'flex', flexDirection: 'column', ml:3  }}>
            {props.options && props.options.length > 0 ? (props.options.map((option,index) => (
                <Box key={index} sx={{display: 'flex', mt:2, mr:1  }}>
                    <Skeleton variant="rectangular" width={20} height={20} />
                    <Skeleton variant="text" width={100} sx={{ fontSize: '1rem', ml:1 }} />
                </Box>
            )))
            :(
                <>
                    <Box sx={{display: 'flex', mt:2, mr:1  }}>
                        <Skeleton variant="rectangular" width={20} height={20} />
                        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem', ml:1 }} />
                    </Box>
                    <Box sx={{display: 'flex', mt:2, mr:1  }}>
                        <Skeleton variant="rectangular" width={20} height={20} />
                        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem', ml:1 }} />
                    </Box>
                </>
                )
            }
            </Box>
        )}
        {!props.isLoading && (
        <FormControl error={props.error} >
            <FormLabel sx={{ display: '-webkit-box', fontSize: '0.8em'}}>{props.label}</FormLabel>
            <FormGroup
                sx={{mt:'-1px', pl:2}}
            >
                {props.options && props.options.length > 0 && (props.options.map((option) => (
                    <FormControlLabel 
                        key={option.value} 
                        value={option.value} 
                        label={option.text} 
                        control={<Checkbox checked={props.value?.length && props.value.find(e => e === option.value) ? true : false} onChange={props.onChange} name={option.value} {...props} />} 
                    />
                )))}
            </FormGroup>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
        )}       
        </>
    );
};
