import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Alert,
  IconButton,
  Avatar,
  Badge,
  Fab,
  Divider,
  DialogContentText,
  DialogActions,
  LinearProgress,
  StepIconProps,
  StepConnector,
  styled,
  stepConnectorClasses,
  CircularProgress,
  Chip,
  Stack,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PsychologyIcon from '@mui/icons-material/Psychology';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import CheckIcon from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BackupIcon from '@mui/icons-material/Backup';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { EASButton, EASCheckChips, EASCustom, EASInput, EASSelect } from '../../forms';
import { centerCrop, convertToPixelCrop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';

import { useSnackbar } from 'notistack';
import { Environment } from '../../environment';
import { useAuthContext } from '../../contexts';
import { MentoryInvitationService } from '../../services';
import { ICustomInput, IList, IResponseCustomInput, IMentoryInvitation } from '../../types';

import { EASFileUploadCrop } from '../eas-file-upload/EASFileUploadCrop';

import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';

interface IStepperMatchComponentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  invitation?: IMentoryInvitation;
}

export const StepperMatchComponent: React.FC<IStepperMatchComponentProps> = ({ open, setOpen, invitation }) => {
  const { profile } = useAuthContext();
  const [mentoryInvitation, setMentoryInvitation] = useState<IMentoryInvitation>();
  const [mentoryInvitationError, setMentoryInvitationError] = useState<IMentoryInvitation | null>({});

  const queryClient = useQueryClient();

  const [urlNewImage, setUrlNewImage] = useState<string | null>(null);
  const deFaultCrop = makeAspectCrop({ unit: '%', width: 25 }, 200, 200, 200);
  const ASPECT_RATIO = 1;
  const MIN_DIMENSION = 200;
  const [opening, setOpening] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [percentCrop, setPercentCrop] = useState<PercentCrop>(deFaultCrop);
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState<number>(0);

  const [steps, setSteps] = useState<number>(0);
  const [activeIcon, setActiveIcon] = useState<number>(0);
  const [questionViews, setQuestionViews] = useState<Array<ICustomInput>>([]);
  const [isMentor, setIsMentor] = useState<number>(0);

  interface IAcceptFile {
    [key: string]: string[];
  }

  const NewacceptFile: IAcceptFile = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
  };

  const stepsLabel = ['Bem-vindo', 'Seu Perfil', 'Competências', 'Match', 'Finalizar'];

  const totalMentoyMentor = [
    { text: '1 Mentorado por mentor', value: '1' },
    { text: 'Até 2 mentorados por mentor', value: '2' },
    { text: 'Até 3 Mentorados por mentor', value: '3' },
    { text: 'Até 4 Mentorados por mentor', value: '4' },
  ];

  useEffect(() => {
    if (open === true) {
      const numberMentor =
        invitation?.participation_role_invitation === '0' &&
        invitation?.program_invitation?.mentory_mentor_program == '5'
          ? 1
          : 0;

      const modifiedCustomInputError: Array<IResponseCustomInput> =
        invitation?.program_invitation?.match_custom_input_program?.map((item) => ({
          id_custom_input: item.id_custom_input,
          responses_custom_input: [],
        })) || [];

      setActiveStep(0);
      setActiveIcon(0);
      setMentoryInvitation(invitation);

      setIsMentor(numberMentor);

      if (invitation?.program_invitation?.match_custom_input_program?.length)
        setQuestionViews(invitation?.program_invitation?.match_custom_input_program);

      setSteps((Number(invitation?.program_invitation?.match_custom_input_program?.length) | 0) + numberMentor + 4);

      setMentoryInvitation((existingValues) => ({
        ...existingValues,
        id_user: profile?.id_user,
        name_user: profile?.name_user,
        curriculum_user: profile?.curriculum_user,
        match_responses_invitation: modifiedCustomInputError,
        ...(invitation?.objectives_invitation && { objectives_invitation: invitation.objectives_invitation }),
      }));

      setMentoryInvitationError((existingValues) => ({
        ...existingValues,
        match_responses_invitation: modifiedCustomInputError,
      }));
    }
  }, [open]);

  const { mutateAsync: markInvitation, isLoading } = useMutation(
    (resendProgramsInvitation: IMentoryInvitation) => MentoryInvitationService.update(resendProgramsInvitation),
    {
      onSuccess: (data) => {
        if (data.status === 'success') {
          queryClient.invalidateQueries('mentory');
          queryClient.invalidateQueries('user-profile');
          queryClient.invalidateQueries('program-invitation-list');
          enqueueSnackbar('Inscrição salva com sucesso! Em breve enviaremos noticias sobre o Match.', {
            variant: 'success',
          });
          setOpen(false);
        } else {
          enqueueSnackbar('Erro ao tentar salvar sua inscrição.', { variant: 'error' });
        }
      },
      onError: () => {
        enqueueSnackbar('Erro inesperado. Recarregue a página e tente novamente.', { variant: 'error' });
      },
    },
  );

  const isValidInvitation = yup.object({
    ...(activeStep === 1 && { name_user: yup.string().nullable().required('Este campo é obrigatório') }),
    ...(activeStep === 2 && {
      objectives_invitation: yup
        .array()
        .min(1, 'Escolha no mínimo uma competência')
        .nullable()
        .required('Escolha no mínimo uma competência'),
    }),
    ...(activeStep === 3 &&
      isMentor === 1 && {
        amount_mentorado_mentor_invitation: yup.string().nullable().required('Este campo é obrigatório'),
      }),
    ...(activeStep > 2 + isMentor && {
      match_responses_invitation: yup.array().of(
        yup.object({
          id_custom_input: yup.string().required('Este campo é obrigatório'),
          responses_custom_input: yup.array().when([], {
            is: () => {
              const indexToValidate = activeStep - 3 - isMentor;
              const matchResponses = mentoryInvitation?.match_responses_invitation;
              if (matchResponses && matchResponses[indexToValidate]) {
                const responses = matchResponses[indexToValidate].responses_custom_input;
                return (
                  responses === undefined || // undefined
                  (Array.isArray(responses) && responses.length === 0) // Array vazio
                );
              }
              return false;
            },
            then: yup
              .array()
              .of(yup.string().required('Este campo é obrigatório'))
              .min(1, 'Este campo é obrigatório')
              .nullable()
              .required('Este campo é obrigatório'),
            otherwise: yup.array().nullable(),
          }),
        }),
      ),
    }),
  });

  const handleNext = () => {
    if (activeStep < steps - 1) {
      isValidInvitation
        .validate(mentoryInvitation, { abortEarly: false })
        .then(() => {
          const numberActiveIcon = (() => {
            switch (true) {
              case activeStep + 1 == 1:
                return 1;
              case activeStep + 1 == 2:
                return 2;
              case activeStep + 1 > 2 && activeStep + 1 < steps - 1:
                return 3;
              case activeStep + 1 == steps - 1:
                return 4;
              default:
                return 0;
            }
          })();
          setActiveIcon(numberActiveIcon);
          setActiveStep(activeStep + 1);
        })
        .catch((errors: yup.ValidationError) => {
          const optionsCustomInputErrors = mentoryInvitationError?.match_responses_invitation || [];
          let updatedOptionsCustomInputErrors = [...optionsCustomInputErrors];
          errors.inner.forEach((error) => {
            if (error.path) {
              const keyError = error.path;
              const regex = /\[([0-9]+)\]/;
              if (keyError.includes('match_responses_invitation[')) {
                const match = keyError.match(regex);
                if (match) {
                  const indexError = Number(match[1]);
                  if (indexError == activeStep - 3 - isMentor) {
                    const valueSearch = mentoryInvitation?.match_responses_invitation?.[indexError]?.id_custom_input;
                    updatedOptionsCustomInputErrors = updatedOptionsCustomInputErrors.map((item) =>
                      item.id_custom_input === valueSearch
                        ? { ...item, responses_custom_input: [error.message] }
                        : item,
                    );
                  }
                }
              } else {
                setMentoryInvitationError((existingValues) => ({
                  ...existingValues,
                  [keyError]: keyError == 'objectives_invitation' ? [error.message] : error.message,
                }));
              }
            }
            setMentoryInvitationError((existingValues) => ({
              ...existingValues,
              match_responses_invitation: updatedOptionsCustomInputErrors,
            }));
          });
        });
    } else {
      const storeMentoryInvitation: IMentoryInvitation = {
        id_program_invitation: mentoryInvitation?.id_program_invitation,
        status_program_invitation: '2',
        match_responsed_program_invitation: '1',
        objectives_invitation: mentoryInvitation?.objectives_invitation,
        amount_mentorado_mentor_invitation: mentoryInvitation?.amount_mentorado_mentor_invitation,
        match_responses_invitation: mentoryInvitation?.match_responses_invitation,
        id_user: mentoryInvitation?.id_user,
        name_user: mentoryInvitation?.name_user,
        curriculum_user: mentoryInvitation?.curriculum_user,
        base64_image_user: mentoryInvitation?.base64_image_user
          ? mentoryInvitation?.base64_image_user.toString()
          : undefined,
        name_temp_image_user: mentoryInvitation?.name_temp_image_user,
      };
      markInvitation(storeMentoryInvitation);
    }
  };

  const handleBack = () => {
    const numberActiveIcon = (() => {
      switch (true) {
        case activeStep - 1 == 0:
          return 0;
        case activeStep - 1 == 1:
          return 1;
        case activeStep - 1 == 2:
          return 2;
        case activeStep - 1 > 2 && activeStep - 1 < steps - 1:
          return 3;
        default:
          return 0;
      }
    })();
    setActiveIcon(numberActiveIcon);
    setActiveStep(activeStep - 1);
  };

  const loadFile = (selectedFile: File) => {
    if (selectedFile) {
      setFile(selectedFile);

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const imageElement = new Image();
        const imageUrl = reader.result?.toString() || '';
        imageElement.src = imageUrl;

        imageElement.addEventListener('load', () => {
          const height = imageElement.height;
          const width = imageElement.width;

          const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

          const newCrop = makeAspectCrop(
            {
              unit: '%',
              width: cropWidthInPercent >= 25 ? cropWidthInPercent : 25,
            },
            ASPECT_RATIO,
            width,
            height,
          );
          const centeredCrop: PercentCrop = centerCrop(newCrop, width, height);
          setPercentCrop(centeredCrop);
        });
      });
      reader.readAsDataURL(selectedFile);
    } else {
      setPercentCrop(deFaultCrop);
      setFile(undefined);
    }
  };

  const setCanvasPreview = async (
    image: HTMLImageElement | null,
    canvas: HTMLCanvasElement | null,
    crop: PixelCrop,
  ) => {
    if (image === null || canvas === null) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

    ctx.restore();
  };

  const toBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise((resolve) => {
      canvas.toBlob(resolve);
    });
  };

  const convertToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const storeAvatar = () => {
    setCanvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(
        percentCrop,
        imgRef.current == null ? 0 : imgRef.current.width,
        imgRef.current == null ? 0 : imgRef.current.height,
      ),
    );
    if (previewCanvasRef?.current && file) {
      const dataUrl = previewCanvasRef.current.toDataURL();
      setUrlNewImage(dataUrl);

      toBlob(previewCanvasRef.current).then((blob) => {
        if (!blob) {
          return '';
        }
        const newFileImage = new File([blob], file.name, { type: blob.type });
        //setImageUser(file.name);

        setMentoryInvitation((existingValues) => ({
          ...existingValues,
          name_temp_image_user: file.name,
        }));

        convertToBase64(newFileImage).then((response) => {
          if (response instanceof Error) {
            setUrlNewImage(null);
            //setImageUser(undefined);
            //setBase64(null);
            setIsLoadingUpload(false);
            enqueueSnackbar('Erro ao carregar arquivo.', {
              variant: 'error',
            });
          } else {
            setMentoryInvitation((existingValues) => ({
              ...existingValues,
              base64_image_user: response?.toString(),
            }));

            // base64_image_user: mentoryInvitation?.base64_image_user ? mentoryInvitation?.base64_image_user.toString() : undefined,
            //name_temp_image_user: mentoryInvitation?.name_temp_image_user

            setIsLoadingUpload(false);
          }
        });
      });
      setOpening(false);
    }
  };

  const handleClickOpen = () => {
    setOpening(true);
  };

  const handleClose = () => {
    setFile(undefined);
    setPercentCrop(deFaultCrop);
    setOpening(false);
  };

  const handleCancelUpdate = () => {
    setFile(undefined);
    setPercentCrop(deFaultCrop);
    //setImageUser(undefined);
    //setBase64(null);
    setUrlNewImage(null);
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
      }),
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
        },
      },
    ],
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement<unknown> } = {
      1: <HomeIcon />,
      2: <PersonIcon />,
      3: <PsychologyIcon />,
      4: <JoinInnerIcon />,
      5: <CheckIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Conclua sua inscrição</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpen(false)}
              sx={{ color: (theme) => theme.palette.grey[500] }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: '100%',
              pt: 1,
            }}
          >
            <Stepper alternativeLabel activeStep={activeIcon} connector={<ColorlibConnector />}>
              {stepsLabel.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minHeight: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {activeStep == 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: '5px' }}>
                <Typography align="center" variant="h6" sx={{ fontWeight: '400', paddingBottom: '15px' }}>
                  Bem-vindo!
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Este programa de mentoria utiliza a tecnologia do Match para unir duplas de mentor e mentorado com
                  características semelhantes, garantindo maior assertividade no desenvolvimento e crescimento da dupla.
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Para isso, solicitamos que você responda algumas perguntas. Elas nos ajudarão a coletar as informações
                  necessárias para comparar com os outros participantes e formar a sua dupla ideal.
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.4, fontSize: '14px' }}>
                  Suas respostas serão essenciais para criarmos um match eficiente, identificando as opções mais
                  adequadas e possibilitando que você escolha aqueles que mais se alinham com o que você busca. Responda
                  agora e descubra quem pode impulsionar sua jornada!
                </Typography>
              </Box>
            )}
            {activeStep == 1 && (
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={12}>
                  {urlNewImage ? (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          badgeContent={
                            <Fab size="small" color="error" aria-label="add" onClick={handleCancelUpdate}>
                              <DeleteForeverIcon />
                            </Fab>
                          }
                        >
                          <Avatar sx={{ width: 100, height: 100 }} src={urlNewImage} />
                        </Badge>
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={
                          <Fab size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
                            <BackupIcon />
                          </Fab>
                        }
                      >
                        <Avatar
                          sx={{ width: 80, height: 80 }}
                          src={
                            profile?.image_user
                              ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) +
                                'images/stories/avatar/medium_' +
                                profile?.image_user
                              : ''
                          }
                        />
                      </Badge>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={10}>
                  <EASInput
                    onFocus={() =>
                      setMentoryInvitationError((existingValues) => ({
                        ...existingValues,
                        name_user: '',
                      }))
                    }
                    label="Nome"
                    type="text"
                    value={mentoryInvitation?.name_user}
                    onChange={(e) =>
                      setMentoryInvitation((existingValues) => ({
                        ...existingValues,
                        name_user: e.target.value,
                      }))
                    }
                    error={!!mentoryInvitationError?.name_user}
                    helperText={mentoryInvitationError?.name_user}
                  />
                </Grid>
                {mentoryInvitation?.participation_role_invitation == '0' && (
                  <Grid item xs={10}>
                    <EASInput
                      onFocus={() =>
                        setMentoryInvitationError((existingValues) => ({
                          ...existingValues,
                          curriculum_user: '',
                        }))
                      }
                      label="Mini Currículo"
                      type="text"
                      multiline
                      rows={3}
                      maxRows={3}
                      value={mentoryInvitation?.curriculum_user}
                      onChange={(e) =>
                        setMentoryInvitation((existingValues) => ({
                          ...existingValues,
                          curriculum_user: e.target.value,
                        }))
                      }
                      error={!!mentoryInvitationError?.curriculum_user}
                      helperText={mentoryInvitationError?.curriculum_user}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {activeStep == 2 && (
              <>
              {mentoryInvitation?.match_objective_block_program == '0' ? (
                <Box sx={{display:'flex-grid', textAlign:'center'}}>
                  {mentoryInvitation?.names_objectives_invitation && mentoryInvitation?.names_objectives_invitation.length > 0 ?
                    mentoryInvitation.names_objectives_invitation?.map((tag, index) => (
                        <Chip 
                          key={index}
                          label={tag}
                          icon={<CheckCircleIcon />} 
                          color={'primary'} 
                          sx={{m:'8px 5px'}}
                        />
     

                  )):(
                    <Chip label="Não há competencias selecionadas" />
                  )}
                </Box>
              ):(
                <EASCheckChips
                  label="Selecione as competências conforme seu interesse"
                  onFocus={(e) => {
                    setMentoryInvitationError((existingValues) => ({
                      ...existingValues,
                      objectives_invitation: undefined,
                    }));
                  }}
                  options={invitation?.program_invitation?.tags ? invitation?.program_invitation?.tags : []}
                  value={mentoryInvitation?.objectives_invitation}
                  onChange={(e) => {
                    const { name, checked } = e.target;
                    const newTags = checked
                      ? [...(mentoryInvitation?.objectives_invitation || []), name]
                      : (mentoryInvitation?.objectives_invitation || []).filter((tag) => tag !== name);

                    setMentoryInvitation((existingValues) => ({
                      ...existingValues,
                      objectives_invitation: newTags,
                    }));
                  }}
                  error={!!mentoryInvitationError?.objectives_invitation}
                  helperText={mentoryInvitationError?.objectives_invitation?.[0]}
                />
              )}
              </>
            )}
            {activeStep >= 3 && activeStep < steps - 1 && (
              <Box
                sx={{
                  width: '70%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  gap: 4,
                }}
              >
                <Box
                  sx={{
                    width: '70%',
                    position: 'absolute',
                    top: 140,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 15,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    sx={{
                      height: 15,
                      borderRadius: 5,
                      width: '70%',
                      backgroundColor: '#e1e1e1',
                      mt: 5,
                    }}
                    value={
                      ((activeStep - 3) * 100) /
                      (((Number(invitation?.program_invitation?.match_custom_input_program?.length) - 1) | 0) +
                        isMentor)
                    }
                  />
                  <Typography variant="caption">
                    {'Questão ' +
                      (activeStep - 2) +
                      ' de ' +
                      ((Number(invitation?.program_invitation?.match_custom_input_program?.length) | 0) + isMentor)}
                  </Typography>
                </Box>
                {activeStep === 3 && isMentor === 1 && (
                  <>
                    <Box sx={{ width: '100%', mt: 8 }}>
                      <Alert severity="info">
                        Selecione a quantidade de mentorados que deseja acompanhar neste processo.
                      </Alert>
                    </Box>
                    <Box sx={{ width: '100%', mb: 8 }}>
                      <EASSelect
                        options={totalMentoyMentor}
                        onFocus={() =>
                          setMentoryInvitationError((existingValues) => ({
                            ...existingValues,
                            amount_mentorado_mentor_invitation: '',
                          }))
                        }
                        label="Quantidade de Mentorados"
                        getOptionLabel={(option) => option.text}
                        value={
                          totalMentoyMentor &&
                          totalMentoyMentor.find(
                            (item) => item.value == mentoryInvitation?.amount_mentorado_mentor_invitation,
                          )
                        }
                        onChangeSelect={(event, value) => {
                          if (value && value.value) {
                            setMentoryInvitation((existingValues) => ({
                              ...existingValues,
                              amount_mentorado_mentor_invitation: value.value,
                            }));
                          }
                        }}
                        error={!!mentoryInvitationError?.amount_mentorado_mentor_invitation}
                        helperText={mentoryInvitationError?.amount_mentorado_mentor_invitation}
                      />
                    </Box>
                  </>
                )}
                {activeStep > 2 + isMentor && activeStep < steps - 1 && (
                  <>
                    {questionViews &&
                      questionViews.map((question, index) => (
                        <>
                          {activeStep - isMentor - 3 === index && (
                            <>
                              {question?.label_custom_input && (
                                <Box sx={{ width: '100%', mt: 0 }}>
                                  <Alert severity="info">{question?.label_custom_input}</Alert>
                                </Box>
                              )}
                              <Box
                                sx={{
                                  width: '70%',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                <EASCustom
                                  customInput={question}
                                  value={(() => {
                                    const response = mentoryInvitation?.match_responses_invitation?.find(
                                      (item) => item.id_custom_input === question.id_custom_input,
                                    )?.responses_custom_input;

                                    switch (question?.type_custom_input) {
                                      case '1':
                                        return question?.options_custom_input?.find(
                                          (item) => item.value === response?.[0],
                                        );
                                      case '2':
                                        return response?.[0] ?? '';
                                      case '3':
                                        return response ?? [];
                                      default:
                                        return '';
                                    }
                                  })()}
                                  onChange={(event, values: string | IList) => {
                                    const newValueCheck =
                                      mentoryInvitation?.match_responses_invitation?.find(
                                        (item) => item.id_custom_input === question.id_custom_input,
                                      )?.responses_custom_input || [];
                                    const existingResponses = mentoryInvitation?.match_responses_invitation || [];
                                    const itemExists = existingResponses.some(
                                      (item) => item.id_custom_input === question?.id_custom_input,
                                    );
                                    const saveInputRespnse: Array<string> | undefined = (() => {
                                      switch (question?.type_custom_input) {
                                        case '1':
                                          return values && typeof values === 'object' ? [values?.value] : [];
                                        case '2':
                                          return values && typeof values === 'string' ? [values] : [];
                                        case '3':
                                          return event.target.checked
                                            ? [...newValueCheck, event.target.name]
                                            : newValueCheck.filter((response) => response !== event.target.name);
                                        default:
                                          return undefined;
                                      }
                                    })();

                                    setMentoryInvitation((existingValues) => ({
                                      ...existingValues,
                                      match_responses_invitation: itemExists
                                        ? existingResponses.map((item) =>
                                            item.id_custom_input === question?.id_custom_input
                                              ? {
                                                  ...item,
                                                  responses_custom_input: saveInputRespnse,
                                                }
                                              : item,
                                          )
                                        : [
                                            ...existingResponses,
                                            {
                                              id_custom_input: question?.id_custom_input,
                                              responses_custom_input: saveInputRespnse,
                                            },
                                          ],
                                    }));
                                  }}
                                  onFocus={() => {
                                    const existingResponses = mentoryInvitationError?.match_responses_invitation || [];
                                    const itemExists = existingResponses.some(
                                      (item) => item.id_custom_input === question?.id_custom_input,
                                    );

                                    setMentoryInvitationError((existingValues) => ({
                                      ...existingValues,
                                      match_responses_invitation: itemExists
                                        ? existingResponses.map((item) =>
                                            item.id_custom_input === question?.id_custom_input
                                              ? {
                                                  ...item,
                                                  responses_custom_input: undefined,
                                                }
                                              : item,
                                          )
                                        : undefined,
                                    }));
                                  }}
                                  helperText={
                                    mentoryInvitationError?.match_responses_invitation?.find(
                                      (item) => item.id_custom_input === question.id_custom_input,
                                    )?.responses_custom_input?.[0]
                                  }
                                />
                              </Box>
                            </>
                          )}
                        </>
                      ))}
                  </>
                )}
              </Box>
            )}
            {activeStep === steps - 1 && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography align="center" variant="h6" sx={{ fontWeight: '400' }}>
                  Quase lá!
                </Typography>
                <Typography paragraph sx={{ fontWeight: '200', lineHeight: 1.6 }}>
                  Ao clicar no botão Finalizar, suas respostas serão enviadas e analisadas. Assim que possível, você
                  terá acesso à lista de mentores que mais se encaixam com suas necessidades e interesses.
                </Typography>
                <Typography paragraph align="center" sx={{ fontWeight: '400', lineHeight: 1.6 }}>
                  Prepare-se para escolher aqueles que farão a diferença na sua jornada!
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <EASButton
              variant="outlined"
              size="small"
              onClick={handleBack}
              startIcon={<KeyboardArrowLeft />}
              disabled={activeStep === 0 || isLoading}
            >
              Voltar
            </EASButton>
            <EASButton
              variant="contained"
              onClick={handleNext}
              disabled={isLoading}
              endIcon={
                isLoading ? (
                  <CircularProgress variant="indeterminate" color="inherit" size={20} />
                ) : (
                  <KeyboardArrowRight />
                )
              }
            >
              {activeStep === steps - 1 ? 'Finalizar' : 'Próximo'}
            </EASButton>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog open={opening} onClose={handleClose}>
        <DialogTitle>Enviar Imagem</DialogTitle>
        <Divider />
        <DialogContent sx={{ maxWidth: '380px' }}>
          <DialogContentText>
            <Typography component="span" variant="subtitle2">
              Adicione ou atualize a imagem da pessoa. Recomendamos imagens quadradas.
            </Typography>
          </DialogContentText>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 3,
            }}
          >
            <EASFileUploadCrop
              titleButton="Enviar Arquivo"
              acceptFile={NewacceptFile}
              isLoadingUpload={isLoadingUpload}
              elementIsDragAccept={
                <Box
                  sx={{
                    p: 2,
                    mb: 3,
                    borderRadius: '8px',
                    height: 140,
                    border: '1px dashed green',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Typography color="green" variant="h6">
                    Pode soltar a imagem agora
                  </Typography>
                </Box>
              }
              elementIsDragReject={
                <Box
                  sx={{
                    p: 2,
                    mb: 3,
                    borderRadius: '8px',
                    height: 140,
                    border: '1px dashed red',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Typography color="red" variant="h6">
                    Este arquivo não é permitido
                  </Typography>
                </Box>
              }
              elementIsNotDragActive={
                <Box
                  sx={{
                    p: 2,
                    mb: 3,
                    borderRadius: '8px',
                    height: 140,
                    border: '1px dashed grey',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Typography color="grey" variant="h6">
                    Clique ou arraste a imagem até aqui
                  </Typography>
                </Box>
              }
              setFile={(e) => loadFile(e)}
              file={file}
              previewCanvasRef={previewCanvasRef}
              imgRef={imgRef}
              setPercentCrop={(e) => setPercentCrop(e)}
              percentCrop={percentCrop}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} disabled={isLoadingUpload}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={storeAvatar} disabled={isLoadingUpload}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
