
import React from 'react';

import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Stack } from '@mui/material';
import { IMatchList } from '../../shared/types';
import { MatchResultsPanelCard } from './MatchResultsPanelCard';
import { PaginationComponent } from '../../shared/components';




export interface IRefMatchResults {
  saveMatch: () => void;
}

interface IMatchResultsPanelProps {
  match?:IMatchList;
}

export const MatchResultsPanel: React.FC<IMatchResultsPanelProps> = ({match}) => {

  return (
    <Grid container spacing={2}>
      {match?.data?.map((matchItem) => (
      <Grid item xs={12} md={6} lg={4} xl={3} key={matchItem.id_program_invitation}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MatchResultsPanelCard matchItem={matchItem} />
          </Grid>
        </Grid>
      </Grid>
      ))}
    </Grid>
  );
};

