
import React, { useState, useMemo, ReactElement } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Typography, Card, CardHeader, Tooltip, Grid, AvatarGroup, Avatar } from '@mui/material/';
import { ProgramResourcesHumanMentoringAdd } from './ProgramResourcesHumanMentoringAdd';

import { useSnackbar } from 'notistack';
import { EASDialogAlert, PaginationComponent } from '../../shared/components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProgramsMentoringService } from '../../shared/services/api/programsmentoring/ProgramsMentoringService';
import { IProgram, IProgramMentoring } from '../../shared/types';
import { EASButton } from '../../shared/forms';
import { Environment } from '../../shared/environment';

interface IProgramResourcesHumanMentoringProps {
  program?: IProgram;
}

export const ProgramResourcesHumanMentoring: React.FC<IProgramResourcesHumanMentoringProps> = ({ program }) => {



  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'resend' | null>(null);

  const [idProgramsMentoring, setIdProgramsMentoring] = useState<string | null>(null);

  const searchResourcesHumanMentoring = useMemo(() => {
    return searchParams.get('searchResourcesHumanMentoring') || '';
  }, [searchParams]);

  const pageResourcesHumanMentoring = useMemo(() => {
    return Number(searchParams.get('pageResourcesHumanMentoring') || '1');
  }, [searchParams]);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['program-mentoring-list', [program?.id_program, searchResourcesHumanMentoring, pageResourcesHumanMentoring]], () => ProgramsMentoringService.getProgramsMentoring(program?.id_program, pageResourcesHumanMentoring, searchResourcesHumanMentoring), {
    //staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de duplas.', { variant: 'error' });
    },
  });

  const { mutateAsync: storeRemove } = useMutation((idProgramsMentoring: string) => ProgramsMentoringService.deleteProgramsMentoring(idProgramsMentoring), {
    onSuccess: () => {
      queryClient.invalidateQueries('program-mentoring-list');
      enqueueSnackbar('Convite removido com sucesso!', { variant: 'success' });
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover o convite!', { variant: 'error' });
      setLoadingDialog(false);
    },
  });

  const { mutateAsync: reSend } = useMutation((resendProgramsMentoring: IProgramMentoring) => ProgramsMentoringService.updateProgramsMentoring(resendProgramsMentoring), {
    onSuccess: () => {
      enqueueSnackbar('Convite reenviado com sucesso.', { variant: 'success' });
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao reenviar convite.', { variant: 'error' });
      setLoadingDialog(false);
    },
  });

  const handleCancelDialog = () => {
    setIdProgramsMentoring(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
  };

  const handleConfirmeDialog = () => {
    switch (executeDialog) {
      case 'remove':
        removeProgramsMentoring();
        break;
      case 'resend':
        resendProgramsMentoring();
        break;
    }
  };

  const resendProgramsMentoring = () => {
    if (idProgramsMentoring) {
      setLoadingDialog(true);
      const resend: IProgramMentoring = {
        id_mentory: idProgramsMentoring,
      };
      reSend(resend);
    }
  };

  const removeProgramsMentoring = () => {
    if (idProgramsMentoring) {
      setLoadingDialog(true);
      storeRemove(idProgramsMentoring);
    }
  };


  return (
    <Card elevation={0}>
      <CardHeader
        action={<EASButton variant="contained" size="small" onClick={() => setOpenDialogAdd(true)}>Criar Nova Dupla</EASButton>}
        title={<Typography variant="h6">Duplas do Programa</Typography>}
        subheader="Crie duplas usando os convidados que se cadastraram e aceitaram oconvite."
      />
      <Grid container spacing={2}>
        {isLoading && (
          <>
            gsf
          </>
        )}
        {!isLoading && ((data?.data && data.data.length) ? (data.data.map((programsMentoring) => (
          <Grid item xs={12} md={6} key={programsMentoring?.id_mentory}>
            <Card sx={{ borderRadius: 2, bgcolor: 'transparent', padding: '10px' }} variant='outlined' elevation={0}>
              <Grid container spacing={2}>
                <Grid item>
                  <AvatarGroup max={2}>
                    <Tooltip title={'Mentorado: ' + programsMentoring?.name_mentorado}>
                      <Avatar sx={{ width: 80, height: 80 }} alt={programsMentoring?.name_mentorado} src={programsMentoring?.image_mentorado ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + programsMentoring?.image_mentorado : ''} />
                    </Tooltip>
                    <Tooltip title={'Mentor: ' + programsMentoring?.name_mentor}>
                      <Avatar sx={{ width: 80, height: 80 }} alt={programsMentoring?.name_mentor} src={programsMentoring?.image_mentor ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + programsMentoring?.image_mentor : ''} />
                    </Tooltip>
                  </AvatarGroup>
                </Grid>
                <Grid item zeroMinWidth sm={true}>
                  <Grid container spacing={0} >
                    <Grid item xs={12} sx={{ mt: 0.2 }}>
                      <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>Mentorado:</Typography>
                      <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500 }}>{programsMentoring?.name_mentorado}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 0.2 }}>
                      <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>Mentor:</Typography>
                      <Typography component="h6" variant="caption">{programsMentoring?.name_mentor}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))) : (
          <Grid item xs={12} sm={6} md={4}>
            <Card >
              Nenhum item encontrado
            </Card>
          </Grid>
        ))}
      </Grid>
      {!isLoading && (
        <PaginationComponent page={pageResourcesHumanMentoring} total={data?.total} setSearchParams={(newPage) => setSearchParams({ searchResourcesHumanMentoring, pageResourcesHumanMentoring: newPage.toString() }, { replace: true })} />
      )}

      <ProgramResourcesHumanMentoringAdd open={openDialogAdd} setOpen={(e) => setOpenDialogAdd(e)} program={program} />
      <EASDialogAlert
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
    </Card>

  );
};
