import { useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { IProgram } from '../../shared/types';

import { useHumanResourcesContext } from '../../shared/contexts';
import { ProgramsService, UtilService } from '../../shared/services/';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import ProgramResourcesHumanLayoutPanel, { IRefLayout } from './ProgramResourcesHumanLayoutPanel';

export const ProgramResourcesHumanLayout = () => {

    const { humanResource } = useHumanResourcesContext();

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refLayout = useRef<IRefLayout>(null);

    const storeDisplay = () => {
      if (refLayout.current)
        refLayout.current.saveLayout();
    };

    const { data: program, isFetching: isLoadingProgram } = useQuery(['program-edit', humanResource?.id_program], () => ProgramsService.read(humanResource?.id_program), {
      enabled: !!humanResource,
      staleTime: Infinity,
      onSuccess: (response) => {
        if (response.status !== 'success') {
            queryClient.invalidateQueries('program-edit');
            enqueueSnackbar('As informações não foram carregadas corretamente. Recarregue a página e tente novamente.', { variant:'warning'});
        }
      },
      onError: () => {
          enqueueSnackbar('Erro inesperado. Recarregue a página e tente novamente.', { variant:'error'});
      }
    });


    const { mutateAsync: storeUpdate, isLoading: isLoadingStore } = useMutation((updateProgram: IProgram) => ProgramsService.update(updateProgram), {
        onSuccess: () => {
            queryClient.invalidateQueries('program-list');
            queryClient.invalidateQueries('program-edit');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant: 'error' });
        },
    });

    return (
      <LayoutDefault >
      <EASCard
          titleCard={'Customizar Layout'}
          breadcrumbsCard=""
          actionCard={<Stack direction="row" spacing={1}>
              <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
          </Stack>} >
          <Box sx={{ mt: 2 }}>
              <ProgramResourcesHumanLayoutPanel
                  program={program?.status === 'success' ? program?.item : undefined}
                  isLoading={isLoadingProgram || isLoadingStore }
                  storeUpdate={(program) => storeUpdate(program)}
                  ref={refLayout}
              />
          </Box>
      </EASCard>
  </LayoutDefault>
    );
};
