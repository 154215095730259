import React, { useState, useMemo } from 'react';
import {Box, Typography, Chip, Skeleton, Alert, Grid, Divider, Badge  } from '@mui/material/';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useSnackbar } from 'notistack';

import { useSearchParams } from 'react-router-dom';

import { IObjective, IMentory  } from '../../shared/types';

import { ObjectiveDetailService } from '../../shared/services/api/objective-detail/ObjectiveDetailService';
import { useQuery } from 'react-query';
import { PaginationComponent } from '../../shared/components';

interface IMentoryTabDetailingDetailProps {
  mentory?: IMentory;
  valueTab: number;
  allObjectives?: Array<IObjective> | null;
}  

export const MentoryTabDetailingDetail: React.FC<IMentoryTabDetailingDetailProps> = ({mentory, valueTab, allObjectives}) => {

  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const { data, isLoading } = useQuery(['mentoring-detailing-detail', [mentory?.id_mentory, valueTab, page]],() =>   ObjectiveDetailService.getObjectiveDetals(valueTab, page), {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de competências.', { variant:'error'});
    },
  });

  return (
    <div style={{marginBottom:'20px'}}>
      {(allObjectives && allObjectives.length ) && (allObjectives.map((objective) => (
      <Box style={{ display: !(objective.id_objective && valueTab == parseInt(objective.id_objective)) ? 'none' : 'flex', justifyContent: 'center', marginBottom:'25px'}} key={objective.id_objective} >
        <Typography sx={{textAlign: 'center', marginY:'15px',  fontWeight: '700',  fontSize:  '1.5em'}}>{objective.text_objective}</Typography>
      </Box> 
      )))}
      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        {isLoading && (
        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px', width:'100%'}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item zeroMinWidth sm={true}>
                  <Grid container spacing={1} >
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width={'90%'} height={18} />
                    </Grid> 
                    <Grid item xs={12} sm={6} >
                      <Skeleton variant="rectangular" width={150} height={15} />
                    </Grid>                    
                    <Grid item xs={12} sm={6} >
                      <Skeleton variant="rectangular" width={150} height={15} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1} >
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width={'100%'} height={12} />
                    </Grid>
                    <Grid item xs={12} >
                      <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> 
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item zeroMinWidth sm={true}>
                  <Grid container spacing={1} >
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width={'90%'} height={18} />
                    </Grid> 
                    <Grid item xs={12} sm={6} >
                      <Skeleton variant="rectangular" width={150} height={15} />
                    </Grid>                    
                    <Grid item xs={12} sm={6} >
                      <Skeleton variant="rectangular" width={150} height={15} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1} >
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width={'100%'} height={12} />
                    </Grid>
                    <Grid item xs={12} >
                      <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> 
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item zeroMinWidth sm={true}>
                  <Grid container spacing={1} >
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width={'90%'} height={18} />
                    </Grid> 
                    <Grid item xs={12} sm={6} >
                      <Skeleton variant="rectangular" width={150} height={15} />
                    </Grid>                    
                    <Grid item xs={12} sm={6} >
                      <Skeleton variant="rectangular" width={150} height={15} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1} >
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width={'100%'} height={12} />
                    </Grid>
                    <Grid item xs={12} >
                      <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box> 
        )}
        <Grid container spacing={2}>
          {!isLoading && (data?.data && data?.data?.length ? (data?.data.map((objectiveDetail, index) => (
          <Grid item xs={12} key={objectiveDetail.id_objective_detail}>
            <Grid container spacing={2}>
              <Grid item zeroMinWidth sm={true}>
                <Grid container spacing={0} >
                  <Grid item xs={12}>
                    <Typography component="h4" variant="subtitle2" sx={{fontWeight:500}}>{objectiveDetail.text_objective_detail}</Typography>
                  </Grid>    
                  <Grid item xs={12} sm={6} sx={{marginTop:'5px'}}>
                    <Typography component="h5" variant="caption">
                      {(objectiveDetail.value_objective_detail ? 
                      ('Nota Inicial: ' + objectiveDetail.value_objective_detail) : 
                      (
                      <Chip
                        label="Nota inicial não informada"
                        icon={<ErrorOutlineIcon />}
                        size="small"
                      />
                      ))}
                    </Typography>
                  </Grid>    
                  <Grid item xs={12} sm={6} sx={{marginTop:'5px', textAlign: 'center'}} >
                    <Typography component="h5" variant="caption">
                      {'Tarefas Associadas:'}
                      <Badge color="primary" sx={{ml:2}} badgeContent={objectiveDetail.total_task_plan}></Badge>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{textAlign: 'center'}} >
                <Typography sx={{textAlign: 'center', fontSize: '12px'}}>
                  Prioridade
                </Typography>
                {(objectiveDetail.priority_objective_detail == '1' ? (
                  <CheckCircleIcon sx={{mt:'0.3em'}} color="primary" />
                ) : (
                  <RemoveCircleOutlineIcon sx={{mt:'0.3em'}} />
                ))}
              </Grid>
            </Grid>
            {data?.data && index < data.data.length -1 && (
              <Divider sx={{mt:'10px'}} />
            )}
          </Grid>
          ))) : (
          <Alert severity="warning">Nenhum comportamento de competência cadastrado para exibir!</Alert>
        ))}
        </Grid>
      </Box>
      {!isLoading  && (
        <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })} />
      )}
    </div>
  );
};
