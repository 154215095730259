
import React, { useState } from 'react';

import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, Collapse, Divider, Grid, Skeleton, Slider, styled, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Environment } from '../../shared/environment';
import { IMatchUser } from '../../shared/types';
import { EASButton } from '../../shared/forms';
import { MatchResultsPanelMentor } from './MatchResultsPanelMentor';



export interface IRefMatchResults {
  saveMatch: () => void;
}

interface IMatchResultsPanelProps {
  matchItem?:IMatchUser;
  isLoading?: boolean;

}

export const MatchResultsPanelCard: React.FC<IMatchResultsPanelProps> = ({matchItem, isLoading}) => {

  const [expand, setExpand] = useState<boolean>(false);

  return (
    <Card variant='outlined' elevation={0}>
      <CardHeader
        avatar={<Avatar sx={{ width: 60, height: 60 }} alt={matchItem?.name_user} src={matchItem?.image_user ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + matchItem?.image_user : ''} />}
        title={matchItem?.name_user}
        titleTypographyProps={{ fontSize: 14, fontWeight: 'bold' }}
        subheader='Selecione a dupla clicando em um mentor da lista para formação da dupla.'
        subheaderTypographyProps={{ fontSize: 12, }}
      />
      <Divider sx={{ width: '100%' }} />
      <CardContent>
        {matchItem?.mentors_match?.map((mentor, index) => (
          <>
            {index > 2 ? (
            <Collapse key={mentor.id_program_invitation}  in={expand}>
              <MatchResultsPanelMentor mentor={mentor} />
            </Collapse>
            ):(
            <MatchResultsPanelMentor key={mentor.id_program_invitation} mentor={mentor} />
            )}          
          </>
        ))}
      </CardContent>
      <CardActions>
        <EASButton size="small" onClick={() => setExpand(!expand)} >{expand ? 'Fechar': 'Ver Todos'}</EASButton>
      </CardActions>
    </Card>

  );
};


