import { ReactElement, useMemo, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { IProgramInvitation, IInvitation } from '../../shared/types';

import { useHumanResourcesContext } from '../../shared/contexts';
import { InvitationService, ProgramsService, UtilService } from '../../shared/services/';

import { EASCard, EASDialogAlert, PaginationComponent } from '../../shared/components';
import { EASButton, EASInput } from '../../shared/forms';

import { useSearchParams } from 'react-router-dom';
import { ProgramResourcesHumanInvitationsAdd } from './ProgramResourcesHumanInvitationsAdd';
import { ProgramResourcesHumanInvitationsImport } from './ProgramResourcesHumanInvitationsImport';
import { ProgramResourcesHumanInvitationsList } from './ProgramResourcesHumanInvitationsList';


export const ProgramResourcesHumanInvitationsPanel = (() => {
    const { humanResource } = useHumanResourcesContext();

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();


    const [searchParams, setSearchParams] = useSearchParams();
    
    const [openDialogAdd, setOpenDialogAdd] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
  
    const [openDialogImport, setOpenDialogImport] = useState(false);
  
    const [openDialog, setOpenDialog] = useState(false);
    const [titleDialog, setTitleDialog] = useState<string | null>('');
    const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
    const [executeDialog, setExecuteDialog] = useState<'remove' | 'resend' | null>(null);
  
    const [idProgramsInvitation, setIdProgramsInvitation] = useState<string | null>(null);

    const search = useMemo(() => {
        return searchParams.get('search') || '';
    }, [searchParams]);
    
    const page = useMemo(() => {
        return Number(searchParams.get('page') || '1');
    }, [searchParams]);


    const { data: invitations, isFetching: isLoadingInvitations } = useQuery(['invitation-list', humanResource?.id_program, page, search], () => InvitationService.read( page, search, humanResource?.id_program), {
      enabled: !!humanResource,
      staleTime: Infinity,
      onError: () => {
          enqueueSnackbar('Erro inesperado. Recarregue a página e tente novamente.', { variant:'error'});
      }
    });


    const { data: program, isFetching: isLoadingProgram } = useQuery(['program-edit', humanResource?.id_program], () => ProgramsService.read(humanResource?.id_program), {
      enabled: !!humanResource,
      staleTime: Infinity,
      onSuccess: (response) => {
        if (response.status !== 'success') {
           // queryClient.invalidateQueries('program-edit');
            enqueueSnackbar('As informações não foram carregadas corretamente. Recarregue a página e tente novamente.', { variant:'warning'});
        }
      },
      onError: () => {
          enqueueSnackbar('Erro inesperado. Recarregue a página e tente novamente.', { variant:'error'});
      }
    });

    const { data: tags, isLoading: loadingTags } = useQuery(['tags-list'], () => UtilService.getTags(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Tags.', { variant: 'error' });
        },
    });

    /*
    const { mutateAsync: storeUpdate, isLoading: isLoadingStore } = useMutation((invitation: IInvitation) => InvitationService.update(invitation), {
        onSuccess: () => {
            queryClient.invalidateQueries('invitation-list');
            queryClient.invalidateQueries('invitation-list');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant: 'error' });
        },
    });
*/

      const { mutateAsync:storeRemove } = useMutation((idProgramsInvitation:string) => InvitationService.destroy(idProgramsInvitation), {

        onSuccess: (response) => {
          if (response.status === 'success') {
            queryClient.invalidateQueries('invitation-list');
            enqueueSnackbar('Convite removido com sucesso!', { variant:'success'});
            setLoadingDialog(false);
            handleCancelDialog();      
          }
          else{
             enqueueSnackbar('Erro ao tentar remover o convite!', { variant:'error'});
             setLoadingDialog(false);
          }
        },
        onError: () => {
          enqueueSnackbar('Erro desconhecido. Recarregue a página e tente novamente!', { variant:'error'});
          setLoadingDialog(false);
        },
      });

      const { mutateAsync:reSend } = useMutation((resendInvitation:IInvitation) => InvitationService.update(resendInvitation), {
        onSuccess: () => {
          enqueueSnackbar('Convite reenviado com sucesso.', { variant:'success'});
          setLoadingDialog(false);
          handleCancelDialog();
        },
        onError: () => {
          enqueueSnackbar('Erro ao reenviar convite.', { variant:'error'});
          setLoadingDialog(false);
        },
      });

      const handleCancelDialog = () => {
        setIdProgramsInvitation(null);
        setTitleDialog(null);
        setTextDialog(null);
        setExecuteDialog(null);
        setOpenDialog(false);
      };
    
      const handleConfirmeDialog = () => {
        switch (executeDialog) {
          case 'remove':
            removeProgramsInvitation();
            break;
          case 'resend':
            resendProgramsInvitation();
            break;
        }
      };
    

  const resendProgramsInvitation = () => {
    if (idProgramsInvitation) {
      setLoadingDialog(true);
      const resend: IProgramInvitation = {
        id_program_invitation: idProgramsInvitation,
        resend:'1',
      };  
      reSend(resend);
    }
  };

  const removeProgramsInvitation = () => {
    if(idProgramsInvitation){
      setLoadingDialog(true);
      storeRemove(idProgramsInvitation);
    }
  };

  const  handleResendInvite = (id_program_invitation?:string, email_program_invitation?:string) => {
    if(id_program_invitation){
      setIdProgramsInvitation(id_program_invitation);
      setTitleDialog('Reenviar Convite');
      setTextDialog(<><Typography >Deseja reenviar o email para este convidado ?</Typography><Typography sx={{display:'flex'}}>Email de destino:&nbsp;<Typography sx={{fontWeight:'bold'}}>{email_program_invitation}</Typography></Typography></>);
      setExecuteDialog('resend');
      setOpenDialog(true);
    }
  }; 

  const  handleRemoveInvite = (id_evaluation?:string) => {
    if(id_evaluation){
      setIdProgramsInvitation(id_evaluation);
      setTitleDialog('Excluir Convite');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene este convite?</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 


  return (
    <>
      <EASCard
        titleCard={'Inscrições do Programa'}
        breadcrumbsCard=""
        actionCard={ <Stack direction="row" sx={{display:'flex', minWidth:'520px'}} spacing={1}>
                      <EASButton variant="contained" sx={{ whiteSpace:'nowrap', px:4 }} onClick={() =>setOpenDialogImport(true)}>Importar Lista</EASButton>
                      <EASButton variant="contained" sx={{whiteSpace:'nowrap', px:4 }} size="small" onClick={() =>setOpenDialogAdd(true)}>Nova Inscrição</EASButton>
                      <EASInput label="Pesquisar" type='search' size="small" sx={{maxWidth:'280px'}} value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                    </Stack>} 
      >
        <Box sx={{ mt: 2 }}>
          <Card sx={{ borderRadius: 2, m:2}} variant='outlined' elevation={0}>
            <CardHeader
              title='Gerenciar Lista de Inscrições do Programa.'
              titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', }}
            />
            <Divider />
            <CardContent>
              <ProgramResourcesHumanInvitationsList
              invitations={invitations?.status === 'success' ? invitations?.list : undefined}
              isLoading={isLoadingProgram || isLoadingInvitations || loadingTags}
              handleResendInvite={(invitation, email) => handleResendInvite(invitation, email)}
              handleRemoveInvite={(invitation) => handleRemoveInvite(invitation)}
              
              />
              {!(isLoadingProgram || isLoadingInvitations || loadingTags)  && (
                <PaginationComponent page={page} total={invitations?.list?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
              )}
            </CardContent>
          </Card>
        </Box>
      </EASCard>
      {!(isLoadingProgram || isLoadingInvitations || loadingTags)  && (
      <>
        <ProgramResourcesHumanInvitationsAdd open={openDialogAdd} setOpen={(e) => setOpenDialogAdd(e)} program={program?.status === 'success' ? program?.item : undefined} tags={tags} listInvitation={invitations?.status === 'success' ? invitations?.list?.data : undefined}/>
        <ProgramResourcesHumanInvitationsImport open={openDialogImport} setOpen={(e) => setOpenDialogImport(e)} program={program?.status === 'success' ? program?.item : undefined} tags={tags} listInvitation={invitations?.status === 'success' ? invitations?.list?.data : undefined}/>
        <EASDialogAlert 
          type="warning"
          title={titleDialog}
          statusOpen={openDialog}
          actionCancel={handleCancelDialog}
          actionConfirme={handleConfirmeDialog}
          loading={loadingDialog}
        >
          {textDialog}
        </EASDialogAlert>
      </>
       )}
    </>
  );
});
