
import React, { useEffect, useImperativeHandle, useState, Ref } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Slider, styled, Typography, useTheme } from '@mui/material';
import { IMatch, IProgram } from '../../shared/types';
import { EASPicker, EASRadio, EASSelect, EASSwitch } from '../../shared/forms';

import * as yup from 'yup';

export interface IRefMatchConfigure {
  saveMatch: () => void;
}

interface IMatchConfigurePanelProps {
  match?:IMatch;
  isLoading?: boolean;
  storeUpdate: (operator: IMatch) => void;

}

interface IchangeFocus {
  acceptInvitation: boolean;
  match: boolean;
  amountMentor: boolean;
  amountSelect: boolean;
}

const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));


const MatchConfigurePanel = React.forwardRef<IRefMatchConfigure, IMatchConfigurePanelProps>(({ match, isLoading, storeUpdate }: IMatchConfigurePanelProps, ref: Ref<IRefMatchConfigure>) => {

  const theme = useTheme();

  const [matchEdit, setMatchEdit] = useState<IMatch | undefined>(match);
  const [matchEditError, setMatchEditError] = useState<IMatch | null>(null);
  const [changeFocus, setChangeFocus] = useState<IchangeFocus>({acceptInvitation:false, match:false, amountMentor:false, amountSelect:false });


  const totalMentoyMentor = [ { text: '1 Mentorado por mentor', value: '1' },
                              { text: 'Até 2 mentorados por mentor', value: '2' },
                              { text: 'Até 3 Mentorados por mentor', value: '3' },
                              { text: 'Até 4 Mentorados por mentor', value: '4' },
                              { text: 'Perguntar para o mentor', value: '5' },
                            ];

  const totalChoose = [ { text: 'Escolher 1', value: '1' },
                        { text: 'Escolher 2', value: '2' },
                        { text: 'Escolher 3', value: '3' },
                      ];

  const totalSuggestion = [ { text: '3 Sugestões', value: '3' },
                            { text: '4 Sugestões', value: '4' },
                            { text: '6 Sugestões', value: '6' },
                            { text: '8 Sugestões', value: '8' },
                            ];
  
  useEffect(() => {

    async function setMatchProgram() {
      if(matchEdit?.match_program != '1')
      {
        setMatchEditError(null) ;    
        const ControlsMatch = [
          'start_enroll_program',
          'close_enroll_program',
          'mentory_mentor_program',
          'match_type_program',
          'match_select_program',
          'match_select_amount_program',
          'match_suggestion_amount_program',
          'start_match_program',
          'close_match_program',
          'match_percent_objectives_program',
          'match_percent_questions_program',
          'match_percent_suggestion_program',
          'match_objective_block_program',
          'match_values_priority_program',
          'match_suggestions_review_program'
        ];
        ControlsMatch.forEach((controlName) => {
          setMatchEdit(existingValues => ({
            ...existingValues,
            [controlName]: '',
          }));
          setMatchEditError(existingValues => ({
            ...existingValues,
            [controlName]: null,
          }));
        });
      }
      else{
        setMatchEdit(existingValues => ({
          ...existingValues,
          match_percent_objectives_program: matchEdit?.match_percent_objectives_program ? matchEdit?.match_percent_objectives_program : '70',
          match_percent_questions_program: matchEdit?.match_percent_questions_program ? matchEdit?.match_percent_questions_program : '20',
          match_percent_suggestion_program: matchEdit?.match_percent_suggestion_program ? matchEdit?.match_percent_suggestion_program : '10',
        }));
      }
        return;
    }

    async function setMatchTypeProgram() {
      if(matchEdit?.match_type_program == '1')
      {
        setMatchEditError(null) ;    
        const ControlsMatch = [
          'match_select_program',
          'match_select_amount_program',
          'match_suggestion_amount_program',
          'start_match_program',
          'close_match_program',
          'match_suggestions_review_program',
          'match_percent_suggestion_program'
        ];
        ControlsMatch.forEach((controlName) => {
          setMatchEdit(existingValues => ({
            ...existingValues,
            [controlName]: '',
          }));
          setMatchEditError(existingValues => ({
            ...existingValues,
            [controlName]: null, 
          }));
        });
      } 
      else
        return;
    }


    async function setMatchSelectProgram() {
      if(matchEdit?.match_select_program == '1')
      {
        setMatchEditError(null) ;    
        const ControlsMatch = [
          'match_select_amount_program',
          'match_suggestion_amount_program',
          'start_match_program',
          'close_match_program',
          'match_suggestions_review_program',
          'match_percent_suggestion_program'
        ];
        ControlsMatch.forEach((controlName) => {
          setMatchEdit(existingValues => ({
            ...existingValues,
            [controlName]: '',
          }));
          setMatchEditError(existingValues => ({
            ...existingValues,
            [controlName]: null, 
          }));
        });
      }  
      else
        return;
    }

    if (match){

      setMatchProgram().then( e => {
        setMatchTypeProgram().then( e => {
          setMatchSelectProgram();
        });
      });
      
    }
  }, [matchEdit?.match_select_program, matchEdit?.match_type_program, matchEdit?.match_program]);


  const handleSlider = (event: Event, newValue: number, sliderName: keyof IMatch) => {
    if (match) {
      let objectives = (Number(matchEdit?.match_percent_objectives_program) || 0);
      let questions = (Number(matchEdit?.match_percent_questions_program) || 0);
      let suggestion = (Number(matchEdit?.match_percent_suggestion_program) || 0);

      switch (sliderName) {
        case 'match_percent_objectives_program': objectives = newValue; break;
        case 'match_percent_questions_program': questions = newValue; break;
        case 'match_percent_suggestion_program': suggestion = newValue; break;
      }

      const total = objectives + questions + suggestion;
  
      if (total > 100) {
        let excess = total - 100;

        switch (sliderName) {
          case 'match_percent_objectives_program': 
            while (excess > 0) {
              if (questions > 0){
                questions--;
                excess--;
              }
              if(excess > 0 && suggestion > 0){
                suggestion--;
                excess--;
              }
            }
          break;
          case 'match_percent_questions_program':   
            while (excess > 0) {
              if (suggestion > 0){
                suggestion--;
                excess--;
              }
              else {
                if(excess > 0 && objectives > 0){
                  objectives--;
                  excess--;
                }
              }
            }
          break;
          case 'match_percent_suggestion_program': 
            while (excess > 0) {
              if (objectives > 0){
                objectives--;
                excess--;
              }
              if(excess > 0 && questions > 0){
                questions--;
                excess--;
              }
            }
          break;
        }
      }

      setMatchEdit(existingValues => ({
        ...existingValues,
        match_percent_objectives_program: objectives.toString(),
        match_percent_questions_program: questions.toString(),
        match_percent_suggestion_program: suggestion.toString(),
      }));
    }
  };


  useImperativeHandle(ref, () => ({
    saveMatch,
  }));
  
  const isValidMatch = yup.object({
    }).concat(matchEdit?.match_program == '1' ? yup.object({
      close_enroll_program: yup.string().nullable().required('Este campo é obrigatório'),
      start_enroll_program: yup.string().nullable().required('Este campo é obrigatório'),
      mentory_mentor_program: yup.string().nullable().required('Este campo é obrigatório'),
      match_type_program: yup.string().nullable().required('Este campo é obrigatório'),
      match_objective_block_program: yup.string().nullable().required('Este campo é obrigatório'),
      match_values_priority_program: yup.string().nullable().required('Este campo é obrigatório'),
    }).concat(matchEdit?.match_type_program != '1' ? yup.object({
      match_select_program: yup.string().nullable().required('Este campo é obrigatório'),
    }).concat(matchEdit?.match_select_program != '1' ? yup.object({
      start_match_program:  yup.string().nullable().required('Este campo é obrigatório'),
      close_match_program: yup.string().nullable().required('Este campo é obrigatório'),
      match_select_amount_program: yup.string().nullable().required('Este campo é obrigatório'),
      match_suggestion_amount_program: yup.string().nullable().required('Este campo é obrigatório'),
      match_suggestions_review_program: yup.string().nullable().required('Este campo é obrigatório'),
  }): yup.object()) : yup.object()): yup.object());
  
  
  const saveMatch = () => {
    if (!isLoading) {
      isValidMatch
        .validate(matchEdit, { abortEarly: false })
        .then(validateMatch => {
          //setIsLoading(true); 
          const updateMatch: IMatch = {
            id_program: match?.id_program,

            match_program: matchEdit?.match_program,
            start_enroll_program: matchEdit?.match_program == '1' ? validateMatch.start_enroll_program : 'NULL',
            close_enroll_program: matchEdit?.match_program == '1' ? validateMatch.close_enroll_program : 'NULL',
            mentory_mentor_program: matchEdit?.match_program == '1' ? validateMatch.mentory_mentor_program : 'NULL',
            match_type_program: matchEdit?.match_program == '1' ? validateMatch.match_type_program : 'NULL',
            match_select_program: matchEdit?.match_program == '1' && matchEdit?.match_type_program != '1' ? validateMatch.match_select_program : 'NULL',
            start_match_program: matchEdit?.match_program == '1' && matchEdit?.match_type_program != '1' && matchEdit?.match_select_program != '1' ? validateMatch.start_match_program : 'NULL',
            close_match_program: matchEdit?.match_program == '1' && matchEdit?.match_type_program != '1' && matchEdit?.match_select_program != '1' ? validateMatch.close_match_program : 'NULL',
            match_select_amount_program: matchEdit?.match_program == '1' && matchEdit?.match_type_program != '1' && matchEdit?.match_select_program != '1' ? validateMatch.match_select_amount_program : 'NULL',
            match_suggestion_amount_program: matchEdit?.match_program == '1' && matchEdit?.match_type_program != '1' && matchEdit?.match_select_program != '1' ? validateMatch.match_suggestion_amount_program : 'NULL',
            match_custom_input_program: matchEdit?.match_program == '1' ? matchEdit?.match_custom_input_program : undefined,

            match_percent_objectives_program: matchEdit?.match_percent_objectives_program ? matchEdit?.match_percent_objectives_program : 'NULL',
            match_percent_questions_program: matchEdit?.match_percent_questions_program ? matchEdit?.match_percent_questions_program : 'NULL',
            match_percent_suggestion_program: matchEdit?.match_percent_suggestion_program ? matchEdit?.match_percent_suggestion_program : 'NULL',

            match_objective_block_program: matchEdit?.match_objective_block_program ? matchEdit?.match_objective_block_program : 'NULL',
            match_values_priority_program: matchEdit?.match_values_priority_program ? matchEdit?.match_values_priority_program : 'NULL',
            match_suggestions_review_program: matchEdit?.match_suggestions_review_program ? matchEdit?.match_suggestions_review_program : 'NULL',
          };
          storeUpdate(updateMatch);
        })
        .catch((errors: yup.ValidationError) => {
          errors.inner.forEach(error => {
            if (error.path) {
              const keyError = error.path;
              setMatchEditError(existingValues => ({
                ...existingValues,
                [keyError]: error.message
              }));
            }
          });
        });
    }
  };
  

  return (
    <Card sx={{ borderRadius: 2, m:2}} variant='outlined' elevation={0}>
      <CardHeader
        title='Parâmetros para a realização do Match'
        titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', }}
        action={<EASSwitch                  
                  isLoading={isLoading}
                  label="Ativar Uso do Match"
                  value={matchEdit?.match_program}
                  onChange={(e) => 
                    setMatchEdit(existingValues => ({
                      ...existingValues,
                      match_program: e.target.checked ? '1' : '0',
                    }))
                  }
                />
              }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>    
          <Grid item xs={12} sx={{marginBottom:1}}>
            <Box sx={{ display:'flex', backgroundColor: theme.palette.primary.main + '14', borderRadius:'8px', p:{xs:4,xl:3}}}>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={8}>
                  <Typography sx={{ fontWeight: 500, mb:2}} variant="body1">
                    Construção dos resultados
                  </Typography> 
                  <Typography sx={{ fontWeight: 400, pb:1}} variant="caption" component="p">
                    O processo de Match é composto por pilares fundamentais que podem ser utilizados, ou não, para calcular o percentual de compatibilidade entre os participantes (Mentor e Mentorado).
                  </Typography>
                  <Typography sx={{ fontWeight: 500, pb:1, pl:2}} variant="caption" component="p">
                    Semelhança entre objetivos ou comportamentos.
                  </Typography>
                  <Typography sx={{ fontWeight: 500, pb:1, pl:2}} variant="caption" component="p">
                    Semelhança nas respostas às perguntas do Match.
                  </Typography>
                  <Typography sx={{ fontWeight: 500, pb:1, pl:2}} variant="caption" component="p">
                    Escolha das sugestões de dupla.
                  </Typography>
                  <Typography sx={{ fontWeight: 400,}} variant="caption" component="p">
                    Cada um desses fatores representa um percentual na composição do total do Match. Ao lado, configure o valor percentual correspondente a cada um desses itens.
                  </Typography>
                </Grid>
                <Grid item xs={12} xl={4} sx={{maxWidth:'350px', pr:3, py:1,mt:{xs:'15px'}}}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 500, mb:0, pb:0}} variant="caption">Objetivos/Comportamentos</Typography>
                      {isLoading && (
                        <Skeleton variant="rectangular" width="100%" height={10} sx={{borderRadius:12, mt:'12px', mb:'16px'}}/>
                      )}
                      {!isLoading && (
                      <PrettoSlider 
                        disabled={matchEdit?.match_program != '1'}
                        valueLabelDisplay="auto" 
                        value={(Number(matchEdit?.match_percent_objectives_program) || 0)} 
                        onChange={(event, newValue) => {
                          const value = Array.isArray(newValue) ? newValue[0] : newValue;
                          handleSlider(event, value, 'match_percent_objectives_program');
                        }} 
                      />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 500, mb:0}} variant="caption">Perguntas do Match</Typography>
                      {isLoading && (
                        <Skeleton variant="rectangular" width="100%" height={10} sx={{borderRadius:12, mt:'12px', mb:'16px'}}/>
                      )}
                      {!isLoading && (
                      <PrettoSlider 
                        disabled={matchEdit?.match_program != '1'}
                        valueLabelDisplay="auto" 
                        value={(Number(matchEdit?.match_percent_questions_program) || 0)} 
                        onChange={(event, newValue) => {
                          const value = Array.isArray(newValue) ? newValue[0] : newValue;
                          handleSlider(event, value, 'match_percent_questions_program');
                        }} 
                      />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 500, mb:0}} variant="caption">Escolha das sugestões de dupla</Typography>
                      {isLoading && (
                        <Skeleton variant="rectangular" width="100%" height={10} sx={{borderRadius:12, mt:'12px', mb:'16px'}}/>
                      )}
                      {!isLoading && (
                      <PrettoSlider 
                        disabled={(matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1' || matchEdit?.match_select_program == '1')}
                        valueLabelDisplay="auto" 
                        value={(Number(matchEdit?.match_percent_suggestion_program) || 0)} 
                        onChange={(event, newValue) => {
                          const value = Array.isArray(newValue) ? newValue[0] : newValue;
                          handleSlider(event, value, 'match_percent_suggestion_program');
                        }} 
                      />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>   


          <Grid item xs={12} lg={6} sx={{marginBottom:1}}>
            <Card sx={{ borderRadius: 2}} variant='outlined' elevation={0}>
              <CardHeader
                title='Funcionamento do Match'
                titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', }}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>   
                  <Grid item xs={12} sx={{marginBottom:1}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography 
                          sx={{
                            fontSize:'0.8em', 
                            color:(changeFocus.amountSelect === true ? 
                                    'primary.main' : 
                                    (matchEdit?.match_program != '1' ? 
                                      'text.disabled' : 
                                      'text.primary'
                                    )
                                  )
                          }}
                        >
                          Prazo para mentor e mentorado aceitarem o convite e confirmarem a participação no Match.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <EASPicker
                          isLoading={isLoading}
                          disabled={!(matchEdit?.match_program &&matchEdit.match_program == '1')}
                          onFocus={() =>{
                              setChangeFocus(existingValues => ({
                                ...existingValues,
                                acceptInvitation: true,
                              }));
                              setMatchEditError(existingValues => ({
                                ...existingValues,
                                start_enroll_program: '',
                              }));
                            }
                          }
                          onBlur={() =>
                            setChangeFocus(existingValues => ({
                              ...existingValues,
                              acceptInvitation: false,
                            }))
                          }
                          onOpen={() =>
                            setMatchEditError(existingValues => ({
                              ...existingValues,
                              start_enroll_program: '',
                            }))
                          }
                          label="Data de Início"
                          value={matchEdit?.start_enroll_program}
                          error={!!matchEditError?.start_enroll_program}
                          helperText={matchEditError?.start_enroll_program}
                          onChangePiker={(e) => {
                            if (e instanceof Date) {
                              setMatchEdit(existingValues => ({
                                ...existingValues,
                                start_enroll_program: e.toISOString().split('T')[0],
                              }));
                            }
                          }
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <EASPicker
                          isLoading={isLoading}
                          disabled={!(matchEdit?.match_program &&matchEdit.match_program == '1')}
                          onFocus={() =>{
                              setChangeFocus(existingValues => ({
                                ...existingValues,
                                acceptInvitation: true,
                              }));
                              setMatchEditError(existingValues => ({
                                ...existingValues,
                                close_enroll_program: '',
                              }));
                            }
                          }
                          onBlur={() =>
                            setChangeFocus(existingValues => ({
                              ...existingValues,
                              acceptInvitation: false,
                            }))
                          }
                          onOpen={() =>
                            setMatchEditError(existingValues => ({
                              ...existingValues,
                              close_enroll_program: '',
                            }))
                          }
                          label="Data de Término"
                          value={matchEdit?.close_enroll_program}
                          error={!!matchEditError?.close_enroll_program}
                          helperText={matchEditError?.close_enroll_program}
                          onChangePiker={(e) => {
                            if (e instanceof Date) {
                              setMatchEdit(existingValues => ({
                                ...existingValues,
                                close_enroll_program: e.toISOString().split('T')[0],
                              }));
                            }
                          }
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} xl={6}>
                        <EASRadio
                          label="Permitir alteração competências"
                          onFocus={() =>
                            setMatchEditError(existingValues => ({
                              ...existingValues,
                              match_objective_block_program: '',
                            }))
                          }
                          disabled={!(matchEdit?.match_program &&matchEdit.match_program == '1')}
                          isLoading={isLoading}
                          options={[{ text: <Box sx={{fontSize: '0.875rem'}}>Não Permitir</Box>, value: '0' }, { text: <Box sx={{fontSize: '0.875rem'}}>Permitir</Box>, value: '1' }]}
                          value={matchEdit?.match_objective_block_program}
                          onChange={(e) =>
                            setMatchEdit(existingValues => ({
                              ...existingValues,
                              match_objective_block_program: e.target.value,
                            }))
                          }
                          error={!!matchEditError?.match_objective_block_program}
                          helperText={matchEditError?.match_objective_block_program}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <EASRadio
                          label="Priorizar formação de duplas pelos valores"
                          onFocus={() =>
                            setMatchEditError(existingValues => ({
                              ...existingValues,
                              match_values_priority_program: '',
                            }))
                          }
                          isLoading={isLoading}
                          options={[{ text: <Box sx={{fontSize: '0.875rem'}}>Individuais</Box>, value: '0' }, { text: <Box sx={{fontSize: '0.875rem'}}>Do Grupo</Box>, value: '1' }]}
                          value={matchEdit?.match_values_priority_program}
                          onChange={(e) =>
                            setMatchEdit(existingValues => ({
                              ...existingValues,
                              match_values_priority_program: e.target.value,
                            }))
                          }
                          error={!!matchEditError?.match_values_priority_program}
                          helperText={matchEditError?.match_values_priority_program}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{marginBottom:2}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={{fontSize:'0.8em', color:(changeFocus.amountMentor === true ? theme.palette.primary.main : ((matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1') ? 'text.disabled': 'text.primary'))}}>Quantidade de Mentorados por Mentor</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <EASSelect 
                            isLoading={isLoading}
                            options={totalMentoyMentor}
                            disabled={!(matchEdit?.match_program &&matchEdit.match_program == '1')}
                            onFocus={() => {
                                setChangeFocus(existingValues => ({
                                  ...existingValues,
                                  amountMentor: true,
                                }));
                                setMatchEditError(existingValues => ({
                                  ...existingValues,
                                  mentory_mentor_program: '',
                                }));
                              }
                            }
                            onBlur={() =>
                              setChangeFocus(existingValues => ({
                                ...existingValues,
                                amountMentor: false,
                              }))
                            }
                            label="Quantidade de Mentorados por Mentor "
                            getOptionLabel={(option) => option.text}   
                            value={totalMentoyMentor && totalMentoyMentor.find(item => item.value == matchEdit?.mentory_mentor_program)}                                   
                            onChangeSelect={(event, value) => {
                                if (value && value.value) {
                                    setMatchEdit(existingValues => ({
                                        ...existingValues,
                                        mentory_mentor_program: value.value,
                                    }));
                            }}}        
                            error={!!matchEditError?.mentory_mentor_program}
                            helperText={matchEditError?.mentory_mentor_program}         
                        />
                      </Grid>
                    </Grid> 
                  </Grid>

                  <Grid item xs={12} sx={{marginBottom:1}}>
                    <EASRadio
                        isLoading={isLoading}
                        disabled={matchEdit?.match_program != '1'}
                        label="Formação das Duplas"
                        options={[
                                    { text: <Box sx={{fontSize: '0.875rem'}}><Box fontWeight='fontWeightMedium' display='inline'>O RH seleciona a dupla</Box> com base em até três sugestões de mentores indicados pelo Match.</Box>,value: '1' },
                                    { text: <Box sx={{fontSize: '0.875rem'}}><Box fontWeight='fontWeightMedium' display='inline'>O RH aprova ou reprova a dupla</Box> sugerida pelo Match.</Box>,value: '2' },
                                    { text: <Box sx={{fontSize: '0.875rem'}}><Box fontWeight='fontWeightMedium' display='inline'>As duplas são formadas automaticamente</Box>, sem intervenção do RH.</Box>,value: '3' },
                                    ]}
                        onFocus={() =>
                          setMatchEditError(existingValues => ({
                            ...existingValues,
                            match_type_program: '',
                          }))
                        }
                        value={matchEdit?.match_type_program}
                        onChange={(e) => 
                          setMatchEdit(existingValues => ({
                            ...existingValues,
                            match_type_program: e.target.value,
                          }))
                        }
                        row={false}
                        error={!!matchEditError?.match_type_program}
                        helperText={matchEditError?.match_type_program}  
                    />
                  </Grid>

                </Grid> 
              </CardContent>
            </Card>
          </Grid> 
          <Grid item xs={12} lg={6} sx={{marginBottom:1}}>
            <Card sx={{ borderRadius: 2}} variant='outlined' elevation={0}>
              <CardHeader
                title='Funcionamento do Match'
                titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', }}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}> 
                  <Grid item xs={12} sx={{marginBottom:1}}>
                    <EASRadio
                        isLoading={isLoading}
                        disabled={ matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1'}
                        label="Match entre participantes"
                        options={[
                                    { text: <Box sx={{fontSize: '0.875rem'}}><Box fontWeight='fontWeightMedium' display='inline'>Não há escolhas</Box> entre os participantes.</Box>,value: '1' },
                                    { text: <Box sx={{fontSize: '0.875rem'}}><Box fontWeight='fontWeightMedium' display='inline'>Apenas os mentorados</Box> escolhem seus mentores entre as sugestões do Match.</Box>,value: '2' },
                                    { text: <Box sx={{fontSize: '0.875rem'}}><Box fontWeight='fontWeightMedium' display='inline'>Mentores e mentorados</Box> se escolhem mutuamente entre as sugestões do Match.</Box>,value: '3' },
                                    ]}
                        value={matchEdit?.match_select_program}
                        onFocus={() =>
                          setMatchEditError(existingValues => ({
                            ...existingValues,
                            match_select_program: '',
                          }))
                        }
                        onChange={(e) => 
                          setMatchEdit(existingValues => ({
                            ...existingValues,
                            match_select_program: e.target.value,
                          }))
                        }
                        row={false}
                        error={!!matchEditError?.match_select_program}
                        helperText={matchEditError?.match_select_program}      
                    />
                  </Grid> 
                  
                  <Grid item xs={12} sx={{marginBottom:1}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography 
                          sx={{
                            fontSize:'0.8em', 
                            color:(changeFocus.amountSelect === true ? 
                                    'primary.main' : 
                                    ( (matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1' || matchEdit?.match_select_program == '1') ? 
                                      'text.disabled' : 
                                      'text.primary'
                                    )
                                  )
                          }}
                        >
                          Número de sugestões e escolhas que serão oferecidas aos mentores e/ou mentorados
                        </Typography>
                      </Grid>
                      <Grid item xs={12} xl={6}>
                        <EASSelect 
                          isLoading={isLoading}
                          options={totalChoose}
                          disabled={matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1' || matchEdit?.match_select_program == '1'}
                          onFocus={() => {
                              setChangeFocus(existingValues => ({
                                ...existingValues,
                                amountSelect: true,
                              }));
                              setMatchEditError(existingValues => ({
                                ...existingValues,
                                match_select_amount_program: '',
                              }));
                            }
                          }
                          onBlur={() =>
                            setChangeFocus(existingValues => ({
                              ...existingValues,
                              amountSelect: false,
                            }))
                          }
                          label="Quantidade de escolhas"
                          getOptionLabel={(option) => option.text}        
                          value={totalChoose && totalChoose.find(item => item.value == matchEdit?.match_select_amount_program)}                            
                          onChangeSelect={(event, value) => {
                              if (value && value.value) {
                                  setMatchEdit(existingValues => ({
                                      ...existingValues,
                                      match_select_amount_program: value.value,
                                  }));
                          }}}        
                          error={!!matchEditError?.match_select_amount_program}
                          helperText={matchEditError?.match_select_amount_program}          
                        />
                      </Grid>
                      <Grid item xs={12} xl={6}>
                        <EASSelect 
                          isLoading={isLoading}
                          options={totalSuggestion}
                          disabled={matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1' || matchEdit?.match_select_program == '1'}
                          onFocus={() => {
                              setChangeFocus(existingValues => ({
                                ...existingValues,
                                amountSelect: true,
                              }));
                              setMatchEditError(existingValues => ({
                                ...existingValues,
                                match_suggestion_amount_program: '',
                              }));
                            }
                          }
                          onBlur={() =>
                            setChangeFocus(existingValues => ({
                              ...existingValues,
                              amountSelect: false,
                            }))
                          }
                          label="Quantidade de Sugestões "
                          getOptionLabel={(option) => option.text}        
                          value={totalSuggestion && totalSuggestion.find(item => item.value == matchEdit?.match_suggestion_amount_program)}                            
                          onChangeSelect={(event, value) => {
                              if (value && value.value) {
                                  setMatchEdit(existingValues => ({
                                      ...existingValues,
                                      match_suggestion_amount_program: value.value,
                                  }));
                          }}}        
                          error={!!matchEditError?.match_suggestion_amount_program}
                          helperText={matchEditError?.match_suggestion_amount_program}      
                        />
                      </Grid>
                    </Grid> 
                  </Grid>

                  <Grid item xs={12} sx={{marginBottom:2}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography 
                          sx={{
                            fontSize:'0.8em', 
                            color:(changeFocus.amountSelect === true ? 
                                    'primary.main' : 
                                    ( (matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1' || matchEdit?.match_select_program == '1') ? 
                                      'text.disabled' : 
                                      'text.primary'
                                    )
                                  )
                          }}
                        >
                          Prazo para o mentor e o mentorado selecionarem suas preferências de dupla com base nas sugestões do Match.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} xl={6}>
                        <EASPicker
                          isLoading={isLoading}
                          disabled={(matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1' || matchEdit?.match_select_program == '1')}
                          onFocus={() => {
                              setChangeFocus(existingValues => ({
                                ...existingValues,
                                match: true,
                              }));
                              setMatchEditError(existingValues => ({
                                ...existingValues,
                                start_match_program: '',
                              }));
                            }
                          }
                          onBlur={() =>
                            setChangeFocus(existingValues => ({
                              ...existingValues,
                              match: false,
                            }))
                          }
                          onOpen={() =>
                            setMatchEditError(existingValues => ({
                              ...existingValues,
                              start_match_program: '',
                            }))
                          }
                          label="Data de Início"
                          value={matchEdit?.start_match_program}
                          error={!!matchEditError?.start_match_program}
                          helperText={matchEditError?.start_match_program}
                          onChangePiker={(e) => {
                            if (e instanceof Date) {
                              setMatchEdit(existingValues => ({
                                ...existingValues,
                                start_match_program: e.toISOString().split('T')[0],
                              }));
                            }
                          }
                          }
                        />
                      </Grid>
                      <Grid item xs={12} xl={6}>
                        <EASPicker
                          isLoading={isLoading}
                          disabled={(matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1' || matchEdit?.match_select_program == '1')}
                          onFocus={() => {
                              setChangeFocus(existingValues => ({
                                ...existingValues,
                                match: true,
                              }));
                              setMatchEditError(existingValues => ({
                                ...existingValues,
                                close_match_program: '',
                              }));
                            }
                          }
                          onBlur={() =>
                            setChangeFocus(existingValues => ({
                              ...existingValues,
                              match: false,
                            }))
                          }
                          onOpen={() =>
                            setMatchEditError(existingValues => ({
                              ...existingValues,
                              close_match_program: '',
                            }))
                          }
                          label="Data de Término"
                          value={matchEdit?.close_match_program}
                          error={!!matchEditError?.close_match_program}
                          helperText={matchEditError?.close_match_program}
                          onChangePiker={(e) => {
                            if (e instanceof Date) {
                              setMatchEdit(existingValues => ({
                                ...existingValues,
                                close_match_program: e.toISOString().split('T')[0],
                              }));
                            }
                          }
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  
                  <Grid item xs={12}>
                    <EASRadio
                      label="Reexibir uma sugestão escolhida antes que o ciclo de escolhas seja concluído?"
                      onFocus={() =>
                        setMatchEditError(existingValues => ({
                          ...existingValues,
                          match_suggestions_review_program: '',
                        }))
                      }
                      disabled={(matchEdit?.match_program != '1' || matchEdit?.match_type_program == '1' || matchEdit?.match_select_program == '1')}
                      isLoading={isLoading}
                      options={[{ text: <Box sx={{fontSize: '0.875rem'}}>Não</Box>, value: '0' }, { text: <Box sx={{fontSize: '0.875rem'}}>Sim</Box>, value: '1' }]}
                      value={matchEdit?.match_suggestions_review_program}
                      onChange={(e) =>
                        setMatchEdit(existingValues => ({
                          ...existingValues,
                          match_suggestions_review_program: e.target.value,
                        }))
                      }
                      error={!!matchEditError?.match_suggestions_review_program}
                      helperText={matchEditError?.match_suggestions_review_program}
                    />
                  </Grid>
                </Grid> 
              </CardContent>
            </Card>
          </Grid> 





        </Grid>
      </CardContent>
    </Card>

  );
});

MatchConfigurePanel.displayName = 'MatchConfigurePanel';
export default MatchConfigurePanel;
