import React, {  useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { IMatch } from '../../shared/types';

import { useHumanResourcesContext } from '../../shared/contexts';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import { MatchService } from '../../shared/services';
import MatchQuestionsPanel, { IRefMatchQuestions } from './MatchQuestionsPanel';

export const MatchQuestions = () => {

    const { humanResource } = useHumanResourcesContext();

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refMatchQuestions = useRef<IRefMatchQuestions>(null);

    const storeDisplay = () => {
        if (refMatchQuestions.current)
          refMatchQuestions.current.saveMatch();
    };

    const { data:match, isFetching: isLoadingMatch } = useQuery(['match-edit', humanResource?.id_program], () => MatchService.read(humanResource?.id_program), {
      enabled: !!humanResource,
      staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Erro ao carregar dados do match.', { variant: 'error' });
        },
    });

    const { mutateAsync: storeUpdate, isLoading: isLoadingStore } = useMutation((updateMatch: IMatch) => MatchService.update(updateMatch), {
        onSuccess: () => {
            queryClient.invalidateQueries('match-edit');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant: 'error' });
        },
    });

    return (
        <LayoutDefault >
            <EASCard
                titleCard={'Perguntas do Match'}
                actionCard={match?.item?.match_program == '1' ? (<EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>) : <></>} >
                <Box sx={{ mt: 2 }}>
                    <MatchQuestionsPanel
                      match={match?.status === 'success' ? match?.item : undefined}
                      isLoading={isLoadingMatch || isLoadingStore}
                      storeUpdate={(match) => storeUpdate(match)}
                      ref={refMatchQuestions}
                    />
                </Box>
            </EASCard>
        </LayoutDefault>
    );
};
