import { TextFieldProps, TextField, useTheme, Skeleton } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type EASPickerProps = {
    isLoading?: boolean | undefined;
    onChangePiker: (value: Date | null | string, keyboardInputValue?: string | undefined) => void;
    onOpen?: () => void;
} & TextFieldProps ; 
               
export const EASPicker: React.FC<EASPickerProps> = ({...props}) => {
    const theme = useTheme();

    return (
        <>
            {props.isLoading && (
            <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px'}}/>
            )}
            {!props.isLoading && (
                <LocalizationProvider adapterLocale={brLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        disabled={props.disabled}
                        onOpen={props.onOpen}
                        label={props.label}
                        value={ props.value === '' ? null : 
                                    (!(props.value instanceof Date) ? new Date (props.value+'T23:28:56.782Z') : props.value)} 
                        
                        onChange={(date, keyString) =>{
                            if(keyString === undefined && date instanceof Date) {
                                const dateResponse = new Date (date.toISOString().split('T')[0] + 'T23:28:56.782Z');
                                props.onChangePiker(dateResponse);
                            } else if(keyString?.length == 10) {
                                    const keyResponse = new Date (keyString.split('/')[2] + '-'+ keyString.split('/')[1]+'-'+keyString.split('/')[0]+ 'T23:28:56.782Z');
                                    props.onChangePiker(keyResponse);
                                }
                            }
                        }
                        renderInput={
                          (params) => 
                            <TextField
                              onFocus={props.onFocus}
                              onBlur={props.onBlur}
                              sx={{  fontSize: '0.85rem', 
                                      borderRadius: 2,  
                                      '& .MuiOutlinedInput-root': {
                                        borderRadius:2,
                                        backgroundColor: (props.disabled ? 'eas.inputBackgroundDisabled': 'eas.inputBackground'),
                                        
                                      }
                                  }}
                              value={props.value}     
                              helperText={props.helperText}  
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: undefined
                              }}                         
                              error={props.error}
                              fullWidth
                              InputLabelProps={{ shrink: true  }}
                            />
                        }
                    />
                </LocalizationProvider> 
            )}
        </>


    );
};

/*




                <TextField
                    inputProps={  { sx:{fontSize: '0.85rem'}, ...(themeName == 'light') ? {style: autoFillLight} : {style: autoFillDark}}}
                    InputProps={
                        {
                            sx: {borderRadius: 2,  backgroundColor: `${theme.palette.background.default}`}, 
                            ...(props.nameIconLeft) ? {
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <Icon style={{fontSize: 18, color: isFocused ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconLeft}</Icon>
                                </InputAdornment>
                                )
                            } : undefined, 

                            ...(props.nameIconRight && handleIconRight) ?
                            (
                                {endAdornment: (                            
                                <InputAdornment position='end'>
                                    <IconButton onClick={(e) => handleIconRight(e)}>
                                    <Icon style={{fontSize: 18, color: isFocused ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconRight}</Icon>
                                    </IconButton>
                                </InputAdornment>)}

                            ) :
                            (
                                props.nameIconRight ? 
                                {
                                    endAdornment: (
                                        <Icon style={{fontSize: 18, color: isFocused ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconRight}</Icon>
                                    )
                                } : undefined
                            )
                        }
                    }
                    onFocus={() => props.onFocus}
                    onBlur={() => handleInputBlur()}
                    label={props.label}
                    type={props.type}
                    value={props.value}
                    onChange={props.onChange}  
                    onKeyDown={props.onKeyDown} 
                    size={props.size}
                    error={props.error}
                    helperText={props.helperText}
                    disabled={props.disabled}
                    fullWidth
                />


*/
