import React, { useMemo, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { IMatch, IProgram } from '../../shared/types';

import { useHumanResourcesContext } from '../../shared/contexts';
import { ProgramsService } from '../../shared/services/api/programs/ProgramsService';
import { UtilService } from '../../shared/services/api/util/UtilService';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import { MatchService } from '../../shared/services';
import MatchConfigurePanel, { IRefMatchConfigure } from './MatchConfigurePanel';

export const MatchConfigure = () => {

    const { humanResource } = useHumanResourcesContext();

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refMatchConfigure = useRef<IRefMatchConfigure>(null);

    const storeDisplay = () => {
        if (refMatchConfigure.current)
          refMatchConfigure.current.saveMatch();
    };

    const { data:match, isFetching: isLoadingMatch } = useQuery(['match-edit', humanResource?.id_program], () => MatchService.read(humanResource?.id_program), {
      enabled: !!humanResource,
      staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Erro ao carregar dados do match.', { variant: 'error' });
        },
    });

    const { mutateAsync: storeUpdate, isLoading: isLoadingStore } = useMutation((updateMatch: IMatch) => MatchService.update(updateMatch), {
        onSuccess: () => {
            queryClient.invalidateQueries('match-edit');
            queryClient.invalidateQueries('match-result');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant: 'error' });
        },
    });

    return (
        <LayoutDefault >
            <EASCard
                titleCard={'Configurações do Match'}
                breadcrumbsCard=""
                actionCard={<Stack direction="row" spacing={1}>
                    <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                </Stack>} >
                <Box sx={{ mt: 2 }}>
                    <MatchConfigurePanel
                        match={match?.status === 'success' ? match?.item : undefined}
                        isLoading={isLoadingMatch || isLoadingStore}
                        storeUpdate={(match) => storeUpdate(match)}
                        ref={refMatchConfigure}
                    />
                </Box>
            </EASCard>
        </LayoutDefault>
    );
};
